import BaseEvaluator from '../base-evaluator.js';

export default class DateComparisonToField extends BaseEvaluator {

    constructor(config) {
      super(config);
      this.name = 'Date Field Comparison';
      this.targetField = config.targetField;
      this.comparisonField = config.comparisonField;
      this.comparator = config.comparator;
    }

    addErrorFields(fieldArray) {
      if (!fieldArray) {
        fieldArray = [];
      }
      if (!fieldArray.includes(this.targetField)) {
        fieldArray.push(this.targetField);
      }
      if (!fieldArray.includes(this.comparisonField)) {
        fieldArray.push(this.comparisonField);
      }
      return fieldArray;
    }

    _getSummary() {
      return `The date found in '${this.targetField}' must be ${this.comparator} compared with ${this.comparisonField}`;
    }

    getTriggerMessage() {
      if (this.comparator === "=") {
        return `(${this.targetField} was equal to ${this.comparisonField}`;
      } else if (this.comparator === "!=") {
        return `(${this.targetField} was not equal to ${this.comparisonField}`;
      } else if (this.comparator === ">") {
        return `(${this.targetField} was more than ${this.comparisonField}`;
      } else if (this.comparator === "<") {
        return `(${this.targetField} was less than ${this.comparisonField}`;
      } else if (this.comparator === ">=") {
        return `(${this.targetField} was more than or equal to ${this.comparisonField}`;
      } else if (this.comparator === "<=") {
        return `(${this.targetField} was less than or equal to ${this.comparisonField}`;
      }
    }

    _evaluate(record, finalRecord) {
      if (!this.targetField || !record[this.targetField.trim()] ||
          !this.comparisonField || !record[this.comparisonField.trim()]) {
        return {result: false, missingData: true, description: 'Necessary fields not present', fields: this.addErrorFields(null)};
      }
      const milliseconds = Date.parse(record[this.targetField.trim()]);
      if (isNaN(milliseconds)) {
        return {result: false, badData: true, description: 'Target field not parseable', fields: this.addErrorFields(null)};
      }
      const compareMilliseconds = Date.parse(record[this.comparisonField.trim()]);
      if (isNaN(compareMilliseconds)) {
        return {result: false, badData: true, description: 'Comparison field not parseable', fields: this.addErrorFields(null)};
      }
      const diff = milliseconds - compareMilliseconds;
      switch (this.comparator) {
        case ">": {
          if (milliseconds > compareMilliseconds){
            record.errorDetail.push(this.getTriggerMessage());
            this.addErrorFields(record.errorFields);
            return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
          }
          break;
        }
        case "<": {
          if (milliseconds < compareMilliseconds){
            record.errorDetail.push(this.getTriggerMessage());
            this.addErrorFields(record.errorFields);
            return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
          }
          break;
        }
        case ">=": {
          if (milliseconds >= compareMilliseconds){
            record.errorDetail.push(this.getTriggerMessage());
            this.addErrorFields(record.errorFields);
            return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
          }
          break;
        }
        case "<=": {
          if (milliseconds <= compareMilliseconds){
            record.errorDetail.push(this.getTriggerMessage());
            this.addErrorFields(record.errorFields);
            return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
          }
          break;
        }
        case "=": {
          if (milliseconds === compareMilliseconds){
            record.errorDetail.push(this.getTriggerMessage());
            this.addErrorFields(record.errorFields);
            return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
          }
          break;
        }
        case "!=": {
          if (milliseconds != compareMilliseconds){
            record.errorDetail.push(this.getTriggerMessage());
            this.addErrorFields(record.errorFields);
            return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
          }
          break;
        }
      }
      return {result: false, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
    }

    _perturb(record) {
      if (record[this.targetField] && record[this.comparisonField]) {
        const comparisonTime = Date.parse(record[this.comparisonField]);
        if (this.comparator === "=") {
          record[this.targetField] = new Date(comparisonTime - (1000 * 3600 * 48)).toString();
        } else if (this.comparator === "!=") {
          record[this.targetField] = record[this.comparisonField];
        } else if (this.comparator === ">") {
          record[this.targetField] = record[this.comparisonField];
        } else if (this.comparator === "<") {
          record[this.targetField] = record[this.comparisonField];
        } else if (this.comparator === ">=") {
          record[this.targetField] = new Date(comparisonTime + (1000 * 3600 * 48)).toString();
        } else if (this.comparator === "<=") {
          record[this.targetField] = new Date(comparisonTime - (1000 * 3600 * 48)).toString();
        }
        record.perturbed = true;
        const newRecord = JSON.parse(JSON.stringify(record));
        newRecord.perturbed_field = this.targetField;
        return newRecord;
      }
    }
}
