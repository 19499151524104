import {InputField, Modal} from "@medtronic/pecc-react-component-library-js";
import {useState} from "react";
import {Box, Stack, Switch, Typography, useTheme} from "@mui/material";
import {Warning} from "@carbon/icons-react";

function VerificationField({controlType, controlString, confirmationExplanation, confirmable, setConfirmable, isSubmitting}) {
    const {palette, typography} = useTheme();
    const [userInput, setUserInput] = useState("");

    const textVerificationField = () => {
        const typeTheme = "bodyS";
        return (
            <>
                <Typography variant={typeTheme}>
                    Type
                </Typography>
                <Typography variant={typeTheme} margin='0 0.5rem' padding="0 0.5rem" backgroundColor={palette.black10.main} color={palette.textBlackEmphasized.main} sx={{borderRadius: "0.5rem"}}>
                    {controlString}
                </Typography>
                <Typography variant={typeTheme}>
                    in the box to confirm:
                </Typography>
                <InputField
                    label=""
                    value={userInput}
                    onChange={input => {
                        setUserInput(input);
                        setConfirmable(input === controlString);
                    }}
                    isDisabled={isSubmitting}
                />
            </>
        )
    }

    const toggleVerificationField = () => (
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{backgroundColor: confirmable ? palette.electricBlue40.main : palette.black10.main, borderRadius: "0.5rem", padding: "1rem"}}>
            <Switch onChange={() => setConfirmable(!confirmable)} disabled={isSubmitting}/>
            <Typography variant="bodyS">
                {confirmationExplanation}
            </Typography>
        </Stack>
    )

    let innerControl = <Typography>{`Unknown controlType parameter: ${controlType}`}</Typography>;

    if (controlType === "toggle") {
        innerControl = toggleVerificationField();
    } else if (controlType === "text") {
        innerControl = textVerificationField();
    }

    return (
        <Box marginTop="1.5rem">
            {innerControl}
        </Box>
    )
}

function ControlledConfirmModal({open, toggleOpenFn, modalTitle, modalDescription, confirmationExplanation, primaryButtonLabel, primaryButtonHandler, secondaryButtonLabel, secondaryButtonHandler, controlType, controlString}) {
    const {palette, typography} = useTheme();
    const [confirmable, setConfirmable] = useState(false);
    const [userInput, setUserInput] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const titleComponent = <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="headingMAlt" color={palette.critical.main}>
            {modalTitle}
        </Typography>
        <Warning fill={palette.critical.main} size={typography.headingMAlt.fontSize}/>
    </Stack>

    const children = <Box width={"33vw"}>
        <Typography variant="bodyLarge">
            {modalDescription}
        </Typography>
        <VerificationField confirmable={confirmable}
                           setConfirmable={setConfirmable}
                           confirmationExplanation={confirmationExplanation}
                           controlString={controlString}
                           controlType={controlType}
                           isSubmitting={isSubmitting}
        />
    </Box>

    const closeWrapperFn = func => {
        func();
        setUserInput("");
        setIsSubmitting(false);
    }

    return <Modal
        open={open}
        toggleOpenFn={() => closeWrapperFn(toggleOpenFn)}
        modalTitle={titleComponent}
        children={children}
        primaryButtonLabel={primaryButtonLabel}
        primaryButtonHandler={() => {
            setIsSubmitting(true);
            setUserInput(null);
            closeWrapperFn(primaryButtonHandler)
            setIsSubmitting(false)
        }}
        disablePrimaryButton={!confirmable}
        secondaryButtonLabel={secondaryButtonLabel}
        secondaryButtonHandler={() => closeWrapperFn(secondaryButtonHandler)}
        customContentMargin={4}
    />
}

ControlledConfirmModal.defaultProps = {
    modalTitle: "Title",
    confirmationExplanation: "I understand and wish to proceed with this action.",
    primaryButtonLabel: "Confirm",
    secondaryButtonLabel: "Cancel",
    controlType: "toggle",
    controlString: "Confirm"
}

export default ControlledConfirmModal;
