import to from 'await-to-js';
import Cookies from "js-cookie";
import digitalHealthAuth from "./digital-health-auth";
import SessionManager from "./session-manager";


const initAuth = async (configuration) => {
    digitalHealthAuth.cookieName = configuration.cookieName;
    if (!digitalHealthAuth.initialized) {
        console.log("Initializing authentication system");
        const sessionManager = new SessionManager(configuration);
        digitalHealthAuth.sessionManager = sessionManager;
        let accessCookieStr = Cookies.get(configuration.cookieName + "-access");
        let idCookieStr = Cookies.get(configuration.cookieName + "-id");
        let refreshCookieStr = Cookies.get(configuration.cookieName + "-refresh");
        let expiresCookieStr = Cookies.get(configuration.cookieName + "-expires");
        const [initError, initResult] = await to(digitalHealthAuth.sessionManager.init(accessCookieStr, idCookieStr, refreshCookieStr, expiresCookieStr));
        if (initError) {
            console.log("Error occurred during auth manager initialization");
            throw(initError);
        }
        digitalHealthAuth.initialized = true;
        return initResult;
    } else {
        console.log("Authentication system already initialized.")
    }
}

const getCookieName = () => {
    return digitalHealthAuth.cookieName;
}

const authInitialized = () => {
    if (digitalHealthAuth.initialized) {
        return true;
    }
    return false;
}

const getSignInURL = () => {
    return digitalHealthAuth.sessionManager.environment.settings.signOutURL;
}

const getSignOutURL = () => {
    return digitalHealthAuth.sessionManager.environment.settings.signInURL;
}

const getJWT = () => {
    return digitalHealthAuth.sessionManager.authManager.id_token;
}

const getLoggedInUserId = () => {
  const idToken = digitalHealthAuth.sessionManager.authManager.id_token;
  if (idToken) {
    const jsonData = digitalHealthAuth.sessionManager.authManager.decodeToken(idToken);
    return jsonData["cognito:username"];
  }
  return null;
}

const getClaims = () => {
  const idToken = digitalHealthAuth.sessionManager.authManager.id_token;
  const accessToken = digitalHealthAuth.sessionManager.authManager.access_token;
  if (idToken) {
    const idData = digitalHealthAuth.sessionManager.authManager.decodeToken(idToken);
    const accessData = digitalHealthAuth.sessionManager.authManager.decodeToken(accessToken);
    const jsonData = {
      id: idData,
      access: accessData
    }
    return jsonData;
  }
  return null;
}

const getSessionManager = () => {
    return digitalHealthAuth.sessionManager;
}

export { getClaims, initAuth, authInitialized, getSignInURL, getSignOutURL, getJWT, getSessionManager, getCookieName, getLoggedInUserId };
