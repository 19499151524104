import CRUDManager from "./crud-manager";


// CRUD SDK for 'workbench' operations (Stop, Reboot, Terminate)
class WorkbenchOpManager extends CRUDManager{
    
    constructor(sessionManager, logger, config, environment) {
        super('Workbench', 'workbenchstatus', sessionManager, logger, config, environment);
    }

    processResult(result, error, updatedWorkbench, command) {
        // Insert the updated record into the cache and array
        if (result) {
            super.update_record(updatedWorkbench)
        }

        if (error){
            this.logger.error('Error encountered at WORKBENCH-OP manager while sending the command: Workbench-' + command);
            return null;
        }

        return result;
    }

    // START workbench
    async startWorkbench(workbench) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
          this.logger.error("Error encountered at WORKBENCH OPS manager in getting headers during start workbench");
          throw headersError;
        }

        const path = super.getRESTPath() + '/' + String(workbench.id) + '/' + 'start';
        try {
            let result = await this.restManager.put(path, null, headersResult);
            return this.processResult(result, null, workbench, 'start');
        } catch (error) {
            this.logger.error(error.message);
            return this.processResult(null, error, workbench, 'start');
        }
    }

    // STOP workbench
    async stopWorkbench(workbench) {
        let headersResult = null;
        try {
          headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
          this.logger.error("Error encountered at WORKBENCH OPS manager in getting headers during stop workbench");
          throw headersError;
        }
        try {
            let path = super.getRESTPath() + '/' + String(workbench.id) + '/' + 'stop';
            let result = await this.restManager.put(path, null, headersResult);
            return this.processResult(result, null, workbench, 'stop');
        } catch (error) {
            this.logger.error(error.message);
            return this.processResult(null, error, workbench, 'stop');
        }
    }

    // TERMINATE workbench
    async terminateWorkbench(workbench) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
          this.logger.error("Error encountered at WORKBENCH OPS manager in getting headers during terminate workbench");
          throw headersError;
        }
        try {
            const path = super.getRESTPath() + '/' + String(workbench.id) + '/' + 'terminate';
            let result = await this.restManager.put(path, null, headersResult);
            return this.processResult(result, null, workbench, 'ternimate');
        } catch (error) {
            this.logger.error(error.message);
            return this.processResult(null, error, workbench, 'ternimate');
        }
    }    
}

export default WorkbenchOpManager;
