import SemanticObject from "./semantic-object";

// Entity Class for 'dataset' table
class Dataset extends SemanticObject {

    constructor() {
        super();
        this.short_description = "";
        this.size = {};
        this.type = "";
        this.location = {};
        this.status = "";
        this.concepts = [];
        this.agreements = [];
        this.source = "";
        this.data_classification = "";
        this.source_ou = "";
    }

    setAttributes(json) {
        super.setAttributes(json);
        this.short_description = json.short_description;
        this.size = json.size;
        this.type = json.type;
        this.location = json.location;
        this.status = json.status;
        this.concepts = json.concepts;
        this.agreements = json.agreements;
        this.source = json.source;
        this.data_classification = json.data_classification;
        this.source_ou = json.source_ou;
    }

    stripNonNewAttributes(json) {
      super.stripNonNewAttributes(json);
      delete json.users;
      delete json.projects;
    }

    stripUpdateAttributes(json) {
      super.stripUpdateAttributes(json);
      delete this.users;
      delete this.projects;
    }
}

export default Dataset;
