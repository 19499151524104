import HDPEntity from "./hdp-entity";

class RoleRelation extends HDPEntity {

    constructor() {
        super();
        this.user_id = "";
        this.role_id = -1;
    }

    setAttributes(json) {
        super.setAttributes(json);
        this.user_id = json.user_id;
        this.role_id = json.role_id;
    }
}

export default RoleRelation;
