import BaseEvaluator from '../base-evaluator.js';

export default class DateComparison extends BaseEvaluator {



    constructor(config) {
      super(config);
      this.name = 'Date Comparison';
      this.targetField = config.targetField.trim();
      this.comparator = config.comparator;
      this.compareValue = Date.parse(config.compareValue);
    }

    addErrorFields(fieldArray) {
      if (!fieldArray) {
        fieldArray = [];
      }
      if (!fieldArray.includes(this.targetField)) {
        fieldArray.push(this.targetField);
      }
      return fieldArray;
    }

    _getSummary() {
      return `The date found in '${this.targetField}' must be ${this.comparator} than ${this.compareValue}`;
    }

    getTriggerMessage() {
      if (this.comparator === "=") {
        return `${this.targetField} was equal to ${this.compareValue}`;
      } else if (this.comparator === "!=") {
        return `${this.targetField} was not equal to ${this.compareValue}`;
      } else if (this.comparator === ">") {
        return `${this.targetField} was after ${this.compareValue}`;
      } else if (this.comparator === "<") {
        return `${this.targetField} was before ${this.compareValue}`;
      } else if (this.comparator === ">=") {
        return `${this.targetField} was after or on ${this.compareValue}`;
      } else if (this.comparator === "<=") {
        return `${this.targetField} was before or on ${this.compareValue}`;
      }
    }

    _evaluate(record, finalRecord) {
      if (!this.targetField || !record[this.targetField.trim()]) {
        return {result: false, missingData: true, description: 'Necessary fields not present', fields: this.addErrorFields(null)};
      }
      const recordValue = Date.parse(record[this.targetField.trim()]);
      if (isNaN(recordValue)) {
        return {result: false, badData: true, description: 'Necessary fields not present', fields: this.addErrorFields(null)};
      }
      switch (this.comparator) {
        case ">": {
          if (recordValue > this.compareValue) {
            record.errorDetail.push(this.getTriggerMessage());
            this.addErrorFields(record.errorFields);
            return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
          }
          break;
        }
        case "<": {
          if (recordValue < this.compareValue) {
            record.errorDetail.push(this.getTriggerMessage());
            this.addErrorFields(record.errorFields);
            return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
          }
          break;
        }
        case ">=": {
          if (recordValue >= this.compareValue) {
            record.errorDetail.push(this.getTriggerMessage());
            this.addErrorFields(record.errorFields);
            return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
          }
          break;
        }
        case "<=": {
          if (recordValue <= this.compareValue) {
            record.errorDetail.push(this.getTriggerMessage());
            this.addErrorFields(record.errorFields);
            return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
          }
          break;
        }
        case "=": {
          if (recordValue === this.compareValue) {
            record.errorDetail.push(this.getTriggerMessage());
            this.addErrorFields(record.errorFields);
            return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
          }
          break;
        }
        case "!=": {
          if (recordValue != this.compareValue) {
            record.errorDetail.push(this.getTriggerMessage());
            this.addErrorFields(record.errorFields);
            return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
          }
          break;
        }
      }
      return {result: false, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
    }

    _perturb(record) {
      if (record[this.targetField]) {
        if (this.comparator === "=") {
          this.perturbValue(record, this.targetField, true);
        } else if (this.comparator === "!=") {
          record[this.targetField] = this.compareValue;
        } else if (this.comparator === ">") {
          record[this.targetField] = this.compareValue;
        } else if (this.comparator === "<") {
          record[this.targetField] = this.compareValue;
        } else if (this.comparator === ">=") {
          record[this.targetField] = new Date(this.compareValue + (1000 * 3600 * 48)).toString();
        } else if (this.comparator === "<=") {
          record[this.targetField] = new Date(this.compareValue - (1000 * 3600 * 48)).toString();
        }
        record.perturbed = true;
        const newRecord = JSON.parse(JSON.stringify(record));
        newRecord.perturbed_field = this.targetField;
        return newRecord;
      }
    }
}
