class Environment {
    constructor() {
        this.settings = require(`./environment.json`);
    }

    getAPIURL() {
        //return this.settings["rootAPIURL"];
        return this.settings["rootAPIURL"];
    }

    getEnvName() {
        return this.settings["name"];
    }

    getCookieName() {
      return this.settings["auth"]["cookieName"];
    }
}

export default Environment;
