import BaseEvaluator from '../base-evaluator.js';

export default class RecordPeriodicity extends BaseEvaluator {

    constructor(config) {
      super(config);
      this.targetField = config.targetField;
      this.assessment = config.assessment;
      this.comparator = config.comparator;
      this.value = config.value;
      this.units = config.units;
    }

    addErrorFields(fieldArray) {
      if (!fieldArray) {
        fieldArray = [];
      }
      if (!fieldArray.includes(this.targetField)) {
        fieldArray.push(this.targetField);
      }
      return fieldArray;
    }

    evaluate(record, finalRecord) {
      throw new Error("Rule is not meant to evaluate a single record");
    }

    _getSummary() {
      return `${this.assessment} in ${this.targetField} must occur ${this.comparator} ${this.value} ${this.units}`;
    }

    getTriggerMessage() {
      if (this.comparator === "=") {
        return `${this.assessment} in ${this.targetField} must occur every ${this.value} ${this.units}`;
      } else if (this.comparator === "!=") {
        return `${this.assessment} in ${this.targetField} must NOT occur every ${this.value} ${this.units}`;
      } else if (this.comparator === ">") {
        return `${this.assessment} in ${this.targetField} must occur more often than ${this.value} ${this.units}`;
      } else if (this.comparator === "<") {
        return `${this.assessment} in ${this.targetField} must occur less often than ${this.value} ${this.units}`;
      } else if (this.comparator === ">=") {
        return `${this.assessment} in ${this.targetField} must occur at least every ${this.value} ${this.units}`;
      } else if (this.comparator === "<=") {
        return `${this.assessment} in ${this.targetField} must occur at most every ${this.value} ${this.units}`;
      }
    }

    _perturb(record) {
      // Do nothing - placeholder
    }
}
