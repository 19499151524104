import BaseEvaluator from '../base-evaluator.js';

export default class DateComparisonToFieldWithWindow extends BaseEvaluator {

  constructor(config) {
    super(config);
    this.name = 'Date Field Comparison with Time Window';
    this.dateField = config.dateField;
    this.units = config.units;
    this.comparisonField = config.comparisonField;
    this.valueLowDisplay = config.valueLow;
    this.valueHighDisplay = config.valueHigh;
    this.valueLow = this.convertToMilliseconds(config.valueLow);
    this.valueHigh = this.convertToMilliseconds(config.valueHigh);
  }

  addErrorFields(fieldArray) {
    if (!fieldArray) {
      fieldArray = [];
    }
    if (!fieldArray.includes(this.dateField)) {
      fieldArray.push(this.dateField);
    }
    if (!fieldArray.includes(this.comparisonField)) {
      fieldArray.push(this.comparisonField);
    }
    return fieldArray;
  }

  _getSummary() {
    return `The date found in '${this.dateField}'
      should be between ${this.convertFromMilliseconds(this.valueLow)} and ${this.convertFromMilliseconds(this.valueHigh)} ${this.units}
       from the date in '${this.comparisonField}'`;
  }

  _evaluate(record, finalRecord) {
    if (!this.dateField || !this.comparisonField || !record[this.dateField.trim()] || !record[this.comparisonField.trim()]) {
      return {result: false, missingData: true, description: 'Necessary fields not present', fields: this.addErrorFields(null)};
    }
    if (this.doesDateFit(record)) {
      record.errorDetail.push(this.getTriggerMessage());
      this.addErrorFields(record.errorFields);
      return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
    }
    return {result: false, description: this.getNotTriggerMessage(), fields: this.addErrorFields(null)};
  }

  getTriggerMessage() {
    return `The date found in '${this.dateField}'
      was between ${this.convertFromMilliseconds(this.valueLow)} and ${this.convertFromMilliseconds(this.valueHigh)} ${this.units}
       from the date in '${this.comparisonField}'`;
  }

  getNotTriggerMessage() {
    return `The date found in '${this.dateField}'
      was NOT between ${this.convertFromMilliseconds(this.valueLow)} and ${this.convertFromMilliseconds(this.valueHigh)} ${this.units}
       from the date in '${this.comparisonField}'`;
  }

  doesDateFit(record) {
    const dateValue = Date.parse(record[this.dateField.trim()]);
    const relativeDateValue = Date.parse(record[this.comparisonField.trim()]);
    const diff = dateValue - relativeDateValue;
    if (diff >= this.valueLow && diff <= this.valueHigh) {
      return true;
    }
    return false;
  }

  convertToMilliseconds(value) {
    switch (this.units) {
      case "seconds": {
        return value * 1000;
      }
      case "minutes": {
        return value * 60 * 1000;
      }
      case "hours": {
        return value * 60 *  60 * 1000;
      }
      case "days": {
        return value * 24 * 60 * 60 * 1000;
      }
      case "weeks": {
        return value * 7 * 24 * 60 * 60 * 1000;
      }
      case "months": {
        return value * 4.4 * 7 * 24 * 60 * 60 * 1000;
      }
      case "years": {
        return value * 12 * 4.4 * 7 * 24 * 60 * 60 * 1000;
      }
    }
    return value;
  }

  convertFromMilliseconds(value) {
    switch (this.units) {
      case "seconds": {
        return value/1000;
      }
      case "minutes": {
        return value/(60 * 1000);
      }
      case "hours": {
        return value/(60 *  60 * 1000);
      }
      case "days": {
        return value/(24 * 60 * 60 * 1000);
      }
      case "weeks": {
        return value/(7 * 24 * 60 * 60 * 1000);
      }
      case "months": {
        return value/(4.4 * 7 * 24 * 60 * 60 * 1000);
      }
      case "years": {
        return value/(12 * 4.4 * 7 * 24 * 60 * 60 * 1000);
      }
    }
    return value;
  }

  _perturb(record) {
    if (record[this.dateField]) {
      this.perturbValue(record, this.dateField, true);
      record.perturbed = true;
      const newRecord = JSON.parse(JSON.stringify(record));
      newRecord.perturbed_field = this.dateField;
      return newRecord;
    }
  }
}
