import BaseEvaluator from '../base-evaluator.js';

export default class DateComparisonToFieldWithValue extends BaseEvaluator {

    constructor(config) {
      super(config);
      this.name = 'Date Field Comparison with Value';
      this.targetField = config.sourceField;
      this.comparisonField = config.comparisonField;
      this.comparator = config.comparator;
      this.value = config.value;
      this.units = config.units;
    }

    addErrorFields(fieldArray) {
      if (!fieldArray) {
        fieldArray = [];
      }
      if (!fieldArray.includes(this.targetField)) {
        fieldArray.push(this.targetField);
      }
      if (!fieldArray.includes(this.comparisonField)) {
        fieldArray.push(this.comparisonField);
      }
      return fieldArray;
    }

    _getSummary() {
      return `The date found in '${this.targetField}' must be ${this.comparator} than ${this.value}`;
    }

    getTriggerMessage() {
      if (this.comparator === "=") {
        return `(${this.targetField} - ${this.comparisonField}) was equal to ${this.value} ${this.units}`;
      } else if (this.comparator === "!=") {
        return `(${this.targetField} - ${this.comparisonField}) was not equal to ${this.value} ${this.units}`;
      } else if (this.comparator === ">") {
        return `(${this.targetField} - ${this.comparisonField}) was more than ${this.value} ${this.units}`;
      } else if (this.comparator === "<") {
        return `(${this.targetField} - ${this.comparisonField}) was less than ${this.value} ${this.units}`;
      }
    }

    _evaluate(record, finalRecord) {
      if (!this.targetField || !record[this.targetField.trim()] ||
          !this.comparisonField || !record[this.comparisonField.trim()]) {
        return {result: false, missingData: true, description: 'Necessary fields not present', fields: this.addErrorFields(null)};
      }
      const milliseconds = Date.parse(record[this.targetField.trim()]);
      if (isNaN(milliseconds)) {
        return {result: false, badData: true, description: 'Target field not parseable', fields: this.addErrorFields(null)};
      }
      const compareMilliseconds = Date.parse(record[this.comparisonField.trim()]);
      if (isNaN(compareMilliseconds)) {
        return {result: false, badData: true, description: 'Comparison field not parseable', fields: this.addErrorFields(null)};
      }
      const valueMilliseconds = this.convertToMilliseconds(this.value);
      const diff = milliseconds - compareMilliseconds;
      switch (this.comparator) {
        case ">": {
          if (diff > valueMilliseconds){
            record.errorDetail.push(this.getTriggerMessage());
            this.addErrorFields(record.errorFields);
            return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
          }
          break;
        }
        case "<": {
          if (diff < valueMilliseconds){
            record.errorDetail.push(this.getTriggerMessage());
            this.addErrorFields(record.errorFields);
            return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
          }
          break;
        }
        case "=": {
          if (diff === valueMilliseconds){
            record.errorDetail.push(this.getTriggerMessage());
            this.addErrorFields(record.errorFields);
            return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
          }
          break;
        }
        case "!=": {
          if (diff != valueMilliseconds){
            record.errorDetail.push(this.getTriggerMessage());
            this.addErrorFields(record.errorFields);
            return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
          }
          break;
        }
      }
      return {result: false, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
    }

    convertToMilliseconds(value) {
      switch (this.units) {
        case "seconds": {
          return value * 1000;
        }
        case "minutes": {
          return value * 60 * 1000;
        }
        case "hours": {
          return value * 60 *  60 * 1000;
        }
        case "days": {
          return value * 24 * 60 * 60 * 1000;
        }
        case "weeks": {
          return value * 7 * 24 * 60 * 60 * 1000;
        }
        case "months": {
          return value * 4.4 * 7 * 24 * 60 * 60 * 1000;
        }
        case "years": {
          return value * 12 * 4.4 * 7 * 24 * 60 * 60 * 1000;
        }
      }
      return value;
    }

    _perturb(record) {
      if (record[this.targetField] && record[this.comparisonField]) {
        const comparisonTime = Date.parse(record[this.comparisonField]);
        if (this.comparator === "=") {
          record[this.targetField] = new Date(comparisonTime - (1000 * 3600 * 48)).toString();
        } else if (this.comparator === "!=") {
          record[this.targetField] = record[this.comparisonField];
        } else if (this.comparator === ">") {
          record[this.targetField] = record[this.comparisonField];
        } else if (this.comparator === "<") {
          record[this.targetField] = record[this.comparisonField];
        } else if (this.comparator === ">=") {
          record[this.targetField] = new Date(comparisonTime + (1000 * 3600 * 48)).toString();
        } else if (this.comparator === "<=") {
          record[this.targetField] = new Date(comparisonTime - (1000 * 3600 * 48)).toString();
        }
        record.perturbed = true;
        const newRecord = JSON.parse(JSON.stringify(record));
        newRecord.perturbed_field = this.targetField;
        return newRecord;
      }
    }
}
