import { authInitialized, getSessionManager } from "../digital-health-auth/di-authentication";
import APIManager from "./api-manager";
import api from "./core-api";
import Logger from "./logger";

const getAPIManager = () => {
    return api.apiManager;
}

// Component to initialize all API managers
const initCoreAPI = (environment) => {
    const config = require(`./config/default.config.json`);
    let logger = null;
    let loggingConfig = {};
    if (environment.logging) {
      loggingConfig = environment.logging;
    }
    try {
      const startColorRGBArray = [100,100,255];
      const endColorRGBArray = [0,0,50];
      logger = new Logger(startColorRGBArray, endColorRGBArray, 'HDP-SDK');

      logger.debug('Debug message');
      logger.info('Info message');
      logger.warn('Warning message');
      logger.error('Error message');
      logger.fatal('Fatal message');

      const testObject = {attributeA: "valueA", attributeB: 990};
      logger.debug(testObject);
      
    } catch (exception) {
      console.error("Unable to initialize Logger.")
    }
    if (!authInitialized()) {
        logger.warn('Unable to initialize Core API Manager, auth system has not been initialized.')
        return;
    }

    if (!api.initialized) {
        logger.info("Initializing Core API Manager...");
        const apiManager = new APIManager(logger, config.dataManagement);
        let currentApplicationId = 1;
        apiManager.init(getSessionManager(), currentApplicationId, environment);
        api.apiManager = apiManager;
        api.initialized = true;
        console.log('Core API Manager initialized.');
    } else {
        console.log('Core API Manager already initialized.');
    }
    const release = require(`./release_info.json`);
    logger.info(`Data Platform SDK Version: ${release.version}`);
    logger.debug(`Branch: ${release.branch}`);
    logger.debug(`Release info: ${release.notes}`);
}

export { getAPIManager, initCoreAPI }
