import BaseCondition from './base-condition.js';

export default class TruePerRecord extends BaseCondition {

    constructor(config, action) {
      super(config, action);
    }

    proceed() {
      return true;
    }

    _renderResults() {
      if (this.falseResults.length > 0 && this.invert) {
        this.tripped = true;
      } else if (this.trueResults.length > 0 && !this.invert) {
        this.tripped = true;
      } else {
        this.tripped = false;
      }
      return {
        result: this.tripped,
        trueResults: this.trueResults,
        falseResults: this.falseResults,
        offendingRecord: this.offendingRecord
      };
    }

    resultAdded(resultSet, ruleEvaluator, record) {
      if (resultSet.result && !this.invert) {
        this.tripped = true;
        this.offendingRecord = record;
        this.action.takeAction(record);
      } else if (resultSet.result && this.invert) {
        this.tripped = false;
      } else if (!resultSet.result && this.invert) {
        this.tripped = true;
        this.offendingRecord = record;
        this.action.takeAction(record);
      } else if (!resultSet.result && !this.invert) {
        this.tripped = false;
      }
    }
}
