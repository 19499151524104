import BaseAction from './base-action.js';

export default class FlagRecord extends BaseAction {

    constructor(config) {
      super(config);
    }

    takeAction(record) {
      console.log('Flagging....');
      console.log(record);
      record.flag = true;
    }
}
