import {useContext, useState} from "react";
import {APIContext} from "../../utils/api-context";
import projectsFields from "../../entity_attributes/projects_fields";
import {process_data_for_table} from "../../utils/table_utils";
import {Box, Stack} from "@mui/material";
import {CustomButton, Modal, Paper, TableContainer, SearchInput} from "@medtronic/pecc-react-component-library-js";
import VariableCard from "./variable-card";
import moment from 'moment/moment';

function ProjectsDisplayTable() {
    const apiManager = useContext(APIContext);
    const user = apiManager.getCurrentUser();
    const numProjects = apiManager.projectManager.dataArray.length;

    const [viewRecordOpen, setViewRecordOpen] = useState(false);
    const [selectedProject, setSelectedProject] = useState();
    let [searchText, setSearchText] = useState("");

    let columns = projectsFields;
    let data = [];

    if (apiManager.projectManager && numProjects > 0) {
        data = process_data_for_table(apiManager.projectManager.dataArray, columns);
        data.map(project_row => {
            console.log(project_row);

            for (let value of project_row.values) {
                if (value.id.endsWith("-id")) {
                    let dataset_id = value.value;
                    value.value = <CustomButton buttonType="borderless" label="View" href={`/projects/${value.value}`}/>
                }
            }

            return project_row
        })
        console.log(data)
    } else {
        console.log('No projects found / Unable to connect to DB');
        data = [];
    }

    function handleProjectSearchChange(event) {
        setTimeout(setSearchText(event.input), 1000);
    }

    function filterForSearch(searchTerm) {
        let filteredRows = [];

        for (let i = 0; i < data.length; i++) {
            let row_attrs = data[i].values;
            let compareStr = "";

            for (let j = 0; j < row_attrs.length; j++) {
                let id = row_attrs[j].id;
                if (typeof id !== "string") {
                    id = id.toString();
                }
                id = id.toLowerCase();

                let val = row_attrs[j].value;
                if (typeof val !== "string") {
                    val = row_attrs[j].value.toString();
                }
                val = val.toLowerCase();
                
                if ((id.includes("created") && !id.includes("created_by")) || 
                (id.includes("updated") && !id.includes("updated_by"))) {
                    val = moment.utc(val).local().format("MM/DD/YYYY"); 
                    row_attrs[j].value = val; 
                }

                const attrsOfInterest = [
                    "name",
                    "description",
                    "type",
                    "status",
                    "group_name",
                    "cost_center",
                    "created_by",
                    "created",
                    "updated_by",
                    "updated"
                ]

                for (let attr of attrsOfInterest) {
                    if (id.includes(attr)) {
                        compareStr += `${val} `;
                    }
                }
               
            }

            if (compareStr.includes(searchTerm.trim().toLowerCase()))
                filteredRows.push(data[i]);
        }

        //add an empty-string row. workaround needed as the table is not refreshing for empty row array
        if (filteredRows.length == 0) {
            filteredRows.push(data[0]);
            for (let i=0; i < data[0].values.length; i++) {
                filteredRows[0].values[i].value = "";
            }
        }        

        return filteredRows;
    }

    return <Paper size="xl" sx={{padding: "1rem"}}>
        <Stack direction="row-reverse" paddingBottom="1rem">
            <SearchInput size={"small"} onChange={handleProjectSearchChange} value={searchText}/>
        </Stack>
        <Box>
            <TableContainer
                rows={filterForSearch(searchText)}
                columns={columns}
            />
        </Box>
        <Modal
            open={viewRecordOpen}
            toggleOpenFn={() => setViewRecordOpen(!viewRecordOpen)}
        >
            <VariableCard contents={selectedProject}/>
        </Modal>
    </Paper>
}

export default ProjectsDisplayTable;
