import {spacify, titleCase} from '../../utils/text-utils';
import {Typography} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import {LabelAndValue} from "@medtronic/pecc-react-component-library-js"; // Grid version 2

function display_key_content(content) {
    try {
        switch (typeof content) {
            case "object":
                return <pre>{JSON.stringify(content, null, 2)}</pre>;
            case "boolean":
                return content ? "Yes" : "No";
            default:
                return content;
        }
    } catch {
        return "Error displaying content";
    }
}

function VariableCard({contents}) {
    let cardContents = [];
    console.log(contents);

    if (!!contents) {
        cardContents = Object.keys(contents).map(key => (<LabelAndValue label={titleCase(spacify(key))} value={!!contents[key] ? display_key_content(contents[key]) : "No Data"}/>))
    } else {
        cardContents = <Typography variant="h2" key="varcarderr">Error</Typography>;
    }
    return <Grid container sx={{margin: "2rem", width: "97.9%", height: "82vh", overflow: "scroll"}}>
        {cardContents}
    </Grid>
}

export default VariableCard;
