import CRUDManager from "./crud-manager";
import DataView from "./data-view";

// CRUD SDK for 'data_view' table
class DataViewManager extends CRUDManager{

    constructor(sessionManager, logger, config, environment, pullData) {
        super('DataView', 'dataview', sessionManager, logger, config, environment, pullData);
    }

    getNewEntity(json) {
        const newEntity = new DataView();
        newEntity.setAttributes(json);
        return newEntity;
    }

    // CREATE dataview
    async createDataview(newDataview) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at DATAVIEW manager in getting headers during create dataview");
            throw headersError;
        }
        let result = null;
        try {
            result = await super.create(newDataview, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // UPDATE exising dataview
    async updateDataview(updatedDataview) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at DATAVIEW manager in getting headers during update dataview");
            throw headersError;
        }
        let result = null;
        try {
            await super.update(updatedDataview, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // DELETE existing dataview
    async deleteDataView(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at DATAVIEW manager in getting headers during delete dataview");
            throw headersError;
        }
        let result = null;
        try {
            await super.delete(id, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // GET ALL dataviews
    async getAllDataViews(onSuccess, onFailure) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at DATAVIEW manager in getting headers during get all dataviews");
            throw headersError;
        }
        let allEntities = [];
        try {
            let result = await super.getAll(headersResult, onSuccess, onFailure);
            for (let record of result) {
                let entity = new DataView();
                entity.setAttributes(record);
                allEntities.push(entity);
            }
        } catch (error) {
            this.logger.error(error.message);
        }
        return allEntities;
    }

    // GET dataview based on id
    async getDataView(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at DATAVIEW manager in getting headers during get dataview");
            throw headersError;
        }
        try {
            let result = await super.getOne(id, headersResult);
            return result;
        } catch (error) {
            this.logger.error(error.message);
            return null;
        }
    }

}

export default DataViewManager;
