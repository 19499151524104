import BaseSelector from './base-selector.js';

export default class BatchBasedSelector extends BaseSelector {

  constructor(config, records) {
    super(config, records);
    this.records = [];
    this.newBatchReady = false;
    this.currentBatchCounter = null;
    this.batches = {};
    this.fieldName = config.fieldName;

    for (let record of records) {
      if (!this.batches[record[this.fieldName]]) {
        this.batches[record[this.fieldName]] = [];
      }
      this.batches[record[this.fieldName]].push(record);
    }
    this.currentBatchCounter = 0;
    const key = Object.keys(this.batches)[this.currentBatchCounter];
    this.records = this.batches[key];
  }

  hasNextRecord() {
    if (!this.records) {
      console.log("No batches found for batch selector");
      return false;
    }
    if (this.counter < this.records.length) {
      return true;
    } else {
      this.currentBatchCounter++;
      const keys = Object.keys(this.batches);
      if (this.currentBatchCounter >= keys.length) {
        return false;
      } else {
        this.records = this.batches[keys[this.currentBatchCounter]];
        this.counter = 0;
        this.newBatchReady = true;
        return true;
      }
    }
  }

  hasNewBatch() {
    if (this.newBatchReady) {
      this.newBatchReady = false;
      return true;
    }
  }
}
