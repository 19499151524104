import RestManager from "../rest-manager";
import Environment from "../config/environment";
import EventNotifier from "../event-notifier";

// Delivers config data to client
class ConfigurationManager {

    constructor(sessionManager, logger, environment) {
        this.sessionManager = sessionManager;
        this.logger = logger;
        this.eventNotifier = new EventNotifier(this.logger);
        this.restManager = new RestManager(this.eventNotifier, new Environment(environment));
        this.dataCache = {};
    }

    async fetchCRUDRules(entityName) {
        let rules = await this.getConfigurations(`rules-${entityName}`);
        if (!rules) {
            rules = {};
        }
        return rules;
    }

    fetchWorkbenchConfigs() {
        return this.getConfigurations("workbench");
    }

    fetchJobClusterConfigs() {
        return this.getConfigurations("jobcluster");
    }

    async fetchOpsRules(operation){
        return {};
    }

    async getConfigurations(type) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at CONFIGURATIONS manager in getting headers during get configurations");
            throw headersError;
        }
        this.logger.info(`Fetching ${type} configurations`);
        let result = null;
        try {
            result = await this.restManager.get(`configurations/${type}`, headersResult);
        } catch (error) {
            this.logger.error(`Error encountered at ops manager in getting configuration content for: ${type}`);
            return null;
        }
        this.dataCache[type] = result;
        return result;
    }

    getWorkbenchEntries() {
        const entries = [];
        if (this.dataCache && this.dataCache["workbench"]) {
            for (let workbenchConfig of this.dataCache["workbench"]) {
                let entry =
                    {
                        id: workbenchConfig.id,
                        type: workbenchConfig.type,
                        workbenchGroup: workbenchConfig.workbenchGroup,
                        name: workbenchConfig.name,
                        description: workbenchConfig.description
                    };
                entries.push(entry);
            }
        }
        return entries;
    }

    getWorkbenchGroups() {
        const groups = [];
        if (this.dataCache && this.dataCache["workbench"]) {
            for (let workbenchConfig of this.dataCache["workbench"]) {
                if (groups.indexOf(workbenchConfig.workbenchGroup) === -1) {
                    groups.push(workbenchConfig.workbenchGroup);
                }
            }
        }
        return groups;
    }

    getWorkbenchNames() {
        const names = [];
        if (this.dataCache && this.dataCache["workbench"]) {
            for (let workbenchConfig of this.dataCache["workbench"]) {
                if (names.indexOf(workbenchConfig.name) === - 1)
                    names.push(workbenchConfig.name);
            }
        }
        return names;
    }

    getWorkbenchIdsByGroup(workbenchGroupName) {
        const ids = [];
        if (this.dataCache && this.dataCache["workbench"]) {
            for (let workbenchConfig of this.dataCache["workbench"]) {
                if ((workbenchConfig.workbenchGroup === workbenchGroupName) && (ids.indexOf(workbenchConfig.id) === -1)) {
                    ids.push(workbenchConfig.id);
                }
            }
        }
        return ids;
    }

    getJobClusterEntries() {
        const types = [];
        if (this.dataCache && this.dataCache["jobcluster"]) {
            for (let jobClusterConfig of this.dataCache["jobcluster"]) {
                let entry = {id: jobClusterConfig.id, name: jobClusterConfig.name}
                types.push(entry);
            }
        }
        return types;
    }

    getConfiguration() {
        return {
            costCenters: ["MDT_PL_1034", "MDT_PL_1045"],
            objectRoles: [
                {
                    id: 12,
                    name: "",
                    description: "",
                    appliesTo: []
                }
            ]
        }
    }
}

export default ConfigurationManager;
