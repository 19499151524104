function process_data_for_table(data, columns=[]) {
    let rows = [];
    for (let i = 0; i < data.length; i++) {
        let data_row = {
            id: i.toString(),
            values: [],
        }
        for (let attribute of columns) {
            let datumElement;
            if (!attribute.key.includes('.')) {
                datumElement = data[i][attribute.key];
            } else {
                let path = attribute.key.split('.');
                datumElement = data[i];
                for (let i = 0; i < path.length; i++) {
                    if (Object.keys(datumElement).includes(path[i])) {
                        datumElement = datumElement[path[i]];
                    }
                }
            }
            if ('postprocess_func' in attribute) {
                try {
                    datumElement = attribute.postprocess_func(datumElement);
                } catch (e) {
                    datumElement = `Error (${e})`;
                }
            }
            else if (typeof datumElement !== "string") {
                try {
                    datumElement = JSON.stringify(datumElement)
                } catch {
                    datumElement = "error";
                }
            }
            data_row.values.push({
                value: datumElement,
                id: `${i}-${attribute.key}`,
            });
        }
        rows.push(data_row);
    }
    console.log(rows)
    return rows;
}

function postprocess_data_for_table(data, columns_config) {
    for (let row of data) {
        for (let col of columns_config.filter(col => 'postprocess_func' in col)) {
            row.value = col.postprocess_func(row.value);
        }
    }

    return data;
}

function zip_arrays_to_obj(keys, vals) {
    let together_obj = {}
    for (let i=0; i < keys.length; i++) {
        together_obj[keys[i]] = vals[i];
    }
    return together_obj;
}

export {process_data_for_table, zip_arrays_to_obj};
