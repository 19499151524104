import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import * as React from "react";
import * as PropTypes from "prop-types";

function ConfirmationDialog({open, handleClose, dialogTitle, dialogContent, primaryLabel, secondaryLabel, onPrimaryClick, onSecondaryClick}) {
    return <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">
            {dialogTitle}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="confirm-workbench-termination-dialog">
                {dialogContent}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button variant="outlined"
                    onClick={onSecondaryClick}
            >
                {secondaryLabel}
            </Button>
            <Button variant="contained"
                    onClick={onPrimaryClick}
                    autoFocus
            >
                {primaryLabel}
            </Button>
        </DialogActions>
    </Dialog>;
}

ConfirmationDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    dialogTitle: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    dialogContent: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    primaryLabel: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    secondaryLabel: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    onPrimaryClick: PropTypes.func,
    onSecondaryClick: PropTypes.func,
};

export default ConfirmationDialog;
