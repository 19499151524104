export function titleCase(str) {
    return str.toLowerCase().split(' ').map(function (word) {
        return word.replace(word[0], word[0].toUpperCase());
    }).join(' ');
}

export function spacify(str) {
    const spacer_pattern = /[-_]/g;
    return str.replace(spacer_pattern, ' ');
}

export function generateID() {
    return Math.random().toString(36).slice(2, 9);
}
