import AddField from './actions/add-field.js';
import ChangeField from './actions/change-field.js';
import CreateAlert from './actions/create-alert.js';
import FlagRecord from './actions/flag-record.js';
import Notify from './actions/notify.js';
import DoNothing from './actions/do-nothing.js';

export default class ActionFactory {

    constructor() {

    }

    getAction(config) {
        switch (config.type) {
          case "addField": return new AddField(config);
          case "changeField": return new ChangeField(config);
          case "createAlert": return new CreateAlert(config);
          case "flagRecord": return new FlagRecord(config);
          case "notify": return new Notify(config);
          case "doNothing": return new DoNothing(config);
        }
        console.log(`No action found: '${config.type}'`);
    }
}
