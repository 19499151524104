import RoleRelation from "./role-relation";

class DataviewRole extends RoleRelation {

    constructor() {
        super();
        this.dataview_id = -1;
    }

    setAttributes(json) {
        super.setAttributes(json);
        this.dataview_id = json.dataview_id;
    }
}

export default DataviewRole;
