import RestManager from "../rest-manager";

// SDK for Healthcheck API call
class PrivilegesManager {

    constructor(sessionManager) {
        this.sessionManager = sessionManager;
        this.restManager = new RestManager();
    }

    __getBlankPrivilegeSet() {
      const privileges = {
        application: {
          canView: false,
          canCreate: false,
          canUpdate: false,
          canDelete: false
        },
        dataset: {
          canView: false,
          canCreate: false,
          canUpdate: false,
          canDelete: false,
          canAddUser: false,
          canCreateView: false
        },
        dataview: {
          canView: false,
          canCreate: false,
          canUpdate: false,
          canDelete: false,
          canUse: false
        },
        project: {
          canView: false,
          canCreate: false,
          canUpdate: false,
          canClose: false,
          canAddDataset: false,
          canAddUser: false
        },
        workbench: {
          canView: false,
          canCreate: false,
          canStart: false,
          canStop: false,
          canTerminate: false,
          canDelete: false,
          canAccess: false
        },
        jobCluster: {
          canView: false,
          canCreate: false,
          canStart: false,
          canStop: false,
          canTerminate: false,
          canDelete: false
        },
        artifact: {
          canView: false,
          canAccess: false,
          canDelete: false
        }
      };
      return privileges;
    }

    __grantPrivileges(privileges, applicationRoleId) {
      switch (applicationRoleId) {
        case 1: //administrator
          privileges.dataset.canView = true;
          privileges.dataset.canUpdate = true;
          privileges.project.canView = true;
          privileges.project.canCreate = true;
          privileges.project.canUpdate = true;
          privileges.project.canClose = true;
          privileges.project.canAddDataset = true;
          privileges.project.canAddUser = true;
          privileges.workbench.canView = true;
          privileges.jobCluster.canView = true;
          privileges.artifact.canView = true;
          privileges.artifact.canAccess = true;
          break;
        case 2: //data_scientist
          privileges.dataset.canView = true;
          privileges.project.canView = true;
          privileges.project.canCreate = true;
          privileges.project.canUpdate = true;
          privileges.project.canClose = true;
          privileges.project.canAddDataset = true;
          privileges.project.canAddUser = true;
          privileges.workbench.canView = true;
          privileges.workbench.canCreate = true;
          privileges.workbench.canStart = true;
          privileges.workbench.canStop = true;
          privileges.workbench.canTerminate = true;
          privileges.workbench.canDelete = true;
          privileges.workbench.canAccess = true;
          privileges.jobCluster.canView = true;
          privileges.jobCluster.canCreate = true;
          privileges.jobCluster.canStart = true;
          privileges.jobCluster.canStop = true;
          privileges.jobCluster.canTerminate = true;
          privileges.jobCluster.canDelete = true;
          privileges.artifact.canView = true;
          privileges.artifact.canAccess = true;
          break;
        case 3: //technician
          privileges.dataset.canView = true;
          privileges.project.canView = true;
          privileges.project.canUpdate = true;
          privileges.workbench.canView = true;
          privileges.workbench.canCreate = true;
          privileges.workbench.canStart = true;
          privileges.workbench.canStop = true;
          privileges.workbench.canTerminate = true;
          privileges.workbench.canDelete = true;
          privileges.workbench.canAccess = true;
          privileges.jobCluster.canView = true;
          privileges.jobCluster.canCreate = true;
          privileges.jobCluster.canStart = true;
          privileges.jobCluster.canStop = true;
          privileges.jobCluster.canTerminate = true;
          privileges.jobCluster.canDelete = true;
          privileges.artifact.canView = true;
          privileges.artifact.canAccess = true;
          break;
        case 4: //compliance_analyst
          privileges.application.canView = true;
          privileges.dataset.canView = true;
          privileges.project.canView = true;
          privileges.workbench.canView = true;
          privileges.jobCluster.canView = true;
          privileges.artifact.canView = true;
          privileges.artifact.canAccess = true;
          break;
        case 5: //data_owner
          privileges.dataset.canView = true;
          privileges.dataset.canUpdate = true;
          privileges.dataset.canAddUser = true;
          privileges.dataset.canCreateView = true;
          privileges.project.canView = true;
          privileges.artifact.canView = true;
          break;
        case 6: //tester
          privileges.application.canView = true;
          privileges.application.canCreate = true;
          privileges.application.canUpdate = true;
          privileges.application.canDelete = true;
          privileges.dataset.canView = true;
          privileges.dataset.canCreate = true;
          privileges.dataset.canUpdate = true;
          privileges.dataset.canDelete = true;
          privileges.dataset.canAddUser = true;
          privileges.dataset.canCreateView = true;
          privileges.dataview.canView = true;
          privileges.dataview.canCreate = true;
          privileges.dataview.canUpdate = true;
          privileges.dataview.canDelete = true;
          privileges.dataview.canUse = true;
          privileges.project.canView = true;
          privileges.project.canCreate = true;
          privileges.project.canUpdate = true;
          privileges.project.canClose = true;
          privileges.project.canAddDataset = true;
          privileges.project.canAddUser = true;
          privileges.workbench.canView = true;
          privileges.workbench.canCreate = true;
          privileges.workbench.canStart = true;
          privileges.workbench.canStop = true;
          privileges.workbench.canTerminate = true;
          privileges.workbench.canDelete = true;
          privileges.workbench.canAccess = true;
          privileges.jobCluster.canView = true;
          privileges.jobCluster.canCreate = true;
          privileges.jobCluster.canStart = true;
          privileges.jobCluster.canStop = true;
          privileges.jobCluster.canTerminate = true;
          privileges.jobCluster.canDelete = true;
          privileges.artifact.canView = true;
          privileges.artifact.canAccess = true;
          privileges.artifact.canDelete = true;
          break;
        case 7: //developer
          privileges.application.canView = true;
          privileges.application.canCreate = true;
          privileges.application.canUpdate = true;
          privileges.application.canDelete = true;
          privileges.dataset.canView = true;
          privileges.dataset.canCreate = true;
          privileges.dataset.canUpdate = true;
          privileges.dataset.canDelete = true;
          privileges.dataset.canAddUser = true;
          privileges.dataset.canCreateView = true;
          privileges.dataview.canView = true;
          privileges.dataview.canCreate = true;
          privileges.dataview.canUpdate = true;
          privileges.dataview.canDelete = true;
          privileges.dataview.canUse = true;
          privileges.project.canView = true;
          privileges.project.canCreate = true;
          privileges.project.canUpdate = true;
          privileges.project.canClose = true;
          privileges.project.canAddDataset = true;
          privileges.project.canAddUser = true;
          privileges.workbench.canView = true;
          privileges.workbench.canCreate = true;
          privileges.workbench.canStart = true;
          privileges.workbench.canStop = true;
          privileges.workbench.canTerminate = true;
          privileges.workbench.canDelete = true;
          privileges.workbench.canAccess = true;
          privileges.jobCluster.canView = true;
          privileges.jobCluster.canCreate = true;
          privileges.jobCluster.canStart = true;
          privileges.jobCluster.canStop = true;
          privileges.jobCluster.canTerminate = true;
          privileges.jobCluster.canDelete = true;
          privileges.artifact.canView = true;
          privileges.artifact.canAccess = true;
          privileges.artifact.canDelete = true;
          break;
        case 8: //support
          privileges.dataset.canView = true;
          privileges.dataset.canUpdate = true;
          privileges.project.canView = true;
          privileges.project.canUpdate = true;
          privileges.project.canAddDataset = true;
          privileges.project.canAddUser = true;
          privileges.workbench.canView = true;
          privileges.jobCluster.canView = true;
          privileges.artifact.canView = true;
          privileges.artifact.canAccess = true;
          break;
        case 9: //guest
          privileges.application.canView = true;
          privileges.dataset.canView = true;
          privileges.dataview.canView = true;
          privileges.project.canView = true;
          privileges.workbench.canView = true;
          privileges.jobCluster.canView = true;
          privileges.artifact.canView = true;
          break;
        case 10: //data_uploader
          privileges.dataset.canView = true;
          privileges.dataset.canCreate = true;
          privileges.project.canView = true;
          privileges.artifact.canView = true;
          break;
        case 11: //testing_god
          privileges.dataset.canView = true;
          privileges.dataset.canCreate = true;
          privileges.dataset.canUpdate = true;
          privileges.dataset.canDelete = true;
          privileges.dataset.canAddUser = true;
          privileges.dataset.canCreateView = true;
          privileges.dataview.canView = true;
          privileges.dataview.canCreate = true;
          privileges.dataview.canUpdate = true;
          privileges.dataview.canDelete = true;
          privileges.dataview.canUse = true;
          privileges.project.canView = true;
          privileges.project.canCreate = true;
          privileges.project.canUpdate = true;
          privileges.project.canClose = true;
          privileges.project.canAddDataset = true;
          privileges.project.canAddUser = true;
          privileges.workbench.canView = true;
          privileges.workbench.canCreate = true;
          privileges.workbench.canStart = true;
          privileges.workbench.canStop = true;
          privileges.workbench.canTerminate = true;
          privileges.workbench.canDelete = true;
          privileges.workbench.canAccess = true;
          privileges.jobCluster.canView = true;
          privileges.jobCluster.canCreate = true;
          privileges.jobCluster.canStart = true;
          privileges.jobCluster.canStop = true;
          privileges.jobCluster.canTerminate = true;
          privileges.jobCluster.canDelete = true;
          privileges.artifact.canView = true;
          privileges.artifact.canAccess = true;
          privileges.artifact.canDelete = true;
          break;
      }
    }

    // Has the side-effect of decorating the current user with data related to current role,
    // and privileges for datasets, projects, and dataviews
    setPrivileges(currentUser, currentApplication, applicationRoles) {
        const privileges = this.__getBlankPrivilegeSet();
        for (let applicationRole of applicationRoles) {
          if (applicationRole.application_id === 1 && applicationRole.user_id === currentUser.id) {
            console.log(`Granting privileges associated with role ${applicationRole.role_id} for current user`);
            this.__grantPrivileges(privileges, applicationRole.role_id);
          }
        }
        currentUser.privileges = privileges;
    }
}

export default PrivilegesManager;
