class AuthManager {

    constructor (environment) {
        this.environment = environment;
    }

    async init(authSettings, entryParams, setCookie) {
      throw new Error("Method #init is not present in abstract class parent and must be implemented in child.");
    }

    async getAllTokens (oneTimeUseCode) {
        throw new Error("Method #getAllTokens is not present in abstract class parent and must be implemented in child.");
    }

    async refreshTokens () {
        throw new Error("Method #refreshTokens is not present in abstract class parent and must be implemented in child.");
    }

    getAuthHeaders() {
        throw new Error("Method #getAllTokens is not present in abstract class parent and must be implemented in child.");
    }

    refreshNeeded() {
        const now = new Date().getTime();
        if (this.expiresAt < now) {
          return true;
        }
        return false;
    }

    getAccessToken() {
        return this.accessToken;
    }

    getAccessTokenPayload() {
        return this.decodeToken(this.accessToken);
    }

    decodeToken (base64Token) {
      if (!base64Token){
        return null;
      }
      let base64Url = base64Token.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    }
}

export default AuthManager;
