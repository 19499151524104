import {Typography, Box} from "@mui/material";


function NoMatch() {
    return <Box sx={{margin: "auto", width: "94%", paddingTop: "2rem"}}>
        <Typography variant={"h2"} gutterBottom>Page Not Found</Typography>
    </Box>
}

export default NoMatch;
