import CRUDManager from "./crud-manager";
import Project from "./project";

// CRUD SDK for 'project' table
class ProjectManager extends CRUDManager{

    constructor(sessionManager, logger, config, environment, pullData) {
        super('Project', 'project', sessionManager, logger, config, environment, pullData);
    }

    // CREATE project
    async createProject(newProject) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at PROJECT manager in getting headers during create project");
            throw headersError;
        }
        let result = null;
        try {
            result = await super.create(newProject, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    getNewEntity(json) {
        const newEntity = new Project();
        newEntity.setAttributes(json);
        return newEntity;
    }

    // UPDATE project
    async updateProject(updatedProject) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at PROJECT manager in getting headers during update project");
            throw headersError;
        }
        let result = null;
        try {
            await super.update(updatedProject, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // DELETE project based on id
    async deleteProject(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at PROJECT manager in getting headers during delete project");
            throw headersError;
        }
        let result = null;
        try {
            await super.delete(id, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // GET ALL projects
    async getAllProjects(onSuccess, onFailure) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at PROJECT manager in getting headers during get all projects");
            throw headersError;
        }
        let allEntities = [];
        try {
            let result = await super.getAll(headersResult, onSuccess, onFailure);
            for (let record of result) {
                let entity = new Project();
                entity.setAttributes(record);
                allEntities.push(entity);
            }
        } catch (error) {
            this.logger.error(error.message);
        }
        return allEntities;
    }

    // GET a project based on Id
    async getProject(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at PROJECT manager in getting headers during get project");
            throw headersError;
        }
        try {
            let result = await super.getOne(id, headersResult);
            return result;
        } catch (error) {
            this.logger.error(error.message);
            return null;
        }
    }
}

export default ProjectManager;
