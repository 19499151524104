
export default class ExplicitRule {

    constructor(pullData, logger) {
        this.pullData = pullData;
        this.logger = logger;
        this.entityData = {};
        this.name = "Unknown rule";
        this.description = "Unknown description";
        this.offendingData = [];
    }

    runRule(path, method, payload) {
        this.logger.info("Running rule: " + this.name);
        this.entityData = this.pullData();
        let finding = this.__executeRule(path, method, payload);
        if (!finding) {
            return {success: true, message: "NO FINDING", description: this.description, severity: "warning", details:null};
        }
        return finding;
    }

    __executeRule(path, method, payload) {
        console.log("Error - child must implement #__excuteRule()");
        return {success: false, message: this.description, severity: "failure", details:null};
    }

    getDataset(datasetId) {
        const datasets = this.entityData["Dataset"];
        for (let dataset of datasets) {
            if (dataset.id === datasetId) {
                return dataset;
            }
        }
    }

    getUser(userId) {
        const appUsers = this.entityData["AppUser"];
        for (let appUser of appUsers) {
            if (appUser.id === userId) {
                return appUser;
            }
        }
    }

    getProjectUserIds(projectId) {
        const projectUserIds = [];
        const projectRoles = this.entityData["ProjectRole"];
        for (let relation of projectRoles) {
            if (relation["project_id"] === projectId) {
                projectUserIds.push(relation["user_id"]);
            }
        }
        return projectUserIds;
    }

    getProjectDatasetIds(projectId) {
        const projectDatasetIds = [];
        const datasetProjectRelations = this.entityData["DatasetProjectRelation"];
        for (let relation of datasetProjectRelations) {
            if (relation["project_id"] === projectId) {
                projectDatasetIds.push(relation["dataset_id"]);
            }
        }
        return projectDatasetIds;
    }

    getProjectDatasets(projectId) {
        const projectDatasets = [];
        const projectDatasetIds = this.getProjectDatasetIds(projectId);
        const datasets = this.entityData["Dataset"];
        for (let dataset of datasets) {
            if (projectDatasetIds.includes(dataset.id)) {
                projectDatasets.push(dataset);
            }
        }
        return projectDatasets;
    }

    getViolationDetails() {
        // Child classes must override this method
        return "No details available";
    }


}