import BaseAction from './base-action.js';

export default class AddField extends BaseAction {

  constructor(config) {
    super(config);
    this.fieldName = config.fieldName;
    this.defaultValue = config.defaultValue;
  }

    takeAction(record) {
      record[this.fieldName] = this.defaultValue;
    }
}
