import {Box, Paper, Typography} from "@mui/material";
import {Breadcrumbs, Tabs} from "@medtronic/pecc-react-component-library-js";
import {useParams} from "react-router-dom";
import UnderConstruction from "./under-construction";
import ProjectDetailsPane from "../pages/project-details-pane";
import WorkbenchControlPanel from "../page-content-components/workbench-control-panel";
import {useContext, useState} from "react";
import {APIContext} from "../../utils/api-context";
import UserPrivilegesControlPanel from "../page-content-components/user-privileges-control-panel";
import EventMediator from "../../utils/event-mediator";
import RestNotifier from "../page-content-components/rest-notifier";

const PROJECT_DETAILS_TAB = "Project Details";
const WORKBENCH_TAB = "Workbench";
const PROJECT_MEMBERS_TAB = "Project Members";
const MODELS_TAB = "Models";
const INSTRUCTIONS_TAB = "Instructions For Use";
const EXT_RESOURCES_TAB = "External Resources";


function ProjectPane() {
    const {projectid} = useParams();
    const [project, setProject] = useState(null);
    const apiManager = useContext(APIContext);
    const user = apiManager.getCurrentUser();
    const workbenchEventMediator = new EventMediator();
    const jobClusterEventMediator = new EventMediator();

    apiManager.projectManager.getProject(projectid).then(received => {
        console.log(received);
        if (!project) {
            setProject(received);
            if (!received.workbenches) {
              received.workbenches = [];
            }
            apiManager.getUpdatedWorkbenchStatuses(received, () => {console.log(`Workbench statuses updated for project ${projectid}`)});
        }
    }).catch(e => console.error(e));


    function isTabEnabled(tabName) {
        let role_id = -1;

        //Check if the user has any project access
        if (projectid in user.projectRoles) {
            role_id = user.projectRoles[projectid].role_id;
        }

        //Get the privileges
        const privileges = apiManager.projectRoleManager.getBlankProjectPrivileges();
        apiManager.projectRoleManager.getRolePrivilege(role_id, privileges);

        switch (tabName) {
            case PROJECT_DETAILS_TAB:
                return privileges.projectDetailsTab.canView;
            case WORKBENCH_TAB:
                return privileges.workbenchTab.canView;
            case PROJECT_MEMBERS_TAB:
                return privileges.projectMembersTab.canView;
            case MODELS_TAB:
                return privileges.modelsTab.canView;
            case INSTRUCTIONS_TAB:
                return privileges.instructionsForUseTab.canView;
            case EXT_RESOURCES_TAB:
                return privileges.externalResourcesTab.canView;
            default:
                //landing page, data catalog->projects tab
                return true;
        }        
    }

    apiManager.projectRoleManager.getAllProjectRoles().then(resp => {
        const roles = resp
            .filter(projectRole => projectRole.project_id == projectid)
            .map(projectRole => projectRole.user_id);
        if (!project.roster) {
            setProject({...project, roster: roles});
        }
        console.log(roles);
    }).catch(err => console.error(err));

    const projectTabs = [
        {
            label: PROJECT_DETAILS_TAB,
            content: <ProjectDetailsPane project={project} setProject={setProject}/>,
            visible: isTabEnabled(PROJECT_DETAILS_TAB),
        }, {
            label: WORKBENCH_TAB,
            content: <WorkbenchControlPanel workbenchEventMediator={workbenchEventMediator} jobClusterEventMediator={jobClusterEventMediator}/>,
            visible: isTabEnabled(WORKBENCH_TAB),
        }, {
            label: PROJECT_MEMBERS_TAB,
            content: <UserPrivilegesControlPanel/>,
            visible: isTabEnabled(PROJECT_MEMBERS_TAB),
        }, {
            label: MODELS_TAB,
            content: <UnderConstruction/>,
            visible: isTabEnabled(MODELS_TAB),
        }, {
            label: INSTRUCTIONS_TAB,
            content: <UnderConstruction/>,
            visible: isTabEnabled(INSTRUCTIONS_TAB),
        }, {
            label: EXT_RESOURCES_TAB,
            content: <UnderConstruction/>,
            visible: isTabEnabled(EXT_RESOURCES_TAB),
        }
    ]

    return !!project ? <Box sx={{width: "100%", margin: "auto"}}>
            <Typography variant="h2" sx={{justify: "center"}}>Projects - <strong>{project.name}</strong></Typography>
            <Breadcrumbs linksData={[{label: "Projects", href: "/projects"}, {
                label: project.name,
                href: `/projects/${projectid}`
            }]}/>
            <Paper elevation={2} sx={{marginTop: "1rem", width: "100%"}}>
                <Tabs
                    isLeftAlign
                    disableBottomBorder
                    tabsData={projectTabs.filter(tab => tab.visible)}
                    width="100%"
                    textTransform="none"
                />
            </Paper>
            <RestNotifier eventMediator={workbenchEventMediator} successMessage={"Workbench created successfully"} warningMessage={"Warnings detected, see logs."} failureMessage={"Workbench creation failed. Try again later."} />
            <RestNotifier eventMediator={jobClusterEventMediator} successMessage={"Job Cluster created successfully"} warningMessage={"Warnings detected, see logs."} failureMessage={"Job Cluster creation failed. Try again later."} />
        </Box> :
        <Typography variant="h1">Project Not Found</Typography>
}

export default ProjectPane;
