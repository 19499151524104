import CRUDManager from "./crud-manager";
import ProjectRole from "./project-role";
import CompatibleDatasetsRule from "../rules/explicit-rules/compatible-datasets.rule";
import NewProjectUserRule from "../rules/explicit-rules/new-project-user.rule";
import NewProjectDatasetRule from "../rules/explicit-rules/new-project-dataset.rule";

// CRUD SDK for 'projectRole' table
class ProjectRoleManager extends CRUDManager{

    constructor(sessionManager, logger, config, environment, pullData) {
        super('PROJECT_ROLE', 'project-role', sessionManager, logger, config, environment, pullData);
    }

    postInit() {
        const explicitRules = [
            new NewProjectUserRule(this.pullData, this.logger)
        ];
        this.setExplicitRuleSet('newMember', explicitRules);
    }

    getNewEntity(json) {
        const newEntity = new ProjectRole();
        newEntity.setAttributes(json);
        return newEntity;
    }

    // CREATE projectRole
    async createProjectRole(newProjectRole) {
        const explicitRules = [
            new NewProjectUserRule(this.pullData, this.logger)
        ];
        this.setExplicitRules(explicitRules);
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at PROJECT ROLE manager in getting headers during create project role");
            throw headersError;
        }
        let result = null;
        try {
            result = await super.create(newProjectRole, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // UPDATE projectRole
    async updateProjectRole(updatedProjectRole) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at PROJECT ROLE manager in getting headers during update project role");
            throw headersError;
        }
        let result = null;
        try {
            await super.update(updatedProjectRole, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // DELETE projectRole based on id
    async deleteProjectRole(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at PROJECT ROLE manager in getting headers during delete project role");
            throw headersError;
        }
        let result = null;
        try {
            await super.delete(id, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // GET ALL projectRoles
    async getAllProjectRoles(onSuccess, onFailure) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at PROJECT ROLE manager in getting headers during get all project roles");
            throw headersError;
        }
        let allEntities = [];
        try {
            let result = await super.getAll(headersResult, onSuccess, onFailure);
            for (let record of result) {
                let entity = new ProjectRole();
                entity.setAttributes(record);
                allEntities.push(entity);
            }
        } catch (error) {
            this.logger.error(error.message);
        }
        return allEntities;
    }

    // GET a projectRole based on Id
    async getProjectRole(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at PROJECT ROLE manager in getting headers during get project role");
            throw headersError;
        }
        try {
            let result = await super.getOne(id, headersResult);
            return result;
        } catch (error) {
            this.logger.error(error.message);
            return null;
        }
    }

    // setup project privileges empty object
    getBlankProjectPrivileges() {
        const privileges = {
            landingPage: {
                canView: false,
                canInteractTabs : false,  //view all/active/inactive/MyOU project tabs
                canSearchProjects : false,
                canCreateProject : false,
            },
            projectDetailsTab: {
                canView: false,
                canEdit: false  //change project attributes, add or remove datasets, de-activate project
            },
            workbenchTab: {
                canView: false,
                canCreate: false,
                canOperate: false,       //start, stop, terminate : workbenches and job-clusters of same user
                canOperateOther: false,  //start, stop, terminate : workbenches and job-clusters of other user (from same project)
                canRefresh: false   //'Refresh' button on workbench screen
            },
            projectMembersTab: {
                canView: false,
                canOperate: false  //add or remove user, change permissions, change team lead
            },
            modelsTab: {
                canView: false
            },
            instructionsForUseTab: {
                canView: false
            },
            externalResourcesTab: {
                canView: false
            },
            dataCatalogProjectsTab: {
                canView: false
            }
        };
        return privileges;
    }

    // return UI privileges based on project-user-role
    getRolePrivilege(roleId, privileges) {
        switch(roleId) {
            case 12: // Team Lead or Project Owner
                privileges.landingPage.canView = true;
                privileges.landingPage.canInteractTabs = true;
                privileges.landingPage.canSearchProjects = true;
                privileges.landingPage.canCreateProject = true;

                privileges.projectDetailsTab.canView = true;
                privileges.projectDetailsTab.canEdit = true;

                privileges.workbenchTab.canView = true;
                privileges.workbenchTab.canCreate = true;
                privileges.workbenchTab.canOperate = true;
                privileges.workbenchTab.canOperateOther = true;
                privileges.workbenchTab.canRefresh = true;

                privileges.projectMembersTab.canView = true;
                privileges.projectMembersTab.canOperate = true;

                privileges.modelsTab.canView = true;
                privileges.instructionsForUseTab.canView = true;
                privileges.externalResourcesTab.canView = true;
                privileges.dataCatalogProjectsTab.canView = true;
                break;

            case 15:  //Reader
                privileges.landingPage.canView = true;
                privileges.landingPage.canInteractTabs = true;
                privileges.landingPage.canSearchProjects = true;
                privileges.landingPage.canCreateProject = true;

                privileges.projectDetailsTab.canView = true;
                privileges.projectDetailsTab.canEdit = false;

                privileges.workbenchTab.canView = true;
                privileges.workbenchTab.canCreate = false;
                privileges.workbenchTab.canOperate = false;
                privileges.workbenchTab.canOperateOther = false;
                privileges.workbenchTab.canRefresh = true;

                privileges.projectMembersTab.canView = false;
                privileges.projectMembersTab.canOperate = false;

                privileges.modelsTab.canView = true;
                privileges.instructionsForUseTab.canView = true;
                privileges.externalResourcesTab.canView = true;
                privileges.dataCatalogProjectsTab.canView = true;
                break;

            case 14: //Writer
                privileges.landingPage.canView = true;
                privileges.landingPage.canInteractTabs = true;
                privileges.landingPage.canSearchProjects = true;
                privileges.landingPage.canCreateProject = true;

                privileges.projectDetailsTab.canView = true;
                privileges.projectDetailsTab.canEdit = false;

                privileges.workbenchTab.canView = true;
                privileges.workbenchTab.canCreate = true;
                privileges.workbenchTab.canOperate = true;
                privileges.workbenchTab.canOperateOther = false;
                privileges.workbenchTab.canRefresh = true;

                privileges.projectMembersTab.canView = false;
                privileges.projectMembersTab.canOperate = false;

                privileges.modelsTab.canView = true;
                privileges.instructionsForUseTab.canView = true;
                privileges.externalResourcesTab.canView = true;
                privileges.dataCatalogProjectsTab.canView = true;
                break;

            default: // User not added to any project. Just has access to the HDP application.
                privileges.landingPage.canView = true;
                privileges.landingPage.canInteractTabs = true;
                privileges.landingPage.canSearchProjects = true;
                privileges.landingPage.canCreateProject = true;

                privileges.projectDetailsTab.canView = true;
                privileges.projectDetailsTab.canEdit = false;

                privileges.workbenchTab.canView = false;
                privileges.workbenchTab.canCreate = false;
                privileges.workbenchTab.canOperate = false;
                privileges.workbenchTab.canOperateOther = false;
                privileges.workbenchTab.canRefresh = false;

                privileges.projectMembersTab.canView = false;
                privileges.projectMembersTab.canOperate = false;

                privileges.modelsTab.canView = false;
                privileges.instructionsForUseTab.canView = true;
                privileges.externalResourcesTab.canView = true;
                privileges.dataCatalogProjectsTab.canView = true;
                break;
        }
    }
}

export default ProjectRoleManager;
