import {Grid, Typography} from "@mui/material";
import {AlertBanner, Dropdown, InputField, LabelAndValue} from "@medtronic/pecc-react-component-library-js";
import datasetAccessRequestUseCaseDropdownOptions
    from "./page-content-components/form-options/DatasetAccessRequestUseCaseOptions";
import Box from "@mui/material/Box";
import {useContext} from "react";
import APIManager from "../mdt-lib/hdp-client-sdk/api-manager";

function DatasetAccessRequestForm({user, dataset, formValues, setFormValues, errorAlertMessage, org_name}) {
    const labelColor = "#0000008C";
    const labelFontSize = "1.2rem";

    return <>
        <Grid container spacing="100rem" sx={{margin: "2rem", width: "75rem"}}>
            <Grid item xs={12}>
                <Typography variant="h3">Request access</Typography>
            </Grid>
            <Grid item xs={12} sx={{margin: "2rem 0"}}>
                <Typography variant="subtitle">
                    {`Use this form to request access to the dataset "${dataset.name}".`}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <LabelAndValue label="Data Owner" labelColor={labelColor} labelFontSize={labelFontSize} value={dataset.metadata.data_owner_name} sx={{padding: "1.6rem 0rem"}}/>
            </Grid>
            <Grid item xs={3}>
                <LabelAndValue label="Username" labelColor={labelColor} labelFontSize={labelFontSize} value={user.id}/>
            </Grid>
            <Grid item xs={3}>
                <LabelAndValue label="Organizational Unit" labelColor={labelColor} labelFontSize={labelFontSize} value={org_name}/>
            </Grid>
            <Grid item xs={3}>
                <LabelAndValue label="Dataset Classification" labelColor={labelColor} labelFontSize={labelFontSize} value={dataset.data_classification}/>
            </Grid>
            <Grid item xs={6}>
                <InputField
                    label="Cost Center"
                    labelColor={labelColor}
                    onChange={newValue => setFormValues({...formValues, hdpar_cost_center: newValue})}
                    value={formValues.cost_center}
                    width="98%"
                    isRequired
                />
            </Grid>
            <Grid item xs={6}>
                <Dropdown
                    label="Use Cases"
                    labelColor={labelColor}
                    dropdownOptions={datasetAccessRequestUseCaseDropdownOptions}
                    onChange={newValue => setFormValues({...formValues, use_cases: newValue})}
                    value={formValues.use_cases}
                    isMultiselect
                    isRequired
                />
            </Grid>
            <Grid item xs={6}>
                <InputField
                    isRequired
                    onChange={newValue => setFormValues({...formValues, use_case_description: newValue})}
                    value={formValues.description}
                    label="Description (Why do you need access?)"
                    labelColor={labelColor}
                    width="98%"
                />
            </Grid>
            <Grid item xs={6}>
                <InputField
                    isRequired
                    onChange={newValue => setFormValues({...formValues, business_value: newValue})}
                    value={formValues.business_value}
                    label="Business Value"
                    labelColor={labelColor}
                    width="98%"
                />
            </Grid>
        </Grid>
        {!!errorAlertMessage && <Box padding="0 2rem 2rem 2rem" maxWidth="78rem">
            <AlertBanner
                bannerType="notice"
                alertType="critical"
                alertMessage={errorAlertMessage}
            />
        </Box>}
    </>;
}

export default DatasetAccessRequestForm;
