import BaseSelector from './base-selector.js';

export default class FieldBasedSelector extends BaseSelector {

  constructor(config, records) {
    super(config, records);
    this.fieldName = config.fieldName;
    this.fieldValue = config.fieldValue;
    this.records = [];
    for (let record of records) {
      if (record[this.fieldName] === this.fieldValue) {
        this.records.push(record);
      }
    }
  }
}
