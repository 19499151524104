class Logger {

  constructor(startColorArray, endColorArray, labelText) {
    this.labelColors = this.getLabelColorArray(startColorArray, endColorArray);
    this.DEBUG_LOGGING_LEVEL = 4;
    this.debugStyle = `color: white; background-color: rgb(${this.labelColors[0][0]},${this.labelColors[0][1]},${this.labelColors[0][2]})`;
    this.debugLabel = `${labelText} - Debug `;
    this.INFO_LOGGING_LEVEL = 3;
    this.infoStyle = `color: white; background-color: rgb(${this.labelColors[1][0]},${this.labelColors[1][1]},${this.labelColors[1][2]})`;
    this.infoLabel = `${labelText} - Info `;
    this.WARN_LOGGING_LEVEL = 2;
    this.warnStyle = `color: white; background-color: rgb(${this.labelColors[2][0]},${this.labelColors[2][1]},${this.labelColors[2][2]})`;
    this.warnLabel = `${labelText} - Warning `;
    this.ERROR_LOGGING_LEVEL = 1;
    this.errorStyle = `color: white; background-color: rgb(${this.labelColors[3][0]},${this.labelColors[3][1]},${this.labelColors[3][2]})`;
    this.errorLabel = `${labelText} - Error `;
    this.FATAL_LOGGING_LEVEL = 0;
    this.fatalStyle = `color: white; background-color: rgb(${this.labelColors[4][0]},${this.labelColors[4][1]},${this.labelColors[4][2]})`;
    this.fatalLabel = `${labelText} - Fatal `;
    this.TIME_MEASURE_CUMULATIVE_SECONDS = 'seconds';
    this.TIME_MEASURE_CUMULATIVE_MILLISECONDS = 'milliseconds';
    this.TIME_MEAUSRE_MILLISECONDS_SINCE_LAST_LOG = 'millis_lastlog';
    this.TIME_MEASURE_TIMESTAMP = 'timestamp';
    this.timeMeasure = this.TIME_MEASURE_CUMULATIVE_MILLISECONDS;
    const now = new Date().getTime();
    this.lastLogTime = now;
    this.loggerStarted = now;
    this.loggingLevel = this.DEBUG_LOGGING_LEVEL;
  }

  getLabelColorArray(startColorRGBArray, endColorRGBArray) {
    const labelColorArray = [];
    const redIncrement = Math.floor((startColorRGBArray[0] - endColorRGBArray[0])/4);
    const greenIncrement = Math.floor((startColorRGBArray[1] - endColorRGBArray[1])/4);
    const blueIncrement = Math.floor((startColorRGBArray[2] - endColorRGBArray[2])/4);
    for (let i=0; i<5; i++) {
      let labelColor = [
        startColorRGBArray[0] - (redIncrement * i),
        startColorRGBArray[1] - (greenIncrement * i),
        startColorRGBArray[2] - (blueIncrement * i)
      ];
      labelColorArray.push(labelColor);
    }
    return labelColorArray;
  }

  setTimeMeasure(timeMeasure) {
    this.timeMeasure = timeMeasure;
  }

  setLoggingLevel(level) {
    this.loggingLevel = level;
  }

  getTimeInfo() {
    const now = new Date();
    let timeInfo = '';
    if (this.timeMeasure == this.TIME_MEASURE_TIMESTAMP) {
      timeInfo = now.toLocaleString('en-us');
    } else if (this.timeMeasure == this.TIME_MEASURE_CUMULATIVE_SECONDS) {
      const seconds = (now.getTime() - this.loggerStarted)/1000;
      timeInfo = `${seconds}s Elapsed`;
    } else if (this.timeMeasure == this.TIME_MEASURE_CUMULATIVE_MILLISECONDS) {
      const milliseconds = (now.getTime() - this.loggerStarted);
      timeInfo = `${milliseconds}ms Elapsed`;
    } else if (this.timeMeasure == this.TIME_MEAUSRE_MILLISECONDS_SINCE_LAST_LOG) {
      const milliseconds = (now.getTime() - this.loggerStarted);
      timeInfo = `Time Marker ${milliseconds}ms`;
    }
    this.lastLogTime = now.getTime();
    return timeInfo;
  }

  getMessageSource() {
    var err = new Error();
    const stackTrace = err.stack.split("\n");
    return stackTrace[4];
  }

  getLoggingLine(message) {
    try {
      if (typeof message === 'string' || typeof message === 'number' || typeof message === 'boolean' ||
          typeof message === 'bigint' || typeof message === 'symbol') {
        return `[${this.getTimeInfo()}] ${message} ---> ${this.getMessageSource()}`;
      } else if (typeof message === 'object' && message !== null) {
        const objectStr = JSON.stringify( message, null, 2 );
        return `[${this.getTimeInfo()}]  ---> ${this.getMessageSource()}, object view:\n\n${objectStr}`;
      } else if (message === null) {
        return `[${this.getTimeInfo()}] **NULL** ---> ${this.getMessageSource()}`;
      } else {
        return `[${this.getTimeInfo()}] Message cannot be displayed, bad type ---> ${this.getMessageSource()}`;
      }
    } catch (exception) {
      console.error("Logging error:");
      console.error(exception);
    }

  }

  debug(message) {
    try {
      if (this.loggingLevel >= this.DEBUG_LOGGING_LEVEL) {
        console.log('%c ' + this.debugLabel, this.debugStyle, this.getLoggingLine(message));
      }
    } catch (exception) {
      console.error("Logging error:");
      console.error(exception);
    }
  }

  info(message) {
    try {
      if (this.loggingLevel >= this.INFO_LOGGING_LEVEL) {
        console.log('%c ' + this.infoLabel, this.infoStyle, this.getLoggingLine(message));
      }
    } catch (exception) {
      console.error("Logging error:");
      console.error(exception);
    }
  }

  warn(message) {
    try {
      if (this.loggingLevel >= this.WARN_LOGGING_LEVEL) {
        console.log('%c ' + this.warnLabel, this.warnStyle, this.getLoggingLine(message));
      }
    } catch (exception) {
      console.error("Logging error:");
      console.error(exception);
    }
  }

  error(message) {
    try {
      if (this.loggingLevel >= this.ERROR_LOGGING_LEVEL) {
        console.log('%c ' + this.errorLabel, this.errorStyle, this.getLoggingLine(message));
      }
    } catch (exception) {
      console.error("Logging error:");
      console.error(exception);
    }
  }

  fatal(message) {
    try {
      if (this.loggingLevel >= this.FATAL_LOGGING_LEVEL) {
        console.log('%c ' + this.fatalLabel, this.fatalStyle, this.getLoggingLine(message));
      }
    } catch (exception) {
      console.error("Logging error:");
      console.error(exception);
    }
  }
}

export default Logger;
