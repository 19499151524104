// ORM Managers
import OperationsManager from "./ops/operations-manager";
import PrivilegesManager from "./ops/privileges-manager";
import ConfigurationsManager from "./ops/configurations-manager";
import RulesManager from "./ops/rules-manager.js";
import DatasetManager from './orm/dataset-manager';
import ProjectManager from './orm/project-manager';
import AppUserManager from './orm/app-user-manager';
import ApplicationRoleManager from './orm/application-role-manager';
import ApplicationManager from "./orm/application-manager";
import DataViewManager from "./orm/data-view-manager";
import JobClusterManager from "./orm/job-cluster-manager";
import ArtifactManager from "./orm/artifact-manager";
import OperatingUnitManager from "./orm/operating-unit-manager";
import RoleManager from "./orm/role-manager";
import WorkbenchManager from "./orm/workbench-manager";
import WorkbenchOpManager from "./orm/workbench-op-manager";
import DatasetProjectRelationManager from "./orm/dataset-project-relation-manager";
import DatasetRoleManager from "./orm/dataset-role-manager";
import ProjectRoleManager from "./orm/project-role-manager";
import DataviewRoleManager from "./orm/dataview-role-manager";
import ServiceNowManager from "./orm/service-now-manager";
import ServiceNowEventManager from "./orm/service-now-event-manager";

// Entities
import DatasetProjectRelation from "./orm/dataset-project-relation";
import ProjectRole from "./orm/project-role";
import DataLoaderService from "./rules/data-loader.service";

// Container class for all entity managers
class APIManager {

  constructor(logger, config) {
    this.config = config;
    this.logger = logger;
    this.currentUserDecorated = false;

    this.operationsManager = null;
    this.privilegesManager = null;
    this.configurationsManager = null;
    this.rulesManager = null;

    this.applicationRoleManager = null;
    this.sessionManager = null;
    this.datasetManager = null;
    this.projectManager = null;
    this.appUserManager = null;
    this.applicationManager = null;
    this.dataViewManager = null;
    this.jobClusterManager = null;
    this.operatingUnitManager = null;
    this.roleManager = null;
    this.workbenchManager = null;
    this.workbenchOpManager = null;
    this.datasetProjectRelationManager = null;
    this.datasetRoleManager = null;
    this.projectRoleManager = null;
    this.dataviewRoleManager = null;
    this.serviceNowManager = null;
    this.serviceNowEventManager = null;

    this.getEntityData = () => {
      return {
        "ApplicationRole": this.applicationRoleManager.dataArray,
        "Dataset": this.datasetManager.dataArray,
        "Project": this.projectManager.dataArray,
        "AppUser": this.appUserManager.dataArray,
        "DataView": this.dataViewManager.dataArray,
        "Application": this.applicationManager.dataArray,
        "JobCluster": this.jobClusterManager.dataArray,
        "OperatingUnit": this.operatingUnitManager.dataArray,
        "Role": this.roleManager.dataArray,
        "Workbench": this.workbenchManager.dataArray,
        "DatasetProjectRelation": this.datasetProjectRelationManager.dataArray,
        "DatasetRole": this.datasetRoleManager.dataArray,
        "ProjectRole": this.projectRoleManager.dataArray,
        "DataviewRole": this.dataviewRoleManager.dataArray
      };

    }

    this.getOtherData = () => {
      return {};
    }

    this.pendingDataRequests = [];
    this.errorDataRequests = [];

    this.currentApplicationId = -1;
    this.currentUser = null;
    this.initializationSucceeded = null;

    this.fetchEntitiesSucceeded = (entityName) => {
      this.removeEntityName(entityName, this.pendingDataRequests);
      this.logger.debug("Pending data requests: ");
      this.logger.debug(this.pendingDataRequests);

      if (this.pendingDataRequests.length === 0) {
        let start = new Date();
        this.__decorateUsers();
        let secondsPassed = this.getSecondsPassed(start);
        this.logger.debug(`Decorate users took ${secondsPassed} seconds`);

        start = new Date();
        this.__decorateProjects();
        secondsPassed = this.getSecondsPassed(start);
        this.logger.debug(`Decorate projects took ${secondsPassed} seconds`);
        start = new Date();

        this.__decorateDatasets();
        secondsPassed = this.getSecondsPassed(start);
        this.logger.debug(`Decorate datasets took ${secondsPassed} seconds`);

        start = new Date();
        if (this.initializationSucceeded) {
          this.initializationSucceeded();
        }
      }
    }

    this.fetchEntitiesFailed = (entityName) => {
      this.removeEntityName(entityName, this.pendingDataRequests);
      this.errorDataRequests.push(entityName);
      if (this.pendingDataRequests.length === 0 && this.initializationSucceeded) {
        this.initializationSucceeded();
      }
    }
  }

  getSecondsPassed(startDate) {
    const startSeconds = startDate.getSeconds();
    const now = new Date();
    let nowSeconds = now.getSeconds();
    if (nowSeconds < startSeconds) {
      nowSeconds += 60;
    }
    return nowSeconds - startSeconds;
  }

  removeEntityName(entityName, entityNameArray) {
    const index = entityNameArray.indexOf(entityName);
    if (index > -1) {
      entityNameArray.splice(index, 1);
    }
  }

  init(sessionManager, currentApplicationId, environment) {
    this.sessionManager = sessionManager;

    this.privilegesManager = new PrivilegesManager(sessionManager, this.logger, environment);
    this.operationsManager = new OperationsManager(sessionManager, this.logger, environment);
    this.configurationsManager = new ConfigurationsManager(sessionManager, this.logger, environment);

    this.applicationRoleManager = new ApplicationRoleManager(sessionManager, this.logger, this.config, environment, this.getEntityData);
    this.datasetManager = new DatasetManager(sessionManager, this.logger, this.config, environment, this.getEntityData);
    this.projectManager = new ProjectManager(sessionManager, this.logger, this.config, environment, this.getEntityData);
    this.appUserManager = new AppUserManager(sessionManager, this.logger, this.config, environment, this.getEntityData);
    this.dataViewManager = new DataViewManager(sessionManager, this.logger, this.config, environment, this.getEntityData);
    this.applicationManager = new ApplicationManager(sessionManager, this.logger, this.config, environment, this.getEntityData);
    this.jobClusterManager = new JobClusterManager(sessionManager, this.logger, this.config, environment, this.getEntityData);
    this.artifactManager = new ArtifactManager(sessionManager, this.logger, this.config, environment, this.getEntityData);
    this.operatingUnitManager = new OperatingUnitManager(sessionManager, this.logger, this.config, environment, this.getEntityData);
    this.workbenchManager = new WorkbenchManager(sessionManager, this.logger, this.config, environment, this.getEntityData);
    this.workbenchOpManager = new WorkbenchOpManager(sessionManager, this.logger, this.config, environment, this.getEntityData);
    this.roleManager = new RoleManager(sessionManager, this.logger, this.config, environment, this.getEntityData);
    this.datasetProjectRelationManager = new DatasetProjectRelationManager(sessionManager, this.logger, this.config, environment, this.getEntityData);
    this.datasetRoleManager = new DatasetRoleManager(sessionManager, this.logger, this.config, environment, this.getEntityData);
    this.projectRoleManager = new ProjectRoleManager(sessionManager, this.logger, this.config, environment, this.getEntityData);
    this.dataviewRoleManager = new DataviewRoleManager(sessionManager, this.logger, this.config, environment, this.getEntityData);

    this.serviceNowManager = new ServiceNowManager(sessionManager, this.logger, this.config, environment);
    this.serviceNowEventManager = new ServiceNowEventManager(sessionManager, this.logger, this.config, environment);

    this.pendingDataRequests.push(this.applicationRoleManager.getEntityName());
    this.pendingDataRequests.push(this.datasetManager.getEntityName());
    this.pendingDataRequests.push(this.projectManager.getEntityName());
    this.pendingDataRequests.push(this.appUserManager.getEntityName());
    this.pendingDataRequests.push(this.dataViewManager.getEntityName());
    this.pendingDataRequests.push(this.applicationManager.getEntityName());
    this.pendingDataRequests.push(this.jobClusterManager.getEntityName());
    this.pendingDataRequests.push(this.artifactManager.getEntityName());
    this.pendingDataRequests.push(this.operatingUnitManager.getEntityName());
    this.pendingDataRequests.push(this.workbenchManager.getEntityName());
    this.pendingDataRequests.push(this.roleManager.getEntityName());
    this.pendingDataRequests.push(this.datasetProjectRelationManager.getEntityName());
    this.pendingDataRequests.push(this.datasetRoleManager.getEntityName());
    this.pendingDataRequests.push(this.projectRoleManager.getEntityName());
    this.pendingDataRequests.push(this.dataviewRoleManager.getEntityName());

    this.currentUser = null;
    this.currentApplicationId = 1;
  }

  async initializeData(userId, onSuccess) {
    this.initializationSucceeded = onSuccess;
    this.logger.info(`Retrieving Current User with id ${userId}...`);
    let userResult = null;
    try {
      userResult = await this.appUserManager.getUser(userId);
      this.currentUser = userResult;
      this.logger.info(`Successfully retrieved the current user's info`)
    } catch (userError) {
      this.logger.error("Error encountered during retrieval of currently logged in User.");
    }
  }

  async lazyLoad(callback) {
    this.logger.info("Retrieving Configurations...");
    this.configurationsManager.fetchWorkbenchConfigs();
    this.configurationsManager.fetchJobClusterConfigs();

    this.logger.info("Retrieving Datasets...");
    this.datasetManager.getAllDatasets(this.fetchEntitiesSucceeded, this.fetchEntitiesFailed);
    this.logger.info("Retrieving Projects...");
    this.projectManager.getAllProjects(this.fetchEntitiesSucceeded, this.fetchEntitiesFailed);
    this.logger.info("Retrieving Project Roles...");
    this.projectRoleManager.getAllProjectRoles(this.fetchEntitiesSucceeded, this.fetchEntitiesFailed);
    this.logger.info("Retrieving Dataset Roles...");
    this.datasetRoleManager.getAllDatasetRoles(this.fetchEntitiesSucceeded, this.fetchEntitiesFailed);
    this.logger.info("Application Roles...");
    this.applicationRoleManager.getAllApplicationRoles(this.fetchEntitiesSucceeded, this.fetchEntitiesFailed);
    this.logger.info("Retrieving Dataset <-> Project Relations...");
    this.datasetProjectRelationManager.getAllDatasetProjectRelations(this.fetchEntitiesSucceeded, this.fetchEntitiesFailed);
    this.logger.info("Retrieving App Users");
    this.appUserManager.getAllUsers(this.fetchEntitiesSucceeded, this.fetchEntitiesFailed);
    this.logger.info("Retrieving Data Views...");
    this.dataViewManager.getAllDataViews(this.fetchEntitiesSucceeded, this.fetchEntitiesFailed);
    this.logger.info("Retrieving Applications...");
    this.applicationManager.getAllApplications(this.fetchEntitiesSucceeded, this.fetchEntitiesFailed);
    this.logger.info("Retrieving Job Clusters...");
    this.jobClusterManager.getAllJobClusters(this.fetchEntitiesSucceeded, this.fetchEntitiesFailed);
    this.logger.info("Retrieving Artifacts...");
    this.artifactManager.getAllArtifacts(this.fetchEntitiesSucceeded, this.fetchEntitiesFailed);
    this.logger.info("Retrieving Operating Units...");
    this.operatingUnitManager.getAllOperatingUnits(this.fetchEntitiesSucceeded, this.fetchEntitiesFailed);
    this.logger.info("Retrieving Roles...");
    this.roleManager.getAllRoles(this.fetchEntitiesSucceeded, this.fetchEntitiesFailed);
    this.logger.info("Retrieving Workbenches...");
    this.workbenchManager.getAllWorkbenches(this.fetchEntitiesSucceeded, this.fetchEntitiesFailed);
    this.logger.info("Retrieving Dataset <-> Project Relations...");
    this.datasetProjectRelationManager.getAllDatasetProjectRelations(this.fetchEntitiesSucceeded, this.fetchEntitiesFailed);
    this.logger.info("Retrieving Dataset Roles...");
    this.datasetRoleManager.getAllDatasetRoles(this.fetchEntitiesSucceeded, this.fetchEntitiesFailed);
    this.logger.info("Retrieving Project Roles...");
    this.projectRoleManager.getAllProjectRoles(this.fetchEntitiesSucceeded, this.fetchEntitiesFailed);
    this.logger.info("Retrieving Data View Roles...");
    this.dataviewRoleManager.getAllDataviewRoles(this.fetchEntitiesSucceeded, this.fetchEntitiesFailed);
    return true;
  }

  getAllUsers() {
    return this.appUserManager.dataArray;
  }

  getCurrentUser() {
    if (!this.currentUserDecorated) {
      this.__decorateUser(this.currentUser);
      this.privilegesManager.setPrivileges(this.currentUser, 1, this.applicationRoleManager.dataArray);
      this.currentUserDecorated = true;
    }
    return this.currentUser;
  }

  // Decorates all users with
  // - Application roles
  // - Project roles
  // - Projects
  // - Dataset roles
  // - Datasets
  // - Roles associated to the user

  __decorateUsers() {
    for (let appUser of this.appUserManager.dataArray) {
      try {
        this.__decorateUser(appUser);
      } catch (exception) {
        this.logger.error(`Error occurred during user attribute decoration ${exception.message}`);
      }
    }
  }

  __decorateUser(appUser) {
    if (this.applicationRoleManager.dataCache && this.projectManager.dataCache && this.datasetManager.dataCache) {
      const currentApplicationId = 1;
      appUser.decorate(
          currentApplicationId,
          this.applicationRoleManager.dataArray,
          this.projectRoleManager.dataArray,
          this.projectManager.dataCache,
          this.datasetRoleManager.dataArray,
          this.datasetManager.dataCache,
          this.roleManager.dataCache);
    }
  }

  getProjects() {
    return this.projectManager.dataArray
  }

  // Decorates all projects with:
  // 1. Associated data sets
  // 2. Associated users
  // 3. Associated workbenches

  __decorateProjects() {
    if (!this.projectManager.dataCache) {
      return;
    }

    // Build the the project -> datatset relational index
    const projectToDatasetIndex = {};
    for (let datasetProjectRelation of this.datasetProjectRelationManager.dataArray) {
      const dataset = this.datasetManager.dataCache[datasetProjectRelation.dataset_id];
      if (!projectToDatasetIndex[datasetProjectRelation.project_id]) {
        projectToDatasetIndex[datasetProjectRelation.project_id] = [];
      }
      if (dataset) {
        projectToDatasetIndex[datasetProjectRelation.project_id].push(dataset);
      } else {
        this.logger.warn(`Dataset not found while building project dataset index: ${datasetProjectRelation.dataset_id}`);
      }
    }

    // Build the project -> Project-User-Role relational index
    const projectToUserIndex = {};
    for (let projectRole of this.projectRoleManager.dataArray) {
      const role = this.roleManager.dataCache[projectRole.role_id];
      const user = this.appUserManager.dataCache[projectRole.user_id];
      if (!projectToUserIndex[projectRole.project_id]) {
        projectToUserIndex[projectRole.project_id] = [];
      }
      if (!role) {
        this.logger.warn(`Role not found while building project user/role index: ${projectRole.role_id}`);
      }
      if (!user) {
        this.logger.warn(`User not found while building project user/role index: ${projectRole.user_id}`);
      }
      if (role && user) {
        projectToUserIndex[projectRole.project_id].push({role: role, user: user});
      }
    }

    // Build the project - Workbench index
    const projectToWorkbenchIndex = {};
    for (let workbench of this.workbenchManager.dataArray) {
      if (!projectToWorkbenchIndex[workbench.project_id]) {
        projectToWorkbenchIndex[workbench.project_id] = [];
      }
      projectToWorkbenchIndex[workbench.project_id].push(workbench);
    }

    // Now attach all related entities to each project
    for (let project of this.projectManager.dataArray) {
      projectToUserIndex[project.id] ? project.users = projectToUserIndex[project.id] : project.users = [];
      projectToDatasetIndex[project.id] ? project.datasets = projectToDatasetIndex[project.id] : project.datasets = [];
      projectToWorkbenchIndex[project.id] ? project.workbenches = projectToWorkbenchIndex[project.id] : project.workbenches = [];
    }
    return this.projectManager.dataArray
  }

  getDatasets() {
    return this.datasetManager.dataArray
  }

  // Decorates datasets with:
  // - Associated projects
  // - Associated dataset user roles

  __decorateDatasets() {
    if (!this.datasetManager.dataCache) {
      return;
    }

    // Build the the Dataset - Projects relational index
    const datasetToProjectIndex = {};
    for (let datasetProjectRelation of this.datasetProjectRelationManager.dataArray) {
      const project = this.datasetManager.dataCache[datasetProjectRelation.project_id];
      if (!datasetToProjectIndex[datasetProjectRelation.dataset_id]) {
        datasetToProjectIndex[datasetProjectRelation.dataset_id] = [];
      }
      datasetToProjectIndex[datasetProjectRelation.dataset_id].push(project);
    }

    // Build the Dataset - Dataset User Role relational index
    const datasetToUserIndex = {};
    for (let datasetRole of this.datasetRoleManager.dataArray) {
      const role = this.roleManager.dataCache[datasetRole.role_id];
      const user = this.appUserManager.dataCache[datasetRole.user_id];
      if (!datasetToUserIndex[datasetRole.dataset_id]) {
        datasetToUserIndex[datasetRole.dataset_id] = [];
      }
      datasetToUserIndex[datasetRole.dataset_id].push({role: role, user: user});
    }

    // Now attach all related entities to each dataset
    for (let dataset of this.datasetManager.dataArray) {
      datasetToUserIndex[dataset.id] ? dataset.users = datasetToUserIndex[dataset.id] : dataset.users = [];
      datasetToProjectIndex[dataset.id] ? dataset.projects = datasetToProjectIndex[dataset.id] : dataset.projects = [];
    }
    return this.datasetManager.dataArray
  }

  // Decorates dataviews with:
  // 1. Associated users:roles
  // 2. Associated datasets
  getDataViews() {
  }

  getUserIdsInProject(projectId) {
    const projectRoles = [];
    for (let projectRole of this.projectRoleManager.dataArray) {
      if (projectRole.project_id === projectId) {
        projectRoles.push(projectRole.user_id);
      }
    }
    return projectRoles;
  }

  getDatasetIdsInProject(projectId) {
    const relations = [];
    for (let relation of this.datasetProjectRelationManager.dataArray) {
      if (relation.project_id === projectId) {
        relations.push(relation.dataset_id);
      }
    }
    return relations;
  }

  async updateProjectDatasets(updated_dataset_ids, project_id) {
    const newDatasetProjectRelations = [];
    const eliminatedDatasetProjectRelations = [];
    const currentRelationsByProjectId =
        this.buildSelectiveIndex(this.datasetProjectRelationManager.dataArray, 'dataset_id', 'project_id', project_id);

    this.logger.debug("*** Current Datasets ***");
    this.logger.debug(currentRelationsByProjectId);
    this.logger.debug("*** Updated Datasets ***");
    this.logger.debug(updated_dataset_ids);

    // Add new dataset ids that don't exist in the current set
    for (let dataset_id of updated_dataset_ids) {
      if (!currentRelationsByProjectId[dataset_id]) {
        let newDatasetProjectRel = new DatasetProjectRelation();
        newDatasetProjectRel.dataset_id = dataset_id;
        newDatasetProjectRel.project_id = project_id;
        delete newDatasetProjectRel.id;
        newDatasetProjectRelations.push(newDatasetProjectRel);
      } else {
        // If the dataset_id already existed, delete it from the set
        delete currentRelationsByProjectId[dataset_id];
      }
    }

    // The remaining dataset_id indices left in currentProjectRolesByUserId are not in the updated_dataset_ids
    // Time to delete them
    const deleteKeys = Object.keys(currentRelationsByProjectId);
    for (let dataset_id of deleteKeys) {
      let relationToBeDeleted = currentRelationsByProjectId[dataset_id];
      eliminatedDatasetProjectRelations.push(relationToBeDeleted);
    }
    this.logger.debug("*** Removed Datasets ***");
    this.logger.debug(eliminatedDatasetProjectRelations);

    const newKeys = Object.keys(newDatasetProjectRelations);
    this.logger.debug(`Creating new dataset <-> project relations`);
    for (let index of newKeys) {
      let datasetRelationToBeAdded = newDatasetProjectRelations[index];
      let addingResult = null;
      try {
        addingResult = await this.datasetProjectRelationManager.createDatasetProjectRelation(datasetRelationToBeAdded);
        this.datasetProjectRelationManager.dataCache[addingResult] = datasetRelationToBeAdded;
      } catch (addingError) {
        this.logger.error(`Error in adding dataset <-> project relation: project-${datasetRelationToBeAdded.project_id} dataset-${datasetRelationToBeAdded.dataset_id}`);
      }
    }

    this.logger.debug(`Removing defunct dataset <-> project relations`);
    for (let relationToBeDeleted of eliminatedDatasetProjectRelations) {
      let deleteResult = null;
      try {
        deleteResult = await this.datasetProjectRelationManager.deleteDatasetProjectRelation(relationToBeDeleted.id);
        delete this.datasetProjectRelationManager.dataCache[relationToBeDeleted.id];
      } catch (deleteError) {
        this.logger.error(`Error in deleting dataset <-> project relation: project-${relationToBeDeleted.project_id} dataset-${relationToBeDeleted.dataset_id}`);
      }
    }
  }

  // Update the Project-User-Role relationships for the Project
  // Input :
  //   updated_member_ids : ["developer_1", "data_scientist_1", ...]
  //   project_id : "100"
  //   member_roles_obj : { "developer_1": [12, 13],     e.g. "developer_1" is given "R" and "W" permissions
  //                        "data_scientist_1": [12],         "data_scientist_1 is given "R" permission
  //                        "user_1": [12, 13, 14] }          "user_1" is given "R", "W" and "project owner" permissions
  async updateProjectUsersWithRoles(updated_member_ids, project_id, member_roles_obj) {

    // Fetch all the existing roles for a project-id
    const newProjectRoles = [];
    const eliminatedProjectRoles = [];
    const currentProjectRolesByUserId =
        this.buildSelectiveIndex(this.projectRoleManager.dataArray, 'user_id', 'project_id', project_id);

    this.logger.debug("*** Current Roles ***");
    this.logger.debug(currentProjectRolesByUserId);
    this.logger.debug("*** Updated Ids ***");
    this.logger.debug(updated_member_ids);

    // Add new User Ids that don't exist in the current set
    for (let user_id of updated_member_ids) {
      if (!currentProjectRolesByUserId[user_id] || !member_roles_obj[user_id].includes(currentProjectRolesByUserId[user_id].role_id)) {

        //Get all the role-ids for the current user-id from member_roles_obj and them.
        let role_ids = member_roles_obj[user_id];

        for (let role_id of role_ids) {
          let newProjectRole = new ProjectRole();
          newProjectRole.user_id = user_id;
          newProjectRole.role_id = role_id;
          newProjectRole.project_id = project_id;
          newProjectRoles.push(newProjectRole);
        }
      } else {
        // If the user_id already existed, delete it from the set
        delete currentProjectRolesByUserId[user_id];
      }
    }

    // The remaining userId indices left in currentProjectRolesByUserId are not in the updated_member_ids
    // Time to delete them
    const deleteKeys = Object.keys(currentProjectRolesByUserId);
    for (let user_id of deleteKeys) {
      let roleToBeDeleted = currentProjectRolesByUserId[user_id];
      eliminatedProjectRoles.push(roleToBeDeleted);
    }

    // Update the 'ProjectRoleManager' data cache for the new roles that are added
    this.logger.debug("*** Added Roles ***");
    this.logger.debug(newProjectRoles);
    for (let roleToBeAdded of newProjectRoles) {
      let addingResult = null;
      try {
        addingResult = await this.projectRoleManager.createProjectRole(roleToBeAdded);
      } catch (addingError) {
        this.logger.error(`Error in adding project role: project-${roleToBeAdded.project_id} user-${roleToBeAdded.user_id} role-${roleToBeAdded.role_id}`);
      }
    }

    // Update the 'ProjectRoleManager' data cache for the removed roles
    this.logger.debug("*** Removed Roles ***");
    this.logger.debug(eliminatedProjectRoles);
    for (let roleToBeDeleted of eliminatedProjectRoles) {
      let deleteResult = null;
      try {
        deleteResult = await this.projectRoleManager.deleteProjectRole(roleToBeDeleted.id);
        delete this.projectRoleManager.dataCache[roleToBeDeleted.id];
      } catch (deleteError) {
        this.logger.error(`Error in deleting project role: project-${roleToBeDeleted.project_id} user-${roleToBeDeleted.user_id} role-${roleToBeDeleted.role_id}`);
      }
    }
  }

  async updateProjectUsers(updated_member_ids, project_id) {
    const newProjectRoles = [];
    const eliminatedProjectRoles = [];
    const currentProjectRolesByUserId =
        this.buildSelectiveIndex(this.projectRoleManager.dataArray, 'user_id', 'project_id', project_id);

    this.logger.debug("*** Current Roles ***");
    this.logger.debug(currentProjectRolesByUserId);
    this.logger.debug("*** Updated Ids ***");
    this.logger.debug(updated_member_ids);

    // Add new User Ids that don't exist in the current set
    for (let user_id of updated_member_ids) {
      if (!currentProjectRolesByUserId[user_id]) {
        let newProjectRole = new ProjectRole();
        newProjectRole.user_id = user_id;
        newProjectRole.role_id = 15;
        newProjectRole.project_id = project_id;
        newProjectRoles.push(newProjectRole);
      } else {
        // If the user_id already existed, delete it from the set
        delete currentProjectRolesByUserId[user_id];
      }
    }

    // The remaining userId indices left in currentProjectRolesByUserId are not in the updated_member_ids
    // Time to delete them
    const deleteKeys = Object.keys(currentProjectRolesByUserId);
    for (let user_id of deleteKeys) {
      let roleToBeDeleted = currentProjectRolesByUserId[user_id];
      eliminatedProjectRoles.push(roleToBeDeleted);
    }

    this.logger.debug("*** Removed Roles ***");
    this.logger.debug(eliminatedProjectRoles);
    for (let roleToBeAdded of newProjectRoles) {
      let addingResult = null;
      try {
        addingResult = await this.projectRoleManager.createProjectRole(roleToBeAdded);
        this.projectRoleManager.dataCache[addingResult] = roleToBeAdded;
      } catch (addingError) {
        this.logger.error(`Error in adding project role: project-${roleToBeAdded.project_id} user-${roleToBeAdded.user_id} role-${roleToBeAdded.role_id}`);
      }
    }

    for (let roleToBeDeleted of eliminatedProjectRoles) {
      let deleteResult = null;
      try {
        deleteResult = this.projectRoleManager.deleteProjectRole(roleToBeDeleted.id);
        delete this.projectRoleManager.dataCache[roleToBeDeleted.id];
      } catch (deleteError) {
        this.logger.error(`Error in deleting project role: project-${roleToBeDeleted.project_id} user-${roleToBeDeleted.user_id} role-${roleToBeDeleted.role_id}`);
      }
    }
  }

  async getJobClusterInstanceStatuses(jobCluster) {
    let clusterName = jobCluster.name;
    let statuses = {};
    try {
      statuses = await this.jobClusterManager.getJobClusterInstanceStatuses(clusterName);
      if (!statuses) {
        this.logger.warn('Null updates found for workbenches.');
      }
    } catch (statusError) {
      this.logger.error('Error in getting updated status for workbenches.');
      return;
    }
    let running = 0;
    let notRunning = 0;
    const instanceIds = Object.keys(statuses);
    for (let instanceId of instanceIds) {
      let status = statuses[instanceId];
      this.logger.debug(`Job Cluster instance status (${instanceId}): ${status}`);
      if (status === "running") {
        running++;
      } else {
        notRunning++;
      }
    }
    let statusStr = `${running}/${notRunning + running} Running`;
    jobCluster.status = statusStr;
    this.logger.debug(`Job Cluster Instance Status: ${statusStr}`);
    return(statusStr);
  }

  async getUpdatedWorkbenchStatuses(project, callback) {
    const workbenchResults = {};
    const workbenchIds = [];
    const updatedWorkbenches = [];
    for (let i=0; i < project.workbenches.length; i++) {
      let workbench = project.workbenches[i];
      let instanceId = workbench.metadata.InstanceId;
      workbenchResults[instanceId] = workbench;
      if (workbench.status != "terminated-deleted" && workbench.status != "terminated") {
        workbenchIds.push(instanceId);
      }
    }

    let statuses = {};
    try {
      statuses  = await this.workbenchManager.getWorkbenchStatuses(workbenchIds);
      if (!statuses) {
        this.logger.warn('Null updates found for workbenches.');
      }
    } catch (statusError) {
      this.logger.error('Error in getting updated status for workbenches.');
    }

    let workbenchStatuses = {};
    let instance_ids = statuses ? Object.keys(statuses) : [];
    for (let instance_id of instance_ids) {
      let workbench = workbenchResults[instance_id];
      if (workbench) {
        let updatedStatus = statuses[instance_id];
        workbenchStatuses[workbench.id] = {
          "status": updatedStatus,
          "instanceId": instance_id
        }
        workbench.status = updatedStatus;
        let directReferenceWorkbench = this.workbenchManager.dataCache[workbench.id];
        directReferenceWorkbench.status = updatedStatus;
      }
    }
    callback(workbenchStatuses);
  }

  getWorkbenchJobCluster(workbenchId) {
    for (let jobCluster of this.jobClusterManager.dataArray) {
      if (jobCluster.workbench_id === workbenchId) {
        return jobCluster;
      }
    }
  }

  buildIndex(objectArray, attributeName) {
    const index = {};
    for (let object of objectArray) {
      index[object[attributeName]] = object;
    }
    return index;
  }

  buildSelectiveIndex(objectArray, indexedAttributeName, selectAttributeName, selectValue) {
    const index = {};
    for (let object of objectArray) {
      if (object[selectAttributeName] === selectValue) {
        index[object[indexedAttributeName]] = object;
      }
    }
    return index;
  }

  reportClientError(errorMessage) {

  }


}

export default APIManager;
