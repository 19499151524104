export default class BaseEvaluator {

    constructor(config) {
      this.resultDescription = config.resultDescription;
      this.treeDepth = 0;
      this.name = 'Base Rule Component';
      this.record = null;
    }

    getSummary() {
      if (this.resultDescription) {
        for (const attributeName of Object.keys(this)) {
          this.resultDescription = this.resultDescription.replace(`[${attributeName}]`, this[attributeName]);
        }
        if (this.record) {
          for (const attributeName of Object.keys(this.record)) {
            this.resultDescription = this.resultDescription.replace(`[${attributeName}]`, this.record[attributeName]);
          }
        } else {
          console.log('No record present to create summary');
        }
        return this.resultDescription;

      } else {
        return this._getSummary();
      }
    }

    _getSummary() {
      throw new Error('#_getSummary must be implemented in child class');
    }

    evaluate(record, finalRecord) {
      this.record = record;
      return this._evaluate(record, finalRecord);
    }

    _evaluate(record, finalRecord) {
      throw new Error('#_evaluate must be implemented in child class');
    }

    _perturb(record) {
      throw new Error('#_perturb must be implemented in child class');
    }

    perturb(record, chance) {
      const dieRoll = Math.random();
      if (dieRoll < chance && !record.perturbed) {
        return this._perturb(record);
      }
    }

    perturbValue(record, fieldName, increase) {
      if (!record.hasOwnProperty(fieldName)) {
        console.warn(`Record does not have the appropriate field for perturbation: ${fieldName}`);
        console.warn(record);
        return;
      }
      if (typeof record[fieldName] === "boolean"){
        // variable is a boolean
      } else if (!isNaN(record[fieldName])) {
        if (increase) {
          record[fieldName] = record[fieldName] + (Math.random() * record[fieldName]);
        } else {
          record[fieldName] = record[fieldName] - (Math.random() * record[fieldName]);
        }
      } else if (Date.parse(record[fieldName])) {
        let time = Date.parse(record[fieldName]);
        if (increase) {
          time = time + (1000 * 3600 * 48);
        } else {
          time = time - (1000 * 3600 * 48);
        }
        record[fieldName] = new Date(time).toString();
      } else if (record[fieldName].toLowercase) {
        if (record[fieldName].toLowercase() === 'false') {
          record[fieldName] = 'true';
        } else if (record[fieldName].toLowerCase() === 'true') {
          record[fieldName] = 'false';
        } else {
          record[fieldName] = this.getStr(48);
        }
      } else {
        console.warn(`Record does not have a recognized data type for field: ${fieldName}`);
        console.warn(record);
      }
    }

    getStr(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }

}
