import BaseEvaluator from '../base-evaluator.js';

export default class NumberComparison extends BaseEvaluator {

  constructor(config) {
    super(config);
    this.name = 'Number Comparison';
    this.targetField = config.targetField;
    this.comparator = config.comparator;
    this.value = config.value;
    this.units = config.units;
  }

  addErrorFields(fieldArray) {
    if (!fieldArray) {
      fieldArray = [];
    }
    if (!fieldArray.includes(this.targetField)) {
      fieldArray.push(this.targetField);
    }
    return fieldArray;
  }

  getTriggerMessage() {
    if (this.comparator === "=") {
      return `${this.targetField} is equal to ${this.value}`;
    } else if (this.comparator === "!=") {
      return `${this.targetField} is unequal to ${this.value}`;
    } else if (this.comparator === ">") {
      return `${this.targetField} is greater than ${this.value}`;
    } else if (this.comparator === "<") {
      return `${this.targetField} is less than ${this.value}`;
    } else if (this.comparator === ">=") {
      return `${this.targetField} is greater than or equal to ${this.value}`;
    } else if (this.comparator === "<=") {
      return `${this.targetField} is less than or equal to ${this.value}`;
    }
  }

  _getSummary() {
    return `The value found in '${this.targetField}' must be ${this.comparator} than ${this.value}`;
  }

  _evaluate(record, finalRecord) {
    if (!this.targetField || !record[this.targetField.trim()]) {
      return {result: false, missingData: true, description: 'Necessary fields not present', fields: this.addErrorFields(null)};
    }
    const recordValue = new Number(record[this.targetField.trim()]);
    switch (this.comparator) {
      case ">": {
        if (recordValue > this.value){
          record.errorDetail.push(this.getTriggerMessage());
          this.addErrorFields(record.errorFields);
          return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
        }
        break;
      }
      case "<": {
        if (recordValue < this.value){
          record.errorDetail.push(this.getTriggerMessage());
          this.addErrorFields(record.errorFields);
          return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
        }
        break;
      }
      case ">=": {
        if (recordValue >= this.value){
          record.errorDetail.push(this.getTriggerMessage());
          this.addErrorFields(record.errorFields);
          return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
        }
        break;
      }
      case "<=": {
        if (recordValue <= this.value){
          record.errorDetail.push(this.getTriggerMessage());
          this.addErrorFields(record.errorFields);
          return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
        }
        break;
      }
      case "=": {
        if (recordValue == this.value){
          record.errorDetail.push(this.getTriggerMessage());
          this.addErrorFields(record.errorFields);
          return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
        }
        break;
      }
      case "!=": {
        if (recordValue != this.value){
          record.errorDetail.push(this.getTriggerMessage());
          this.addErrorFields(record.errorFields);
          return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
        }
        break;
      }
    }
    return {result: false, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
  }

  _perturb(record) {
    if (record[this.targetField] && !isNaN(record[this.targetField])) {
      if (this.comparator === "=") {
        this.perturbValue(record, this.targetField, true);
      } else if (this.comparator === "!=") {
        record[this.targetField] = this.value;
      } else if (this.comparator === ">") {
        record[this.targetField] = this.value;
      } else if (this.comparator === "<") {
        record[this.targetField] = this.value;
      } else if (this.comparator === ">=") {
        this.perturbValue(record, this.targetField, true);
      } else if (this.comparator === "<=") {
        this.perturbValue(record, this.targetField, false);
      }
      record.perturbed = true;
      const newRecord = JSON.parse(JSON.stringify(record));
      newRecord.perturbed_field = this.targetField;
      return newRecord;
    }
  }
}
