import moment from "moment/moment";

const DATE_FORMAT = "MM/DD/YYYY";
const datasetsFields = [{
	"id": "name",
	"label": "Name",
	"key": "name"
},{
	"id": "description",
	"label": "Description",
	"key": "short_description",
},{
	"id": "status",
	"label": "Status",
	"key": "status",
},{
	"id": "data_classification",
	"label": "Classification",
	"key": "data_classification",
},{
	"id": "size",
	"label": "Size",
	"key": "size",
	"postprocess_func": value => {
		if (typeof value === "object" && "value" in value && "unit" in value) {
			return `${value.value} ${value.unit}`;
		} else {
			return value;
		}
	}
},{
	"id": "source",
	"label": "Source",
	"key": "source",
},{
	"id": "steward",
	"label": "Data Steward",
	"key": "metadata.data_owner_name",
},{
	"id": "created",
	"label": "Date Added",
	"key": "created",
	"postprocess_func": value => value ? moment.utc(value).local().format(DATE_FORMAT) : value,
},{
	"id": "updated",
	"label": "Last Updated",
	"key": "updated",
	"postprocess_func": value => value ? moment.utc(value).local().format(DATE_FORMAT) : value,
},{
	"id": "deidentified",
	"label": "De-Identified",
	"key": "metadata.de_identified",
	"postprocess_func": value => value !== "" ? value ? "Yes" : "No" : "",
},{
    "id": "controls",
    "label": "Controls",
    "key": "id"
}]

export default datasetsFields;
