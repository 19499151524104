import ExplicitRule from "./explicit-rule.rule.js";

export default class NewProjectDatasetRule extends ExplicitRule {

    constructor(pullData, logger) {
        super(pullData, logger);
        this.name = "New Project Dataset Rule (Existing Project Roster)";
        this.description = "New project roster members must have rights " +
            "to datasets associated with the project before being added";
    }

    __executeRule(path, method, payload) {
        const incomingDatasetId = payload["dataset_id"];
        const datasetRoles = this.entityData["DatasetRole"];
        const acceptableRoleIds = [12, 13, 14, 15];
        const requiredUserIds = this.getProjectUserIds(payload["project_id"]);
        if (!incomingDatasetId) {
            return {success: true, message: this.description, severity: "none"}
        }
        for (let datasetRole of datasetRoles) {
            try {
                let userIdIndex = requiredUserIds.indexOf(datasetRole.user_id);
                if (datasetRole.dataset_id === incomingDatasetId && userIdIndex >= 0 &&
                    acceptableRoleIds.includes(datasetRole.role_id)) {
                    requiredUserIds.splice(userIdIndex, 1);
                }
            } catch (exception) {
                console.log(exception);
            }
        }
        if (requiredUserIds.length > 0) {
            this.offendingData = requiredUserIds;
            return {success: false, message: this.description, severity: "failure", details:this.getViolationDetails()};
        }
        return {success: true, message: this.description, severity: "none", details: null};
    }

    getViolationDetails() {
        if (this.offendingData.length > 0) {
            let appUserNames = "";
            for (let userId of this.offendingData) {
                const appUser  = this.getUser(userId);
                appUserNames = `${appUserNames}, ${userId} (${appUser.first_name} ${appUser.last_name}))`;
            }
            appUserNames = appUserNames.substring(2);
            return `The following users do not have privileges to use the new dataset: ${appUserNames}. `;
        }
        return "No further details available";
    }
}