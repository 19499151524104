import to from 'await-to-js';
import CognitoAuthManager from "./cognito-auth-manager";
import Cookies from 'js-cookie';
import Environment from "./environment";

class SessionManager {

  constructor(configuration) {
    this.environment = new Environment(configuration);
    this.entryParams = this.getEntryParams();
    this.authManager = null;
    this.cookieName = configuration.cookieName;
  }

  loadCookieParams(cookieStr) {
      if (!cookieStr || cookieStr === "undefined") {
          return null;
      }
      const cookieData = JSON.parse(cookieStr);

      if (cookieData && cookieData.value) {
          console.log("Existing cookie value:");
          console.log(cookieData.value);
      } else {
          console.log("No cookie found.")
      }
      return cookieData.value;
  }

  getEntryParams() {
      let entryParams = {};
      const urlEnd = window.location.search;
      if (!urlEnd || urlEnd.length === 0) {
        console.log("No entry params found");
        return entryParams;
      }
      const queryString = urlEnd.substring(1);
      const params = queryString.split("&");
      for (let entry of params) {
          let pair = entry.split('=');
          entryParams[pair[0]] = pair[1];
      }
      console.log("Entry params");
      console.log(entryParams);
      return entryParams;
  }

  async getAuthHeaders() {
    console.log("Getting auth headers from session manager.")
    const [headersError, headersResult] = await to(this.authManager.getAuthHeaders());
    if (headersError) {
      console.log("Error encountered at session manager in getting headers");
      throw headersError;
    }
    // Reset the cookies' expiration because of activity
    this.refreshCookies();
    return headersResult;
  }

  refreshCookies() {
    const expiration = new Date(new Date().getTime() + this.environment.settings["cookieExpiresInMilliseconds"]);
    Cookies.set(this.cookieName + "-access", JSON.stringify({value: this.cookieData.access_token}), { expires: expiration, path:"/" });
    Cookies.set(this.cookieName + "-id", JSON.stringify({value: this.cookieData.id_token}), { expires: expiration, path:"/" });
    Cookies.set(this.cookieName + "-refresh", JSON.stringify({value: this.cookieData.refresh_token}), { expires: expiration, path:"/" });
    Cookies.set(this.cookieName + "-expires", JSON.stringify({value: this.cookieData.expiresAt}), { expires: expiration, path:"/" });
  }

  hasAllTokens() {
    if (this.cookieData && this.cookieData.id_token &&
      this.cookieData.access_token && this.cookieData.refresh_token) {
        return true;
      }
  }

  async init(accessCookieData, idCookieData, refreshCookieData, expiresCookieData) {
    console.log("--- Auth initializing Session Manager");
    this.cookieData = null;
    if (accessCookieData && idCookieData && refreshCookieData && expiresCookieData) {
      let accessToken = this.loadCookieParams(accessCookieData);
      let idToken = this.loadCookieParams(idCookieData);
      let refreshToken = this.loadCookieParams(refreshCookieData);
      let expiresAt = this.loadCookieParams(expiresCookieData);
      this.cookieData = {
        id_token: idToken,
        access_token: accessToken,
        refresh_token: refreshToken,
        expiresAt: expiresAt
      }
    }

    this.authManager = new CognitoAuthManager(this.environment);

    const [initError, initResult] = await to(this.authManager.init(this.cookieData, this.entryParams));
    if (initError) {
      console.log("Initialization error in session manager while creating auth manager.");
      throw initError;
    }
    this.cookieData = initResult;
    this.refreshCookies();
    this.expiresAt = initResult.expiresAt;
    return initResult;
  }

  logout() {

  }
}

export default SessionManager;
