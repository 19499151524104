import {Typography, Stack, Box} from "@mui/material";
import {CustomButton} from "@medtronic/pecc-react-component-library-js";
import {ShareKnowledge} from '@carbon/icons-react';

function FeedbackButton() {
    const feedbackLink = "https://forms.office.com/Pages/ResponsePage.aspx?id=2zk619puXUmAAHV59W1ot9Uyw3e6F51Hs_isvirCzIJUOVJDVUlUV0xHSTJQSzQzU1BZMTNIQjVINyQlQCN0PWcu";

    return <Stack spacing={2} direction="row" useFlexGap flexWrap="wrap" justifyContent="center" alignItems="center"
                  sx={{marginTop: "100px", marginBottom: "2rem"}}>
        <Typography variant="body1">Got a new feature or bug to report?</Typography>
        <CustomButton buttonType="outlined" href={feedbackLink} target="_blank">
            <Box component="span" sx={{marginRight: "1rem"}}>
                <ShareKnowledge/>
            </Box>
            {"Share Feedback"}
        </CustomButton>
    </Stack>
}

export default FeedbackButton;
