import BaseEvaluator from '../base-evaluator.js';

export default class OpenComparison extends BaseEvaluator {

    constructor(config) {
      super(config);
      this.name = 'Open Comparison';
      this.targetField = config.targetField;
      this.comparator = config.comparator;
      if (!isNaN(config.comparisonValue)) {
        this.comparisonValue = new Number(config.comparisonValue);
      } else if (new Date(config.comparisonValue).getTime() > 1) {
        this.comparisonValue = Date.parse(config.comparisonValue);
      } else if (typeof (config.comparisonValue) === "string") {
        this.comparisonValue = config.comparisonValue;
      } else {
        this.comparisonValue = config.comparisonValue;
      }
    }

    addErrorFields(fieldArray) {
      if (!fieldArray) {
        fieldArray = [];
      }
      if (!fieldArray.includes(this.targetField)) {
        fieldArray.push(this.targetField);
      }
      return fieldArray;
    }

    _getSummary() {
      return `The number found in '${this.targetField}' must be ${this.comparator} than ${this.comparisonValue}`;
    }

    getTriggerMessage() {
      if (this.comparator === "=") {
        return `${this.targetField} is equal to ${this.comparisonValue}`;
      } else if (this.comparator === "!=") {
        return `${this.targetField} is unequal to ${this.comparisonValue}`;
      } else if (this.comparator === ">") {
        return `${this.targetField} is greater than ${this.comparisonValue}`;
      } else if (this.comparator === "<") {
        return `${this.targetField} is less than ${this.comparisonValue}`;
      } else if (this.comparator === ">=") {
        return `${this.targetField} is greater than or equal to ${this.comparisonValue}`;
      } else if (this.comparator === "<=") {
        return `${this.targetField} is less than or equal to ${this.comparisonValue}`;
      }
    }

    _evaluate(record, finalRecord) {
      if (!this.targetField || !record[this.targetField.trim()]) {
        return {result: false, missingData: true, description: 'Necessary fields not present', fields: this.addErrorFields(null)};
      }
      let recordValue = record[this.targetField.trim()];
      if (!isNaN(recordValue)) {
        recordValue = new Number(recordValue);
      } else if (!isNaN(Date.parse(recordValue))) {
        recordValue = Date.parse(recordValue);
      }
      switch (this.comparator) {
        case ">": {
          if (recordValue > this.comparisonValue){
            record.errorDetail.push(this.getTriggerMessage());
            this.addErrorFields(record.errorFields);
            return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
          }
          break;
        }
        case "<": {
          if (recordValue < this.comparisonValue){
            record.errorDetail.push(this.getTriggerMessage());
            this.addErrorFields(record.errorFields);
            return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
          }
          break;
        }
        case ">=": {
          if (recordValue >= this.comparisonValue){
            record.errorDetail.push(this.getTriggerMessage());
            this.addErrorFields(record.errorFields);
            return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
          }
          break;
        }
        case "<=": {
          if (recordValue <= this.comparisonValue){
            record.errorDetail.push(this.getTriggerMessage());
            this.addErrorFields(record.errorFields);
            return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
          }
          break;
        }
        case "=": {
          if (recordValue == this.comparisonValue){
            record.errorDetail.push(this.getTriggerMessage());
            this.addErrorFields(record.errorFields);
            return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
          }
          break;
        }
        case "!=": {
          if (recordValue != this.comparisonValue){
            record.errorDetail.push(this.getTriggerMessage());
            this.addErrorFields(record.errorFields);
            return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
          }
          break;
        }
      }
      return {result: false, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
    }

    _perturb(record) {
      if (record[this.targetField] && this.comparisonValue) {
        if (this.comparator === "!=") {
          record[this.targetField] = this.comparisonValue;
        } else if (this.comparator === ">") {
          record[this.targetField] = this.comparisonValue;
        } else if (this.comparator === "<") {
          record[this.targetField] = this.comparisonValue;
        } else if (this.comparator === ">=") {
          this.perturbValue(record, this.targetField, true);
        } else if (this.comparator === "<=") {
          this.perturbValue(record, this.targetField, false);
        } else if (this.comparator === "=") {
          this.perturbValue(record, this.targetField, true);
        }
        record.perturbed = true;
        const newRecord = JSON.parse(JSON.stringify(record));
        newRecord.perturbed_field = this.targetField;
        return newRecord;
      }
    }
}
