const emptyProject = {
    "application_id": 1,
    "operating_unit_id": null,
    "name": "",
    "description": "",
    "status": "active",
    "type": "",
    "group_name": "",
    "cost_center": "",
    "data_sets": [],
    "metadata": {},
    "roster": [],
    "workbenches": [],
    "deleted": false
}

export default emptyProject;
