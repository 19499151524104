import CRUDManager from "./crud-manager";
import Dataset from "./dataset";

// CRUD SDK for 'Dataset' entity
class DatasetManager extends CRUDManager{

    //Class constructor
    constructor(sessionManager, logger, config, environment, pullData) {
        super('Dataset', 'dataset', sessionManager, logger, config, environment, pullData);
    }

    getNewEntity(json) {
        const newEntity = new Dataset();
        newEntity.setAttributes(json);
        return newEntity;
    }

    // CREATE dataset
    async createDataset(newDataset) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at DATASET manager in getting headers during create dataset");
            throw headersError;
        }
        let result = null;
        try {
            result = await super.create(newDataset, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // UPDATE dataset
    async updateDataset(updatedDataset) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at DATASET manager in getting headers during update dataset");
            throw headersError;
        }
        let result = null;
        try {
            await super.update(updatedDataset, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // DELETE dataset
    async deleteDataSet(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at DATASET manager in getting headers during delete dataset");
            throw headersError;
        }
        let result = null;
        try {
            await super.delete(id, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // GET ALL datasets
    async getAllDatasets(onSuccess, onFailure) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at DATASET manager in getting headers during get all datasets");
            throw headersError;
        }

        //Get all datasets
        let result = null;
        try {
            result = await super.getAll(headersResult, onSuccess, onFailure);
        } catch (exception) {
            console.log("Error encountered fetching all datasets.");
            console.log(exception);
            return [];
        }
        console.log("Datasets fetched successfully.");
        let allDatasets = [];
        if (result) {
            for (let record of result) {
                let dataset = new Dataset();
                dataset.setAttributes(record);
                allDatasets.push(dataset);
            }
        }
        return allDatasets;
    }

    // GET dataset based on Id
    async getDataset(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at DATASET manager in getting headers during get dataset");
            throw headersError;
        }
        try {
            let result = await super.getOne(id, headersResult);
            return result;
        } catch (error) {
            this.logger.error(error.message);
            return null;
        }
    }
}

export default DatasetManager;
