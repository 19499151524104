import AndEvaluator from './evaluators/boolean/and-evaluator.js';
import OrEvaluator from './evaluators/boolean/or-evaluator.js';
import NumberComparison from './evaluators/core/number-comparison.js';
import OpenComparison from './evaluators/core/open-comparison.js';
import FieldComparison from './evaluators/core/field-comparison.js';
import DateComparison from './evaluators/core/date-comparison.js';
import RecordPeriodicity from './evaluators/core/record-periodicity.js';
import TextOccurrence from './evaluators/core/text-occurrence.js';
import TextOccurrenceWithTime from './evaluators/core/text-occurrence-with-time.js';
import TextOccurrenceWithTimeWindow from './evaluators/core/text-occurrence-with-time-window.js';
import DateFieldRelativeComparison from './evaluators/core/date-field-relative-comparison.js';
import ChoiceMatchInField from './evaluators/core/choice-match-in-field.js';
import DateComparisonToField from './evaluators/core/date-comparison-to-field.js';
import DateComparisonToFieldWithValue from './evaluators/core/date-comparison-to-field-with-value.js';
import DateComparisonToFieldWithWindow from './evaluators/core/date-comparison-to-field-with-window.js';
import MetadataFieldComparison from './evaluators/core/metadata-field-comparison.js';

export default class EvaluatorFactory {

    constructor() {

    }

    getEvaluator(config) {
        switch (config.nativeType) {
          case "and": return this.populateParentAction(new AndEvaluator(config), config);
          case "or": return this.populateParentAction(new OrEvaluator(config), config);
          case "numberComparison": return new NumberComparison(config);
          case "unmappedNumberComparison": return new NumberComparison(config);
          case "fieldComparison": return new FieldComparison(config);
          case "metadataFieldComparison": return new MetadataFieldComparison(config);
          case "dateComparison": return new DateComparison(config);
          case "recordPeriodicity": return new RecordPeriodicity(config);
          case "textOccurrence": return new TextOccurrence(config);
          case "textOccurrenceWithTime": return new TextOccurrenceWithTime(config);
          case "textOccurrenceWithTimeWindow": return new TextOccurrenceWithTimeWindow(config);
          case "openComparison": return new OpenComparison(config);
          case "unmappedOpenComparison": return new OpenComparison(config);
          case "dateFieldRelativeComparison": return new DateFieldRelativeComparison(config);
          case "choiceMatchInField": return new ChoiceMatchInField(config);
          case "dateComparisonToField": return new DateComparisonToField(config);
          case "dateComparisonToFieldInRelatonToValue": return new DateComparisonToFieldWithValue(config);
          case "dateComparisonToFieldInRelatonToWindow": return new DateComparisonToFieldWithWindow(config);
        }
        console.log(`No rule component found: '${config.type}'`);
    }

    populateParentAction(evaluator, config) {
      for (const key of Object.keys(config.content)) {
        const childDef = config.content[key];
        const childEvaluator = this.getEvaluator(childDef);
        evaluator.children.push(childEvaluator);
      }
      return evaluator;
    }
}
