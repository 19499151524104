import CRUDManager from "./crud-manager";
import JobCluster from "./job-cluster";

// CRUD SDK for 'job_cluster' table
class JobClusterManager extends CRUDManager{

    constructor(sessionManager, logger, config, environment, pullData) {
        super('JobCluster', 'jobcluster', sessionManager, logger, config, environment, pullData);
    }

    getNewEntity(json) {
        const newEntity = new JobCluster();
        newEntity.setAttributes(json);
        return newEntity;
    }

    // CREATE jobCluster
    async createJobCluster(newJobCluster) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at JOBCLUSTER manager in getting headers during create job cluster");
            throw headersError;
        }
        let result = null;
        try {
            result = await super.create(newJobCluster, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // UPDATE jobCluster
    async updateJobCluster(updatedJobCluster) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at JOBCLUSTER manager in getting headers during update job cluster");
            throw headersError;
        }
        let result = null;
        try {
            await super.update(updatedJobCluster, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // DELETE jobCluster based on id
    async deleteJobCluster(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at JOBCLUSTER manager in getting headers during delete job cluster");
            throw headersError;
        }
        let result = null;
        try {
            await super.delete(id, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // GET ALL jobClusters
    async getAllJobClusters(onSuccess, onFailure) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at JOBCLUSTER manager in getting headers during get all job clusters");
            throw headersError;
        }
        let allEntities = [];
        try {
            let result = await super.getAll(headersResult, onSuccess, onFailure);
            for (let record of result) {
                let entity = new JobCluster();
                entity.setAttributes(record);
                allEntities.push(entity);
            }
        } catch (error) {
            this.logger.error(error.message);
        }
        return allEntities;
    }

    // GET a jobCluster based on Id
    async getJobCluster(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at JOBCLUSTER manager in getting headers during get job cluster");
            throw headersError;
        }
        try {
            let result = await super.getOne(id, headersResult);
            return result;
        } catch (error) {
            this.logger.error(error.message);
            return null;
        }
    }

    async getJobClusterInstanceStatuses(job_cluster_name) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at JOBCLUSTER manager in getting headers during get job cluster statuses");
            throw headersError;
        }
        try {
            let statuses = await this.restManager.post('jobcluster/status', {"name": job_cluster_name}, headersResult);
            return statuses;
        } catch (error) {
            this.logger.error(error.message);
            return {};
        }
    }
}

export default JobClusterManager;
