import {Box, Button, Grid, MenuItem, Paper, Stack, TextField, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import {useContext, useState} from "react";
import to from 'await-to-js';

import {APIContext} from "../../utils/api-context";
import JobCluster from "../../mdt-lib/hdp-client-sdk/orm/job-cluster";
import {CustomButton} from "@medtronic/pecc-react-component-library-js";
import EventMediator from "../../utils/event-mediator";
import RestNotifier from "../page-content-components/rest-notifier";

function ClusterView() {
    const navigate = useNavigate();
    const {projectid, workbenchid} = useParams();
    const apiManager = useContext(APIContext);
    const [protocluster, setProtocluster] = useState({project_id: projectid});
    const [workbench, setWorkbench] = useState({id: "ID", name: "Name"});
    const [project, setProject] = useState({id: "ID", name: "Name"});
    const [submitState, setSubmitState] = useState("editing");
    const eventMediator = new EventMediator();

    const cluster_template_options = [{
        label: "ML Flow Runner", value: "ml-flow-runner"
    }]
    const cluster_type_options = [{
        label: "t2.micro", value: "t2.micro"
    }]

    apiManager.workbenchManager.getWorkbench(workbenchid)
        .then(resp => {
            console.log(resp);
            if (workbench.id === "ID") {
                setWorkbench(resp);
                setProtocluster({
                    ...protocluster,
                    workbench_id: resp.id,
                    name: `${resp.name} cluster`,
                    description: `${resp.name}-description`
                })
            }
        })
        .catch(error => console.error(error));

    apiManager.projectManager.getProject(projectid)
        .then(resp => {
            console.log(resp);
            if (project.id === "ID") {
                setProject(resp);
            }
        })
        .catch(error => console.error(error));

    function handleChange(event) {
        let value;

        if (event.target.type === 'checkbox') {
            value = String(event.target.checked)
        } else {
            value = event.target.value
        }

        setProtocluster({...protocluster, [event.target.name]: value});
        console.log(protocluster)
    }

    function handleError(err) {
        console.error(err);
        alert(err); // Should later turn into a toast and/or console log
    }

    async function onSubmit(event) {
        event.preventDefault();

        console.log('Submitting job cluster');
        const newCluster = new JobCluster();
        newCluster.setAttributes(protocluster);
        newCluster.workbench_id = workbench.id;
        newCluster.name = workbench.name + "-cluster";
        newCluster.description = workbench.name + "-cluster-description";
        newCluster.status = 'running';
        newCluster.current_job_name = 'None';
        newCluster.current_job_status = 'NA';
        newCluster.metadata = {};
        newCluster.log_activity = '';

        setSubmitState("submitting");

        const [createError, createResult] = await to(apiManager.jobClusterManager.createJobCluster(newCluster));
        if (createError) {
            eventMediator.failure();
            handleError(createError);
            setSubmitState("editing");
        } else {
            eventMediator.success();
            navigate(-1);
        }
    }

    return <>
        <Paper elevation={2} sx={{width: "100%", padding: "2rem"}}>
            <Typography variant="h1">
                Create New Cluster
            </Typography>
            <form id="project-form" onSubmit={onSubmit}>
                <Grid container spacing={2} style={{"marginTop": "1px"}}>
                    <Grid item xs={12}>
                        <Paper elevation={2} sx={{padding: "0.5rem"}}>
                            <Typography>
                                Project: {project.name}
                            </Typography>
                            <Typography>
                                Workbench: {workbench.name}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            id="cluster_detail_template_dropdown"
                            name="cluster_template"
                            label="Cluster Template"
                            value={protocluster.cluster_template}
                            onChange={handleChange}
                            fullWidth
                            select
                            required
                        >
                            {cluster_template_options.map((option) => <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>)}
                        </TextField>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            id="cluster_detail_type_dropdown"
                            name="cluster_type"
                            label="Cluster Type"
                            fullWidth
                            select
                            value={protocluster.cluster_type}
                            onChange={handleChange}
                            required
                        >
                            {cluster_type_options.map((option) => <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>)}
                        </TextField>
                    </Grid>
                </Grid>
                <br/>
                <Stack spacing={2} direction="row">
                    <CustomButton buttonType="outlined" onClick={() => navigate(-1)}>Close</CustomButton>
                    <CustomButton buttonType="loading" label={submitState === "editing" ? "Submit" : submitState} type="submit" form="project-form" isLoading={submitState !== "editing"}/>
                </Stack>
            </form>
            <RestNotifier eventMediator={eventMediator} successMessage={"Job Cluster created successfully"} warningMessage={"Warnings detected, see logs."} failureMessage={"Job Cluster creation failed. Try again later."} />
        </Paper>
    </>
}

export default ClusterView;
