import BaseAction from './base-action.js';

export default class DoNothing extends BaseAction {

    constructor(config) {
      super(config);

    }

    takeAction(record) {
      //console.log(`Doing nothing...`);
    }
}
