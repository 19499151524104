import AllRecordsSelector from './selectors/all-records.js';
import FieldBasedSelector from './selectors/field-based-selector.js';
import BatchBasedSelector from './selectors/batch-based-selector.js';
import EachRecordSelector from './selectors/each-record.js';

export default class SelectorFactory {

    constructor() {

    }

    getSelector(config, records) {
        switch (config.type) {
          case "allRecords": return new AllRecordsSelector(config, records);
          case "fieldBased": return new FieldBasedSelector(config, records);
          case "fieldBatched": return new BatchBasedSelector(config, records);
          case "eachRecord": return new EachRecordSelector(config, records);
        }
        console.log(`No selector found: '${config.type}'`);
    }
}
