import HDPEntity from "./hdp-entity";

// Entity Class for 'AppUser'
class AppUser extends HDPEntity {

    constructor(logger) {
        super();
        this.email = "";
        this.first_name = "";
        this.last_name = "";
        this.type = "";
        this.status = "";
        this.profile = {};
        this.authAccounts = [];
    }

    setAttributes(json) {
        super.setAttributes(json);
        this.id = json.id;
        this.email = json.email;
        this.first_name = json.first_name;
        this.last_name = json.last_name;
        this.type = json.type;
        this.status = json.status;
        if (json.profile) {
          this.profile = json.profile;
        }
        if (json.auth_accounts) {
          this.auth_accounts = json.auth_accounts;
        }
        if (json.operating_unit_id) {
          this.operating_unit_id = json.operating_unit_id;
        }
    }

    stripNonNewAttributes(json) {
      super.stripNonNewAttributes(json);
      delete json.roles;
      delete json.projectRoles;
      delete json.datasetRoles;
      delete json.privileges;
    }

    stripUpdateAttributes(json) {
      super.stripUpdateAttributes(json);
      delete json.roles;
      delete json.projectRoles;
      delete json.datasetRoles;
      delete json.privileges;
    }

    decorate(currentApplicationId, applicationRolesArray,
        projectRolesArray, projectsDataCache,
        datasetRolesArray, datasetDataCache,
        rolesDataCache ) {
      this.roles = [];
      this.projectRoles = {};
      this.datasetRoles = {};
      for (let applicationRole of applicationRolesArray) {
        if (applicationRole.application_id === currentApplicationId && applicationRole.user_id === this.id) {
          let application = rolesDataCache[applicationRole.role_id];
          if (application) {
              applicationRole.name = rolesDataCache[applicationRole.role_id].name;
              this.roles.push(applicationRole);
          } else {
              this.logger.warn(`Missing application cannot be assigned to user based on application role record: ${applicationRole.project_id}`)
          }
        }
      }
      for (let projectRole of projectRolesArray) {
        if (projectRole.user_id === this.id) {
          let project = projectsDataCache[projectRole.project_id];
          if (project) {
            projectRole.project_name = project.name;
            projectRole.role_name = rolesDataCache[projectRole.role_id].name;
            this.projectRoles[projectRole.project_id] = projectRole;
          } else {
            this.logger.warn(`Missing project cannot be assigned to user based on project role record: ${projectRole.project_id}`);
          }
        }
      }
      for (let datasetRole of datasetRolesArray) {
        if (datasetRole.user_id === this.id) {
          let dataset = datasetDataCache[datasetRole.dataset_id];
          if (dataset) {
            datasetRole.dataset_name = dataset.name;
            datasetRole.role_name = rolesDataCache[datasetRole.role_id].name;
            this.datasetRoles[datasetRole.dataset_id] = datasetRole;
          } else {
            this.logger.warn(`Missing dataset cannot be assigned to user based on dataset role record: ${datasetRole.dataset_id}`);
          }
        }
      }
    }
}

export default AppUser;
