import ExplicitRule from "./explicit-rule.rule.js";

export default class CompatibleDatasetsRule extends ExplicitRule {

    constructor(pullData, logger) {
        super(pullData, logger);
        this.name = "Compatible Datasets Rule";
        this.description = "Datasets that host deidentified data cannot be " +
            "combined with identifiable datasets in the same project.";
    }

    __executeRule(path, method, payload) {
        const projectDatasets = this.getProjectDatasets(payload["project_id"]);
        const newDataset = this.getDataset(payload["dataset_id"]);
        for (let dataset of projectDatasets) {
            try {
                if (newDataset.metadata.de_identified != dataset.metadata.de_identified) {
                    this.offendingData.push(dataset);
                    return {success: false, message: this.description, severity: "failure", details:this.getViolationDetails()};
                }
            } catch (exception) {
                this.logger.error(exception.message);
            }
        }
        return {success: true, message: this.description, severity: "none", details:null};
    }

    getViolationDetails() {
        if (this.offendingData.length > 0) {
            const dataset  = this.getDataset(this.offendingData[0]);
            return `The '${dataset.name}' dataset has a different de-identified classification. `;
        }
        return "No further details available";
    }


}