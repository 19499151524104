import moment from "moment/moment";
import {Paper, Typography} from "@mui/material";
import {Chips, CustomButton} from "@medtronic/pecc-react-component-library-js";
import * as PropTypes from "prop-types";
import {Stack, Link} from "@mui/material";
import {APIContext} from "../utils/api-context";
import {useContext} from "react";

function ProjectCard({project}) {
    console.log(project)
    const dateFormat = "MM/DD/YYYY";
    const processedCreatedDate = moment.utc(project.created).local().format(dateFormat);
    const projectOwnerFilter = Array.isArray(project.users)
    ? project.users.filter(Q => Q.role.id === 12) : [];
    const projectOwnerUser = projectOwnerFilter.length > 0 ? projectOwnerFilter[0].user : null;
    const apiManager = useContext(APIContext);

    let numberOfMembersStr = "Unknown";
    if (project && project.users) {
      numberOfMembersStr = project.users.length.toString();
    }

    let projectStatus = "Unknown";
    if (project && project.status && project.status.length > 0) {
      projectStatus = project.status.charAt(0).toUpperCase() + project.status.slice(1);
    }
    const projectChipsConfig = [
        {
            label: projectStatus,
            type: "confirm"
        },
        {
            label: `${numberOfMembersStr} members`,
            type: "informative"
        }
    ]

    const creatorUser = apiManager.appUserManager.dataCache[project.created_by];
    const sysUserNames = ['system', 'setup'];

    return <Paper sx={{width: "32%", minWidth: "20rem", padding: "1rem", wordWrap: "break-word"}}>
        <Stack direction="column" justifyContent="space-between" sx={{height: "100%"}}>
            <Stack direction="column">
                <Typography variant={"h4"}>
                    {project.name}
                </Typography>
                <Typography paragraph sx={{"whiteSpace": "pre-wrap"}}>
                    {project.description}
                </Typography>
            </Stack>
            <Stack direction="column">
                <Chips chipsConfig={projectChipsConfig} alignment={"left"}/>
                {projectOwnerUser && <Typography variant={"body2"}>
                    {`Owner: ${projectOwnerUser.first_name} ${projectOwnerUser.last_name}`}
                </Typography>}
                <Typography variant={"body2"}>
                    {`Created by ${sysUserNames.includes(project.created_by) || !Object.keys(apiManager.appUserManager.dataCache).includes(project.created_by) ? project.created_by : 
                        `${creatorUser.first_name} ${creatorUser.last_name}`} on ${processedCreatedDate}`}
                </Typography>
                <Stack direction="row" justifyContent="center" sx={{marginTop: "1rem"}}>
                    <Link href={`/projects/${project.id}`}>
                        <CustomButton
                            buttonType="outlined"
                            label="View Details"
                        />
                    </Link>
                </Stack>
            </Stack>
        </Stack>
    </Paper>;
}

ProjectCard.propTypes = {
    active: PropTypes.string,
    members: PropTypes.number,
    teamLead: PropTypes.string,
    createdBy: PropTypes.string,
    createdDate: PropTypes.string,
    description: PropTypes.string
}

export default ProjectCard;
