import {Box, Grid, Radio, Stack, Typography} from "@mui/material";
import {SortAscending, SortDescending} from "@carbon/icons-react";
import {useContext, useState} from "react";
import {
    AlertBanner,
    Chips,
    CustomButton,
    Dropdown,
    LoadingSpinner,
    Paper
} from "@medtronic/pecc-react-component-library-js";
import {APIContext} from "../../utils/api-context";
import {useParams} from "react-router-dom";
import ConfirmationDialog from "./ConfirmationDialog";
import * as React from "react";
import {generateID} from "../../utils/text-utils";

function getUserDropdownOptions (userList) {
    return userList.map(appUser => ({
        optionLabel: getAppUserLabel(appUser),
        optionValue: appUser.id,
        groupLabel: "appUser",
    }));
}

function getAppUserLabel(appUser) {
    let appUserLabel = `${appUser.first_name} ${appUser.last_name}`;

    if ('profile' in appUser && 'NTID' in appUser.profile) {
        appUserLabel += ` (${appUser.profile.NTID})`;
    }

    return appUserLabel;
}

// USERNAME_COL_WIDTH + (PRIVILEGE_COL_WIDTH * 2) + REMOVE_BTN_COL_WIDTH must equal 12 (the width of
// the whole "table" as dictated by Google's MUI
const USERNAME_COL_WIDTH = 5;
const PRIVILEGE_COL_WIDTH = 2;
const REMOVE_BTN_COL_WIDTH = 3;

const PROJECTADDABLEROLENAMES = ["data_scientist", "technician"];

function UserPrivilegesControlPanel() {
    const {projectid} = useParams();
    const [panelRefreshKey, setPanelRefreshKey] = useState(false);
    const [rulesNotificationMessage, setRulesNotificationMessage] = useState();
    const setRuleResultMessage = ruleResult => {
        setRulesNotificationMessage(`${ruleResult.message}.  ${ruleResult.details}`)
    };

    const apiManager = useContext(APIContext);
    const listenerID = generateID();
    apiManager.projectRoleManager.addRuleResultsListener(setRuleResultMessage, listenerID);
    const project = apiManager.projectManager.dataCache[projectid];
    const currentUser = apiManager.getCurrentUser();
    const canMakeChanges = Object.keys(currentUser.projectRoles).includes(projectid) &&
        currentUser.projectRoles[projectid].role_id <= MAINTAINER;
    const canMakeLeaderChanges = canMakeChanges && currentUser.projectRoles[projectid].role_id <= OWNER;

    const projectUserDropdownOptions = getUserDropdownOptions(apiManager.projectManager.dataCache[projectid].users.map(u => u.user));

    let emptyChanges = {};
    for (let user of apiManager.projectManager.dataCache[projectid].users) {
        if (projectid in user.user.projectRoles) {
            emptyChanges[user.user.id] = [user.user.projectRoles[projectid].role_id];
        } else {
            // console.warn(`projectid ${projectid} not found in user ${user.first_name} ${user.last_name}'s projectRoles!`);
            emptyChanges[user.user.id] = [READER];
        }
    }
    console.log("Initial privileges loaded.");
    console.log(emptyChanges);
    let [privileges, setPrivileges] = useState(emptyChanges);

    function AddNewUserForm() {
        const [newUser, setNewUser] = useState();
        const [newUserLoading, setNewUserLoading] = useState(false);
        const allUsersDropdownOptions = getUserDropdownOptions(apiManager.appUserManager.dataArray);
        const addableUsers = apiManager.appUserManager.dataArray
            .filter(user => {
                const already_here = project.users.map(u => u.user.id).includes(user.id);
                const appropriate_role = user.roles.filter(role => PROJECTADDABLEROLENAMES.includes(role.name)).length > 0;
                const have_all_dataset_permissions = project.datasets.every(projectDataset => {
                    return projectDataset.id in user.datasetRoles && !["pending_reader", "pending_writer"].includes(user.datasetRoles[projectDataset.id].roleName);
                })
                return !already_here && appropriate_role;
            })
        const addableUsersOptions = getUserDropdownOptions(addableUsers);

        function changeAddNewUser(event) {
            console.log(`Changed add new user with value "${event.optionValue}"`);
            setNewUser(event.optionValue);
        }

        function submitAddNewUser(user) {
            console.log(`Submitting add new user with value "${user}"...`);
            setNewUserLoading(true);
            if (apiManager.projectManager.dataCache[projectid].users) {
                let userList = apiManager.projectManager.dataCache[projectid].users.map(u => u.user.id);
                userList.push(user);
                const rulesFinding = apiManager.projectRoleManager.rulesManager.runExplicitRuleSet('newMember',
                    {user_id: user, project_id: parseInt(projectid)});
                if (!rulesFinding.success) {
                    // Remove the new user from the user list
                    userList.pop();
                    return;
                }
                apiManager.updateProjectUsers(userList, apiManager.projectManager.dataCache[projectid].id)
                    .then(res => {
                        console.log(res);
                        console.log(apiManager.projectManager.dataCache[projectid]);
                        apiManager.__decorateUsers();
                        apiManager.__decorateProjects();
                        emptyChanges[user] = [READER];
                        privileges[user] = [READER];
                        setPanelRefreshKey(!panelRefreshKey);
                    })
                    .catch(err => {
                        console.error(err);
                        alert(`Error: ${err}`);
                    });
            } else {
                console.log("Project users not populated, skipping submitting new user.")
            }
        }

        return <Stack spacing={2} width="50%">
            <Dropdown
                dropdownOptions={addableUsersOptions}
                label="Add&nbsp;New User"
                onChange={changeAddNewUser}
                value={newUser}
                disabled={!canMakeChanges || rulesNotificationMessage}
            />
            {newUser && <CustomButton
                buttonType="loading"
                isLoading={newUserLoading}
                disabled={newUserLoading || !canMakeChanges || rulesNotificationMessage}
                label="Submit New User"
                onClick={() => {
                    submitAddNewUser(newUser);
                }}
            />}
        </Stack>
    }

    function ChangeProjectOwnerForm() {
        const [newOwner, setNewOwner] = useState();
        const [newOwnerIsSubmitting, setNewOwnerIsSubmitting] = useState(false);
        const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
        const newOwnerUser = apiManager.appUserManager.dataCache[newOwner];
        const newOwnerName = `${newOwnerUser ? newOwnerUser.first_name : "Arthur"} ${newOwnerUser ? newOwnerUser.last_name : "Dent"}`
        const transferableUsers = apiManager.projectManager.dataCache[projectid].users.filter(u => u.user.id !== currentUser.id);
        const transferableUsersDropdownOptions = getUserDropdownOptions(transferableUsers.map(u => u.user));

        function changeChangeTeamLead(event) {
            console.log(`Changed project owner value to ${event.optionValue}`);
            setNewOwner(event ? event.optionValue : null);
        }

        function submitChangeTeamLead(ownerUsername) {
            console.log(`Submitted change project owner with value "${ownerUsername}"`);
            setNewOwnerIsSubmitting(true);

            let privileges = {}
            for (let user of apiManager.projectManager.dataCache[projectid].users.map(u => u.user)) {
                if (user.id === ownerUsername) {
                    privileges[user.id] = [12];
                } else if (user.projectRoles[projectid].role_id === OWNER) {
                    privileges[user.id] = [15];
                } else {
                    privileges[user.id] = [user.projectRoles[projectid].role_id];
                }
            }

            const updatedMemberIds = Object.keys(privileges);
            const projectID = parseInt(projectid);

            try {
                apiManager.updateProjectUsersWithRoles(updatedMemberIds, projectID, privileges)
                    .then(() => {
                        setNewOwnerIsSubmitting(false);
                        setNewOwner(null);
                        window.location.reload();
                    })
                    .catch(err => console.error(err));
            } catch (error) {
                alert(error);
            }
        }

        return <Stack spacing={2} width="50%">
            <Dropdown
                dropdownOptions={transferableUsersDropdownOptions}
                label="Change Project&nbsp;Owner"
                onChange={changeChangeTeamLead}
                value={newOwner}
                disabled={!canMakeLeaderChanges || rulesNotificationMessage}
            />
            {newOwner && <CustomButton
                buttonType="loading"
                isLoading={newOwnerIsSubmitting}
                disabled={newOwnerIsSubmitting || !canMakeLeaderChanges || rulesNotificationMessage}
                label="Submit"
                onClick={() => setConfirmationModalOpen(true)}
            />}
            <ConfirmationDialog
                open={confirmationModalOpen}
                handleClose={() => setConfirmationModalOpen(false)}
                dialogTitle={`Change the project owner for this project to ${newOwnerName}?`}
                dialogContent={`Are you sure you want to change the project owner for ${apiManager.projectManager.dataCache[projectid].name}?\n\nThis will re-assign ownership to ${newOwnerName} and you will no longer have ownership of the project.`}
                primaryLabel={"Submit"}
                onPrimaryClick={() => {
                    setNewOwnerIsSubmitting(true);
                    setConfirmationModalOpen(false);
                    submitChangeTeamLead(newOwner);
                }}
                secondaryLabel="Cancel"
                onSecondaryClick={() => setConfirmationModalOpen(false)}
            />
        </Stack>
    }

    return <Box sx={{padding: "1rem"}}>
        <UserPrivilegesTable
            emptyChanges={emptyChanges}
            privileges={privileges}
            setPrivileges={setPrivileges}
            refreshKey={panelRefreshKey}
            setRefreshKey={setPanelRefreshKey}
            rulesNotificationMessage={rulesNotificationMessage}
        />
        {project.status !== 'inactive' &&
            <Stack>
                {rulesNotificationMessage && <AlertBanner
                    alertMessage={rulesNotificationMessage}
                    alertType="caution"
                    bannerType="notice"
                    onActionClick={() => setRulesNotificationMessage(null)}
                    actionButtonLabel="Dismiss"
                />}
                <Stack direction="row" spacing={USERNAME_COL_WIDTH} sx={{marginTop: "2rem"}}>
                    <AddNewUserForm/>
                    <ChangeProjectOwnerForm/>
                </Stack>
            </Stack>
        }
    </Box>
}

function SortIndicator({ascending, toggle, disabled}) {
    const iconSize="20";
    return <CustomButton buttonType="borderless" onClick={toggle} disabled={disabled}>
        {ascending ? <SortAscending size={iconSize}/> : <SortDescending size={iconSize}/>}
    </CustomButton>
}

function UserPrivilegesTableHeader({ascending, toggle}) {
    const sortingToggleEnabled = false;
    return <>
        <Grid item xs={USERNAME_COL_WIDTH}>
            <Stack direction="row" alignItems="center">
                <Typography fontWeight={600} lineHeight="1.6rem">Username</Typography>
                {sortingToggleEnabled && <SortIndicator ascending={ascending} toggle={toggle}/>}
            </Stack>
        </Grid>
        <Grid item xs={PRIVILEGE_COL_WIDTH}>
            <Typography fontWeight={600} lineHeight="1.6rem">
                Reader
            </Typography>
        </Grid>
        <Grid item xs={PRIVILEGE_COL_WIDTH}>
            <Typography fontWeight={600} lineHeight="1.6rem">
                Writer
            </Typography>
        </Grid>
        <Grid item xs={REMOVE_BTN_COL_WIDTH}>
            {/*Column unlabeled for the row-end buttons*/}
        </Grid>
        <hr width={"100%"}/>
    </>;
}

function sortUsersFn(key_attr, ascending) {
    const ascendingConstant = ascending ? 1 : -1;
    key_attr = key_attr.split('.');
    return function(userA, userB) {
        let sort_key_attr_A = userA;
        let sort_key_attr_B = userB;

        for (let attr of key_attr) {
            sort_key_attr_A = sort_key_attr_A[attr];
            sort_key_attr_B = sort_key_attr_B[attr];
        }

        if (sort_key_attr_A > sort_key_attr_B) {
            return 1 * ascendingConstant;
        } else if (sort_key_attr_B > sort_key_attr_A) {
            return -1 * ascendingConstant;
        } else {
            return 0;
        }
    }
}

const READER = 15;
const WRITER = 14;
const MAINTAINER = 13;
const OWNER = 12;
const PENDING = "pending";

function UserPrivilegesRows({ascending, users, emptyChanges, privileges, setPrivileges, refreshKey, setRefreshKey, rulesNotificationMessage}) {
    const {projectid} = useParams();
    const apiManager = useContext(APIContext);
    const currentUser = apiManager.getCurrentUser();
    const canMakeChanges = Object.keys(currentUser.projectRoles).includes(projectid) &&
        currentUser.projectRoles[projectid].role_id <= MAINTAINER;

    const [privilegeButtonEnabled, setPrivilegeButtonEnabled] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [removeUser, setRemoveUser] = useState();
    const [removeSubmitting, setRemoveSubmitting] = useState();

    users.sort(sortUsersFn('user.first_name', ascending))

    async function removeUserFromProject(userIDtoRemove, projectID, userList, setRemoveSubmitting) {
        console.log(`Remove user called for user "${userIDtoRemove} from project ${projectID}`);
        projectID = parseInt(projectID);

        try {
            console.log(`Remove clicked for user ${userIDtoRemove}`);
            const userList = apiManager.projectManager.dataCache[projectid].users.map(user => user.user.id);
            const privilegesWithRemoval = {...privileges, [userIDtoRemove]: []};
            apiManager.updateProjectUsersWithRoles(userList, projectID, privilegesWithRemoval)
                .then(async resp => {
                    console.log(resp);
                    setRemoveSubmitting(null);
                    apiManager.__decorateUsers();
                    apiManager.__decorateProjects();
                    delete emptyChanges[userIDtoRemove];
                    delete privileges[userIDtoRemove];
                    setRefreshKey(!refreshKey);
                })
                .catch(err => console.error(err));
        } catch (error) {
            alert(error);
        }
    }

    function UserPrivilegeRow({user, changes, setChanges, removeSubmitting, setRemoveSubmitting, rulesNotificationMessage}) {
        const radioProps = {'& .MuiSvgIcon-root': {fontSize: 28}}
        const userFullName = `${user.first_name} ${user.last_name}`;

        const handleChange = event => {
            console.log(`handleChange called for user ${user.first_name} ${user.last_name} (${user.id}) with event target ${JSON.stringify(event.target.value)}`);
            const newChanges = {...changes, [user.id]: [parseInt(event.target.value)]};

            function areUserPrivsUnchanged(userId) {
                const userChanges = newChanges[userId];
                const userEmptyChanges = emptyChanges[userId];
                return (
                    // Are the lists equivalent in their contents?
                    userChanges.every(a => userEmptyChanges.includes(a)) &&
                    userEmptyChanges.every(b => userChanges.includes(b))
                )
            }

            const areAllPrivsUnchanged = Object.keys(newChanges).every(areUserPrivsUnchanged);
            setPrivilegeButtonEnabled(!areAllPrivsUnchanged);
            setChanges(newChanges);
        };

        return <>
            <Grid item xs={USERNAME_COL_WIDTH}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography lineHeight="1.6rem" color={"#1010EB"}>
                        {userFullName}
                    </Typography>
                    {changes[user.id].includes(OWNER) && <Chips chipsConfig={[{
                        customColor: 'electricBlue.main',
                        // customIcon: <CatchingPokemonSharp size={14} color="#FFFFFF" />,
                        isWhiteFont: true,
                        label: 'Project Owner',
                        // type: 'customSolid'
                    }]}/>}
                </Stack>
            </Grid>
            <Grid item xs={PRIVILEGE_COL_WIDTH}>
                <Radio
                    checked={changes[user.id].includes(READER)}
                    disabled={changes[user.id].includes(OWNER) || !canMakeChanges}
                    onChange={handleChange}
                    value={READER}
                    name={`${user.id}-radio-buttons`}
                    sx={radioProps}
                />
            </Grid>
            <Grid item xs={PRIVILEGE_COL_WIDTH}>
                <Radio
                    checked={changes[user.id].includes(WRITER)}
                    disabled={changes[user.id].includes(OWNER) || !canMakeChanges}
                    onChange={handleChange}
                    value={WRITER}
                    name={`${user.id}-radio-buttons`}
                    sx={radioProps}
                />
            </Grid>
            <Grid container item xs={REMOVE_BTN_COL_WIDTH} justifyContent="flex-end">
                {!changes[user.id].includes(OWNER) && <Stack direction="row" spacing={2}>
                    <CustomButton buttonType="outlined"
                                  disabled={removeSubmitting || !canMakeChanges || rulesNotificationMessage}
                                  onClick={() => {
                                      setRemoveSubmitting(user.id);
                                      setRemoveUser(user);
                                  }}
                    >
                        {removeSubmitting === user.id ? "Removing..." : "Remove"}
                        {removeSubmitting === user.id && <LoadingSpinner spinnerSize="xs"/>}
                    </CustomButton>
                </Stack>}
            </Grid>
            <hr width={"100%"}/>
        </>
    }

    function submitChanges() {
        console.log(privileges);
        setIsSubmitting(true);

        const updatedMemberIds = Object.keys(privileges);
        const projectID = parseInt(projectid);

        try {
            apiManager.updateProjectUsersWithRoles(updatedMemberIds, projectID, privileges)
                .then(() => {
                    apiManager.__decorateUsers();
                    apiManager.__decorateProjects();
                    emptyChanges = privileges;
                    setIsSubmitting(false);
                    setRefreshKey(!refreshKey);
                    setPrivilegeButtonEnabled(false);
                })
                .catch(err => console.error(err));
        } catch (error) {
            alert(error);
        }
    }

    return <>
        {users.map(user => (
            <UserPrivilegeRow
                user={user.user}
                changes={privileges}
                setChanges={setPrivileges}
                removeSubmitting={removeSubmitting}
                setRemoveSubmitting={setRemoveSubmitting}
                rulesNotificationMessage={rulesNotificationMessage}
            />
        ))}
        <ConfirmationDialog
            open={removeUser}
            handleClose={() => {
                setRemoveSubmitting(null);
                setRemoveUser(null);
            }}
            dialogTitle={`Are you sure you want to remove ${removeUser ? removeUser.first_name : "Arthur"} ${removeUser ? removeUser.last_name : "Dent"} from the project?`}
            dialogContent={""}
            primaryLabel="Remove User"
            onPrimaryClick={() => {
                removeUserFromProject(removeUser.id, projectid, apiManager.projectManager.dataCache[projectid].users, setRemoveSubmitting);
                setRemoveUser(null);
            }}
            secondaryLabel="Cancel"
            onSecondaryClick={() => {
                setRemoveUser(null);
                setRemoveSubmitting(null);
            }}
        />
        {privilegeButtonEnabled && <Stack direction="row-reverse" spacing={2}>
            <CustomButton buttonType="loading"
                          isLoading={isSubmitting}
                          disabled={isSubmitting || rulesNotificationMessage}
                          onClick={submitChanges}
                          label={isSubmitting ? "Submitting Privilege Changes..." : "Submit Privilege Changes"}
            />
        </Stack>}
    </>
}

function UserPrivilegesTable({emptyChanges, privileges, setPrivileges, refreshKey, setRefreshKey, rulesNotificationMessage}) {
    const [userSortAscending, setUserSortAscending] = useState(true);
    const toggleUserSortAscending = () => setUserSortAscending(!userSortAscending);

    const {projectid} = useParams();
    const apiManager = useContext(APIContext);

    const projectUserDropdownOptions = getUserDropdownOptions(apiManager.projectManager.dataCache[projectid].users.map(u => u.user));

    return <Paper sx={{margin: "1rem", padding: "2rem"}}>
        <Grid container spacing={1} justifyContent="center" alignItems="center">
            <UserPrivilegesTableHeader
                ascending={userSortAscending}
                toggle={toggleUserSortAscending}
            />
            <UserPrivilegesRows
                ascending={userSortAscending}
                users={apiManager.projectManager.dataCache[projectid].users}
                emptyChanges={emptyChanges}
                privileges={privileges}
                setPrivileges={setPrivileges}
                refreshKey={refreshKey}
                setRefreshKey={setRefreshKey}
                rulesNotificationMessage={rulesNotificationMessage}
            />
        </Grid>
    </Paper>
}

export default UserPrivilegesControlPanel;
