import RoleRelation from "./role-relation";

class DatasetRole extends RoleRelation {

    constructor() {
        super();
        this.dataset_id = -1;
        this.metadata = {};
    }

    setAttributes(json) {
        super.setAttributes(json);
        this.dataset_id = json.dataset_id;
        this.metadata = json.metadata;
    }
}

export default DatasetRole;
