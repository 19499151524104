import RoleRelation from "./role-relation";

class ProjectRole extends RoleRelation {

    constructor() {
        super();
        this.project_id = -1;
    }

    setAttributes(json) {
        super.setAttributes(json);
        this.project_id = json.project_id;
    }
}

export default ProjectRole;
