export default class BaseCondition {

    constructor(config, action) {
      this.action = action;
      this.customMessage = config.customMessage;
      this.tripped = false;
      this.trueResults= [];
      this.falseResults = [];
      this.offendingRecord = null;
      this.invert = false;
    }

    proceed() {
      throw new Error('#proceed must be implemented in child class');
    }

    hasTagInResultGroup(resultGroup, tagName) {
      for (let result of resultGroup) {
        if (result.result[tagName]) {
          return true;
        }
      }
    }

    renderResults() {
      const prelimResults = this._renderResults();
      prelimResults.inverted = this.invert;
      prelimResults.hasMissingData = this.hasTagInResultGroup(prelimResults.falseResults, 'missingData');
      prelimResults.hasBadData = this.hasTagInResultGroup(prelimResults.falseResults, 'badData');
      prelimResults.recordFieldsUsed = this.recordFieldsUsed;
      if (this.customMessage) {
        prelimResults.customMessage = this.customMessage;
      }
      return prelimResults;
    }

    _renderResults() {
      throw new Error('#renderResults must be implemented in child class');
    }

    addResult(result, ruleEvaluator, record) {
      if (result.result) {
        this.trueResults.push({result: result, record: record});
      } else {
        this.falseResults.push({result: result, record: record});
      }
      if (!this.recordFieldsUsed) {
        this.recordFieldsUsed = result.fields;
      }
      this.resultAdded(result, ruleEvaluator, record);
    }

    resultAdded(result, ruleEvaluator, record) {
      throw new Error('#resultAdded must be implemented in child class');
    }
}
