import Logger from "../logger";

class HDPEntity {

    constructor() {
        this.id = "";
        this.deleted = false;
        this.created = new Date();
        this.createdBy = "";
        this.updated = new Date();
        this.updatedBy = "";
        const startColorRGBArray = [100,100,255];
        const endColorRGBArray = [0,0,50];
        this.logger =  new Logger(startColorRGBArray, endColorRGBArray, 'HDP-SDK');
    }

    setAttributes(json) {
      this.id = json.id;
      this.deleted = json.deleted;
      this.created = json.created;
      this.createdText = new Date(json.created).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"});
      this.created_by = json.created_by;
      this.updated = json.updated;
      this.updatedText = new Date(json.updated).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"});
      this.updated_by = json.updated_by;
    }

    stripNonNewAttributes(json) {
      delete json.id;
      delete json.deleted;
      delete json.created;
      delete json.createdBy;
      delete json.createdText;
      delete json.updated;
      delete json.updatedBy;
      delete json.updatedText;
      delete json.logger;
    }

    stripUpdateAttributes(json) {
      delete json.created;
      delete json.createdBy;
      delete json.createdText;
      delete json.updated;
      delete json.updatedBy;
      delete json.updatedText;
      delete json.logger;
    }
}

export default HDPEntity;
