import {createBrowserRouter, useRouteError} from "react-router-dom";
import Layout from "./components/layout";
import ProjectContainer from "./components/page-content-components/project-container";
import ProjectPane from "./components/pages/project-pane";
import WorkbenchView from "./components/pages/workbench-view";
import ClusterView from "./components/pages/cluster-view";
import DataTableDisplay from "./components/page-content-components/data-table-display";
import LogoutDisplay from "./components/page-content-components/logout-display";
import UnderConstruction from "./components/pages/under-construction";
import NoMatch from "./components/pages/no-page-found";
import {Box, Stack, Typography} from "@mui/material";

function ErrorBoundary() {
    let error = useRouteError();
    console.error(error);
    // Uncaught ReferenceError: path is not defined
    return <Box>
        <Stack justifyContent="center" alignItems="baseline">
            <Typography variant="h1">Error</Typography>
            <Typography variant="body1">{error.message || JSON.stringify(error)}</Typography>
        </Stack>
    </Box>
}

const Router = createBrowserRouter([{
    Component: Layout,
    errorElement: <ErrorBoundary/>,
    children: [{
        path: "/",
        errorElement: <ErrorBoundary/>,
        Component: ProjectContainer
    }, {
        path: "projects",
        errorElement: <ErrorBoundary/>,
        Component: ProjectContainer
    }, {
        path: "projects/:projectid",
        errorElement: <ErrorBoundary/>,
        Component: ProjectPane
    }, {
        path: "projects/:projectid/workbench/:workbenchid/newcluster",
        errorElement: <ErrorBoundary/>,
        Component: ClusterView
    }, {
        path: "data-catalog",
        errorElement: <ErrorBoundary/>,
        Component: DataTableDisplay
    }, {
        path: "logout",
        errorElement: <ErrorBoundary/>,
        Component: LogoutDisplay
    }, {
        path: "*",
        errorElement: <ErrorBoundary/>,
        Component: NoMatch
    }]
}])

export default Router;
