import CRUDManager from "./crud-manager";

// CRUD SDK for 'servicenow' operations
class ServiceNowManager extends CRUDManager{

    constructor(sessionManager, logger, config, environment) {
        super('ServiceNow', 'servicenow', sessionManager, logger, config, environment);
    }

    // CREATE ServiceNow ticket
    async createNewTicket(newticketInfoJson) {
        let headersResult = null;
        try {
          headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
          this.logger.error("Error encountered at SERVICE NOW TICKET manager in getting headers during create ticket");
          throw headersError;
        }
        let result = null;
        try {
            result = await super.create_by_payload(newticketInfoJson, headersResult)
        } catch (error) {
            this.logger.error("ERROR encountered during create new ticket");
            this.logger.error(error);
            throw error;
        }
        return result;
    }

    // GET ServiceNow ticket by request-number
    async getTicketByRequestNumber(requestNumber) {
        let headersResult = null;
        try {
          headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
          this.logger.error("Error encountered at SERVICE NOW TICKET manager in getting headers during get ticket by request number");
          throw headersError;
        }
        this.RESTPath = this.RESTPath + '/reqnum'
        let result = null;
        try {
            result = await super.getOne(requestNumber, headersResult);
        } catch (error) {
            this.logger.error("Error encountered at SERVICENOW manager while retrieving ticket based on request number");
            this.logger.error(error.message);
            return null;
        }
        return result;
    }

    // Get ServiceNow ticket by requested-for
    async getTicketByUserId(requestedForUserId) {
        let headersResult = null;
        try {
          headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
          this.logger.error("Error encountered at SERVICE NOW TICKET manager in getting headers during get ticket by user id");
          throw headersError;
        }
        this.RESTPath = this.RESTPath + '/reqfor';
        let result = null;
        try {
            result = await super.getOne(requestedForUserId, headersResult);
        } catch (error) {
            this.logger.error("Error encountered at SERVICENOW manager while retrieving ticket based on requested for");
            this.logger.error(error.message);
            return null;
        }
        return result;
    }
}

export default ServiceNowManager;
