import HDPEntity from "./hdp-entity";

class DatasetProjectRelation extends HDPEntity  {

    constructor() {
        super();
        this.project_id = -1;
        this.dataset_id = -1;
    }

    setAttributes(json) {
        super.setAttributes(json);
        this.project_id = json.project_id;
        this.dataset_id = json.dataset_id;
    }
}

export default DatasetProjectRelation;
