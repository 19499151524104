import React from 'react';
import ReactDOM from 'react-dom/client';
import Cookies from 'js-cookie';
import to from 'await-to-js';

import {authInitialized, getSignInURL, initAuth} from './mdt-lib/digital-health-auth/di-authentication';
import {getLoggedInUserId} from './mdt-lib/digital-health-auth/di-authentication';
import {getClaims} from './mdt-lib/digital-health-auth/di-authentication';
import {initCoreAPI} from './mdt-lib/hdp-client-sdk/hdp-api';
import {getAPIManager} from "./mdt-lib/hdp-client-sdk/hdp-api";
import './index.css';

import MainPanel from './App';
import reportWebVitals from './reportWebVitals';
import {AppContainer} from '@medtronic/pecc-react-component-library-js';

import { APIContext } from "./utils/api-context";
import Environment from "./config/environment";
const environment = new Environment();

const root = ReactDOM.createRoot(document.getElementById('root'));

const codePresent = () => {
  const entryParams = {};
  const urlEnd = window.location.search;
  const queryString = urlEnd.substring(1);
  const params = queryString.split("&");
  for (let entry of params) {
      let pair = entry.split('=');
      entryParams[pair[0]] = pair[1];
  }
  if (entryParams.code) {
    return true;
  }
}

const cookiePresent = () => {
  let accessCookieStr = Cookies.get(environment.getCookieName() + "-access");
  let idCookieStr = Cookies.get(environment.getCookieName() + "-id");
  let refreshCookieStr = Cookies.get(environment.getCookieName() + "-refresh");
  let expiresCookieStr = Cookies.get(environment.getCookieName() + "-expires");
  if (accessCookieStr && idCookieStr &&  refreshCookieStr && expiresCookieStr) {
      return true;
  }
}

const renderTokenError = () => {
  removeSpinner();
  root.render(
    <React.StrictMode>
        <AppContainer>
          <div className={"login-billboard"}>
              <h3>An error occurred when attempting to sign in.  Unable to create Authentication tokens, please contact your administrator.</h3>
              <h4>Click <a href={environment.settings.auth.signOutURL}>here</a> to return to login screen.</h4>
          </div>
        </AppContainer>
    </React.StrictMode>
  );
}

const renderUserNotRegistered = () => {
  removeSpinner();
  root.render(
    <React.StrictMode>
        <AppContainer>
            <div className={"login-billboard"}>
                <h3>Oops, it looks like your Medtronic user id isn't able to access this application.</h3>
                <br />
                <h3>Please contact your administrator to get access.</h3>
                <h4>Click <a href={environment.settings.auth.signOutURL}>here</a> to return to login screen.</h4>
            </div>
        </AppContainer>
    </React.StrictMode>
  );
}

const renderApplicationNotAvailable = () => {
  removeSpinner();
  root.render(
    <React.StrictMode>
        <AppContainer>
            <div className={"login-billboard"}>
              <h3>The application is currently unavailable, please check back later.</h3>
              <h4>Click <a href={environment.settings.auth.signOutURL}>here</a> to login</h4>
            </div>
        </AppContainer>
    </React.StrictMode>
  );
}

const removeSpinner = () => {
  const spinnerContainer = document.querySelector("#loading-spinner-container");
  if (spinnerContainer) {
    spinnerContainer.remove();
  }
}

const renderClient = () => {
  removeSpinner();
    if (isAuthed && apiManager.currentUser && appAvailable) {
        root.render(
            <React.StrictMode>
                <AppContainer>
                    <APIContext.Provider value={apiManager}>
                      <MainPanel/>
                    </APIContext.Provider>
                </AppContainer>
            </React.StrictMode>
        );
    }
}

const initRESTComs = () => {
  console.log("Core API initializing...");
  initCoreAPI(environment.settings);
  console.log("Core API initialized.");
  apiManager = getAPIManager();
  const claims = getClaims();
  console.log("******** CLAIMS **********")
  console.log(claims);
  jwtUsername = getLoggedInUserId();
  if (!jwtUsername) {
    console.log("No logged in user id detected.");
  }
}

const getData = async () => {
  apiManager.lazyLoad();
  const [initError, initResult] = await to(apiManager.initializeData(jwtUsername, renderClient));
  if (initError) {
    console.log("Failed to initialize application data.");
    if (initError.unavailable) {
      appAvailable = false;
      console.log("Application is unavailable, please check back later.");
    }
  } else {
    isAuthed = true;
  }
}


let isAuthed = false;
let apiManager = null;
let appAvailable = true;
let jwtUsername = null;
let tokensOK = false;
let noSessionAtAll = false;
if (!cookiePresent() && !codePresent()) {
  console.log("No evidence of authentication, redirecting...");
  noSessionAtAll = true;
} else if (!authInitialized()) {
    console.log("Digital health authentication system initializing...");
    const [tokenError, tokens] = await to (initAuth(environment.settings.auth));
    if (tokenError) {
      console.log("Digital health authentication system failed to initialize.");
      console.error(tokenError);

    } else {
      tokensOK = true;
      console.log("Digital health authentication system initialized.");
    }
} else {
  tokensOK = true;
  console.log("Digital health authentication system already initialized.");
}

if (tokensOK) {
  initRESTComs();
  const [dataError, data] = await to(getData());
  if (dataError) {
    console.log(dataError);
    throw dataError;
  }
}

if (noSessionAtAll) {
  window.location = environment.settings.auth.signOutURL;
} else if (jwtUsername && !apiManager.currentUser) {
    renderUserNotRegistered();
} else if (!appAvailable) {
    renderApplicationNotAvailable();
} else if (!tokensOK){
    renderTokenError();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
