import BaseAction from './base-action.js';

export default class CreateAlert extends BaseAction {

    constructor(config) {
      super(config);

    }

    takeAction(record) {
      console.log(`Creating alert!`);
    }
}
