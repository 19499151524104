import AlwaysTrue from './conditions/always-true.js';
import NeverTrue from './conditions/never-true.js';
import TrueAtLeastNumber from './conditions/true-at-least-number.js';
import TrueNumberOfTimes from './conditions/true-number-of-times.js';
import TruePerRecord from './conditions/true-per-record.js';

export default class ConditionFactory {

    constructor() {

    }

    getCondition(config, action) {
        switch (config.type) {
          case "alwaysTrue": return new AlwaysTrue(config, action);
          case "neverTrue": return new NeverTrue(config, action);
          case "trueAtLeastNumber": return new TrueAtLeastNumber(config, action);
          case "trueNumberOfTimes": return new TrueNumberOfTimes(config, action);
          case "individualRecord": return new TruePerRecord(config, action);
        }
        console.log(`No condition found: '${config.type}'`);
    }
}
