import BaseEvaluator from '../base-evaluator.js';

export default class BaseParentEvaluator extends BaseEvaluator {

    constructor(config) {
      super(config);
      this.children = [];
    }

    _evaluate(record, finalRecord, perturb) {
      throw new Error('#evaluate must be implemented in child class');
    }

    evaluate(record, finalRecord) {
      return this._evaluate(record, finalRecord);
    }

    _perturb(record) {
      throw new Error('#_perturb must be implemented in child class');
    }

    perturb(record, chance) {
      if (!record.perturbed) {
        for (let child of this.children) {
          return child.perturb(record, chance);
        }
      }
    }

    addFields(fieldsArray, incomingFields) {
      for (let incomingField of incomingFields) {
        if (fieldsArray.indexOf(incomingField) < 0) {
          fieldsArray.push(incomingField);
        }
      }
    }
}
