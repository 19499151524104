import BaseEvaluator from '../base-evaluator.js';

export default class ChoiceMatchInField extends BaseEvaluator {


    constructor(config) {
      super(config);
      this.name = 'Choice Match In Field';
      this.targetField = config.targetField.trim();
      this.value = config.value;
    }

    addErrorFields(fieldArray) {
      if (!fieldArray) {
        fieldArray = [];
      }
      if (!fieldArray.includes(this.targetField)) {
        fieldArray.push(this.targetField);
      }
      return fieldArray;
    }

    _getSummary() {
      return `The value found in '${this.targetField}' must be ${this.value}`;
    }

    getTriggerMessage() {
      return `${this.targetField} was equal to ${this.value}`;
    }

    _evaluate(record, finalRecord) {
      if (!this.targetField || !record[this.targetField.trim()]) {
        return {result: false, missingData: true, description: 'Necessary fields not present', fields: this.addErrorFields(null)};
      }
      const recordValue = Date.parse(record[this.targetField.trim()]);
      if (isNaN(recordValue)) {
        return {result: false, badData: true, description: 'Necessary value type not present', fields: this.addErrorFields(null)};
      }
      if (recordValue === this.value){
        record.errorDetail.push(this.getTriggerMessage());
        this.addErrorFields(record.errorFields);
        return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
      } else {
        return {result: false, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
      }
    }

    _perturb(record) {
      if (record[this.targetField]) {
        this.perturbValue(record, this.targetField, true);
        record.perturbed = true;
        const newRecord = JSON.parse(JSON.stringify(record));
        newRecord.perturbed_field = this.targetField;
        return newRecord;
      }
    }
}
