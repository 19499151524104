import {Paper, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {Catalog, ModelBuilder, DeploymentPattern} from "@carbon/icons-react";

function MoreToExplore() {
    const iconsize = 36;
    const iconcolor = "#1010EB";

    return <Box sx={{margin: "auto", paddingTop: "2rem"}}>
        <Typography variant={"h2"}>More to Explore</Typography>
        <Stack direction="row" justifyContent="flex-start" alignItems="baseline" spacing={4} sx={{marginTop: "1rem"}}>
            <Paper>
                <Stack direction="row" justifyContent="space-evenly" spacing={2} sx={{padding: "1rem"}}>
                    <Typography variant="h3">Data Catalog</Typography>
                    <Catalog size={iconsize} fill={iconcolor}/>
                </Stack>
            </Paper>
            <Paper>
                <Stack direction="row" justifyContent="space-evenly" spacing={2} sx={{padding: "1rem"}}>
                    <Typography variant="h3">Artifacts</Typography>
                    <ModelBuilder size={iconsize} fill={iconcolor}/>
                </Stack>
            </Paper>
            <Paper>
                <Stack direction="row" justifyContent="space-evenly" spacing={2} sx={{padding: "1rem"}}>
                    <Typography variant="h3">Deployments</Typography>
                    <DeploymentPattern size={iconsize} fill={iconcolor}/>
                </Stack>
            </Paper>
        </Stack>
    </Box>;
}

export default MoreToExplore;