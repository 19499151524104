import CRUDManager from "./crud-manager";
import Artifact from "./artifact";

// CRUD SDK for 'artifact' table
class ArtifactManager extends CRUDManager{

    constructor(sessionManager, logger, config, environment, pullData) {
        super('Artifact', 'artifact', sessionManager, logger, config, environment, pullData);
    }

    getNewEntity(json) {
        const newEntity = new Artifact();
        newEntity.setAttributes(json);
        return newEntity;
    }

    // CREATE artifact
    async createArtifact(newArtifact) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at USER manager in getting headers during create artifact");
            throw headersError;
        }
        let result = null;
        try {
            result = await super.create(newArtifact, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // UPDATE artifact
    async updateArtifact(updatedArtifact) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at ARTIFACT manager in getting headers during update artifact");
            throw headersError;
        }
        let result = null;
        try {
            await super.update(updatedArtifact, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // DELETE artifact based on id
    async deleteArtifact(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at ARTIFACT manager in getting headers during delete artifact");
            throw headersError;
        }
        let result = null;
        try {
            await super.delete(id, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // GET ALL artifact
    async getAllArtifacts(onSuccess, onFailure) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at ARTIFACT manager in getting headers during get all artifacts");
            throw headersError;
        }
        let allEntities = [];
        try {
            let result = await super.getAll(headersResult, onSuccess, onFailure);
            for (let record of result) {
                let entity = new Artifact();
                entity.setAttributes(record);
                allEntities.push(entity);
            }
        } catch (error) {
            this.logger.error(error.message);
        }
        return allEntities;
    }

    // GET a artifact based on Id
    async getArtifact(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at ARTIFACT manager in getting headers during get artifact");
            throw headersError;
        }
        try {
            let result = await super.getOne(id, headersResult);
            return result;
        } catch (error) {
            this.logger.error(error.message);
            return null;
        }
    }
}

export default ArtifactManager;
