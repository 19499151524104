import {useContext} from 'react';
import {Tabs} from "@medtronic/pecc-react-component-library-js";
import {Typography} from "@mui/material";
import {APIContext} from "../../utils/api-context";
import DatasetsDisplayTable from "./datasets-display-table";
import ProjectsDisplayTable from "./projects-display-table";

function DataTableDisplay() {
    const apiManager = useContext(APIContext);
    const user = apiManager.getCurrentUser();
    console.log(user);
    const tabsData = [{
        label: "Datasets",
        content: <DatasetsDisplayTable/>,
        visible: user.privileges.dataset.canView,
    }, {
        label: "Projects",
        content: <ProjectsDisplayTable/>,
        visible: user.privileges.project.canView,
    }];
    return <>
        <Typography variant="h2">Data Catalog</Typography>
        <Tabs
            isLeftAlign
            width="100%"
            tabsData={tabsData.filter(tab => tab.visible)}
        />
    </>
}

export default DataTableDisplay;
