import CRUDManager from "./crud-manager";
import Workbench from "./workbench";

// CRUD SDK for 'workbench' table
class WorkbenchManager extends CRUDManager{

    constructor(sessionManager, logger, config, environment, pullData) {
        super('Workbench', 'workbench', sessionManager, logger, config, environment, pullData);
    }

    getNewEntity(json) {
        const newEntity = new Workbench();
        newEntity.setAttributes(json);
        return newEntity;
    }

    // CREATE workbench
    async createWorkbench(newWorkbench) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at WORKBENCH manager in getting headers during create workbench");
            throw headersError;
        }
        let result = null;
        try {
            result = await super.create(newWorkbench, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // UPDATE workbench
    async updateWorkbench(updatedWorkbench) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at WORKBENCH manager in getting headers during update workbench");
            throw headersError;
        }
        let result = null;
        try {
            await super.update(updatedWorkbench, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // GET ALL workbenchs
    async getAllWorkbenches(onSuccess, onFailure) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at WORKBENCH manager in getting headers during get all workbenches");
            throw headersError;
        }
        let allEntities = [];
        try {
            let result = await super.getAll(headersResult, onSuccess, onFailure);
            for (let record of result) {
                let entity = new Workbench();
                entity.setAttributes(record);
                allEntities.push(entity);
            }
        } catch (error) {
            this.logger.error(error.message);
        }
        return allEntities;
    }

    // GET a workbench based on Id
    async getWorkbench(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at WORKBENCH manager in getting headers during get workbench");
            throw headersError;
        }
        try {
            let result = await super.getOne(id, headersResult);
            return result;
        } catch (error) {
            this.logger.error(error.message);
            return null;
        }
    }

    async connectToWorkbench(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at WORKBENCH manager in getting headers during connect to workbench");
            throw headersError;
        }
        let assumeRoleCreds = null;
        try {
            assumeRoleCreds = await this.restManager.get('connect/' + id, headersResult);
        } catch (error) {
            this.logger.error(error.message);
            return null;
        }
        return assumeRoleCreds;
    }

    async getWorkbenchStatuses(instance_ids, callback) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at WORKBENCH manager in getting headers during get workbench statuses");
            throw headersError;
        }

        let statuses = null;
        try {
            statuses = await this.restManager.post('workbench/status', instance_ids, headersResult);
        } catch (error) {
            this.logger.error(error.message);
            return null;
        }

        if (callback) {
            callback(statuses);
        }

        return statuses;
    }
}

export default WorkbenchManager;
