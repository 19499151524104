import ExplicitRule from "./explicit-rule.rule.js";

export default class NewProjectUserRule extends ExplicitRule {

    constructor(pullData, logger) {
        super(pullData, logger);
        this.name = "New Project User Rule (Dataset Rights)";
        this.description = "New project roster members must have rights " +
            "to datasets associated with the project before being added";
    }

    __executeRule(path, method, payload) {
        const incomingUserId = payload["user_id"];
        const datasetRoles = this.entityData["DatasetRole"];
        const acceptableRoleIds = [12, 13, 14, 15];
        const requiredDatasetIds = this.getProjectDatasetIds(payload["project_id"]);
        for (let datasetRole of datasetRoles) {
            let datasetIdIndex = requiredDatasetIds.indexOf(datasetRole.dataset_id);
            if (datasetRole.user_id === incomingUserId && datasetIdIndex >= 0 &&
                acceptableRoleIds.includes(datasetRole.role_id)) {
                requiredDatasetIds.splice(datasetIdIndex, 1);
            }
        }
        if (requiredDatasetIds.length > 0) {
            this.offendingData = requiredDatasetIds;
            return {success: false, message: this.description, severity: "failure", details:this.getViolationDetails()};
        }
        return {success: true, message: this.description, severity: "failure", details:null};
    }

    getViolationDetails() {
        if (this.offendingData.length > 0) {
            let datasetNames = "";
            for (let datasetId of this.offendingData) {
                const dataset  = this.getDataset(datasetId);
                datasetNames = datasetNames + ", " + dataset.name;
            }
            datasetNames = datasetNames.substring(2);
            return `New user does not have privileges to user these datasets:${datasetNames}. `;
        }
        return "No further details available";
    }
}