import Environment from './config/environment'

class RestManager {

  constructor(eventNotifier, environment) {
    this.environment = environment;
    this.headers = {};
    this.queryParams = {};
    this.eventNotifier = eventNotifier;
    this.ruleManagerCallbacks = [];
  }

  registerRuleManager(ruleManager) {
      if (ruleManager.runRules) {
          this.ruleManagerCallbacks.push(ruleManager.runRules)
      }
  }

  runRules(path, method, payload) {
      for (let callBackMethod of this.ruleManagerCallbacks) {
          let result  = callBackMethod(path, method, payload);
          if (!result.success) {
              return false;
          }
      }
      return true;
  }

  addPersistentHeaders(headers) {
    for (let key in headers) {
      this.headers[key] = headers[key];
    }
  }

  addPersistentHeader(key, value) {
    this.headers[key] = value;
  }

  clearPersisentHeaders() {
    this.headers = {};
  }

  addPersistentQueryParam(key, value) {
    this.queryParams[key] = value;
  }

  removePersistentHeader(key) {
    delete this.headers[key];
  }

  removePersistentQueryParam(key) {
    delete this.queryParams[key];
  }

  async callEndpoint (method, path, payload, headers, queryParams) {
      let proceed = this.runRules(path, method, payload);
      if (!proceed) {
          let failureToLaunch = {
              message: "Rules prevented issuing REST call"
          };
          return this.handleError(failureToLaunch);
      }
      let url = this.environment.getAPIURL() + path;
      let combinedQueryParams = {
            ...queryParams,
            ...this.queryParams
      };
      
      let queryParamKeys = Object.keys(combinedQueryParams)
      if (queryParamKeys.length > 0) {
          url += '?';
      }
      for (let key of queryParamKeys) {
        url = key + "=" + combinedQueryParams[key];
      }
      
      let combinedHeaders = {
            ...headers,
            ...this.headers
      };
      
      let params = {
        method: method,
        mode: 'cors',
        headers: combinedHeaders
      };
      
      if (payload) {
          params.body = JSON.stringify(payload);
      }
      
      let response = null;
      try {
        response = await fetch(url, params);
        this.eventNotifier.restEvent(path, method, response.statusCode, {});
        if (!response.ok) {
          return this.handleError(response);
        }
        return response;
      } catch (fetchException) {
        this.eventNotifier.restEvent(path, method, -1, fetchException);
        console.log(fetchException);
        return this.handleFetchException(fetchException);
      }
  }

  handleFetchException(exception) {
      console.log(exception);
      throw exception;
  }

  handleError(response) {
    console.log("Response came back with error-related status code");
    console.log(response);
    return null;
  }

  async get(path, headers, queryParams){
    try {
      let result  = await this.callEndpoint("GET", path, null, headers, queryParams);
      return result.json();
    } catch (error) {
      throw error;
    }    
  }

  async post(path, payload, headers, queryParams){
    try {
      let result  = await this.callEndpoint("POST", path, payload, headers, queryParams);
      return result.json();
    } catch (error) {
      throw error;
    }    
  }

  async put(path, payload, headers, queryParams){
    try {
      let result  = await this.callEndpoint("PUT", path, payload, headers, queryParams);
      return result.json();
    } catch (error) {
      throw error;
    }  
  }

  async patch(path, payload, headers, queryParams){
    try {
      let result  = await this.callEndpoint("PATCH", path, payload, headers, queryParams);
      return result.json();
    } catch (error) {
      throw error;
    }  
  }

  async delete(path, headers, queryParams){
    try {
      let result  = await this.callEndpoint("DELETE", path, null, headers, queryParams);
      return result.json();
    } catch (error) {
      throw error;
    }  
  }
}

export default RestManager;
