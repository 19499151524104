import SemanticObject from "./semantic-object";


// Entity Class for 'Application'
class Role extends SemanticObject {

    constructor() {
        super();
        this.type = "";
        this.source = "";
    }

    setAttributes(json) {
        super.setAttributes(json);
        this.type = json.type;
        this.source = json.source;
    }
}

export default Role;
