import DataLoaderService from "../rules/data-loader.service";
import RuleProcessingService from "../rules/rule-processor.service";
import EvaluatorFactory from "../rules/evaluator-factory.service.js";
import ActionFactory from "../rules/action-factory.service.js";
import ConditionFactory from "../rules/condition-factory.service.js";
import SelectorFactory from "../rules/selector-factory.service.js";


class RulesManager {

    constructor(restManager, rulesDef, pullData) {
        this.dataLoader = new DataLoaderService(pullData);
        const evaluatorFactory = new EvaluatorFactory();
        const actionFactory = new ActionFactory();
        const conditionFactory = new ConditionFactory();
        const selectorFactory = new SelectorFactory();
        this.ruleProcessor = new RuleProcessingService(evaluatorFactory, actionFactory, conditionFactory, selectorFactory);
        this.ruleResultsCallbacks = {};
        this.useRandom = false;
        this.explicitRules = [];
        this.explicitRuleSets = {};
        this.rulesDef = {

        };
        this.initRules(rulesDef);

        this.runExplicitRuleSet = (action, payload) => {
            let finding = {};
            if (!this.explicitRuleSets[action]) {
                finding = {
                    message: "No rules to execute.",
                    success: true,
                    results: []
                };
            } else {
                this.setExplicitRules(this.explicitRuleSets[action]);
                for (let explicitRule of this.explicitRules) {
                    finding = explicitRule.runRule(null, null, payload);
                    if (!finding.success) {
                        break;
                    }
                }
            }
            this.explicitRules = [];
            finding.action = action;
            if (!finding.success) {
                this.notifyListeners(finding);
            }
            return finding;
        }

        this.runRules = (path, method, payload) => {
            let finding = {};
            if (this.useRandom) {
                finding = this.getRandomFinding(path, method, payload);
            } else if (this.explicitRules.length > 0) {
                for (let explicitRule of this.explicitRules) {
                    finding = explicitRule.runRule(path, method, payload);
                    if (!finding.success) {
                        break;
                    }
                }
                this.explicitRules = [];
            } else {
                const ruleSet = this.rulesDef[method.toLowerCase()];
                if (ruleSet) {
                    let records = [];
                    const recordsDef = ruleSet["records"];
                    if (recordsDef && recordsDef["entities"]) {
                        records = this.dataLoader.getData(recordsDef["entities"]);
                    }
                    const results = [];//this.ruleProcessor.processRuleSet(records, ruleSet);
                    finding = {
                        message: "Rules have been disabled.",
                        success: true,
                        results: results
                    };
                    console.log("******** RULE RESULTS ********");
                    console.log(results);
                    console.log("******************************");
                } else {
                    finding = {
                        message: "No rules to execute.",
                        success: true,
                        results: []
                    };
                }
            }
            finding.path = path;
            finding.method = method;
            finding.payload = payload;
            if (!finding.success) {
                this.notifyListeners(finding);
            }
            return finding;
        }
        restManager.registerRuleManager(this);
    }

    initRules(rulesDef){
        this.rulesDef = rulesDef;
    }

    setExplicitRules(rules) {
        this.explicitRules = rules;
    }

    setExplicitRuleSet(action, rules) {
        this.explicitRuleSets[action] = rules;
    }

    addRuleResultsListener(callbackFunction, id) {
        this.ruleResultsCallbacks[id] = callbackFunction;
    }



    notifyListeners(finding) {
        const callbacks = Object.values(this.ruleResultsCallbacks);
        for (let callback of callbacks) {
            console.log(callback);
            callback(finding);
        }
    }

    getRandomFinding(path, method, payload) {
        const listeners = Object.keys(this.ruleResultsCallbacks);
        let success = true;
        let severity = "none";
        if (listeners.length > 0) {
            severity = "warning";
            if (Math.random() > 0.3) {
                success = false;
            }
            if (!success && Math.random() > 0.50) {
                severity = "failure";
            }
        }
        let message = `Simulated rule outcome: ${success}, severity: ${severity}, path: ${path}, method: ${method}`;
        return {success: success, message: message, severity: severity};
    }
}

export default RulesManager;
