import to from 'await-to-js';
import AuthManager from "./auth-manager";


class CognitoAuthManager extends AuthManager {

  async init(cookieData, entryParams) {
    if (entryParams && entryParams.code) {
      this.type = entryParams.type;
      const [tokensError, tokensResult] = await to(this.getAllTokens(entryParams["code"]));
      if (tokensError) {
        console.log("Issues encountered while initializing the Cognito auth manager.");
        console.log(tokensError);
        throw tokensError;
      }
      this.setAttributes(tokensResult);
      this.setExpiration(tokensResult);
      return tokensResult;
    } else if (cookieData && cookieData.id_token && cookieData.expiresAt && !this.refreshNeeded()) {
      this.setAttributes(cookieData);
      return cookieData;
    } else if (cookieData && cookieData.refresh_token) {
      this.setAttributes(cookieData);
      const [tokensError, tokensResult] = await to(this.refreshTokens(entryParams["code"]));
      if (tokensError) {
        console.log("Issues encountered while initializing the Cognito auth manager while attempting a refresh.");
        console.log(tokensError);
        throw tokensError;
      }
      this.setAttributes(tokensResult);
      this.setExpiration(tokensResult);
      return tokensResult;
    } else {
      console.log("No auth cookie and no one-time use code available.");
    }
  }

  setAttributes(jwtData) {
    if (jwtData.expires_in){
      this.expires_in = jwtData.expires_in;
    }

    this.expiresAt = jwtData.expiresAt;
    this.access_token = jwtData.access_token;
    this.id_token = jwtData.id_token;
    this.refresh_token = jwtData.refresh_token;
    this.type = jwtData.type;
  }

  writeCookieValues() {

  }

  setExpiration(jwtData) {
    if (jwtData && jwtData.expires_in) {
      const now = new Date().getTime();
      this.expiresAt = now + jwtData.expires_in;
    } else {
      console.log("No expiration information available for JWT token");
    }
  }

  async getAuthHeaders() {
    if (this.refreshNeeded()) {
      console.log("Auth headers requested from Congito Auth Manager, refreshing current tokens");
      const [refreshError, refreshResult] = await to(this.refreshTokens());
      if (refreshError) {
        throw refreshError;
      }
      console.log(refreshResult);
    } else {
      console.log("Auth headers requested from Congito Auth Manager, current tokens valid");
    }
    return {"Authorization": `Bearer ${this.id_token}`};
  }

  async getAllTokens (oneTimeUseCode) {
    const url = this.environment.settings.baseURL + '/oauth2/token';
    const authStr = "Basic " + btoa(`${this.environment.settings["clientId"]}:${this.environment.settings["clientSecret"]}`);
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      "Accept": "*/*",
      //"Host": "digital-health-unlock-com-test.com.us-east-1.amazoncognito.com",
      "Accept-Encoding": "gzip, deflate, br",
      "Content-Length":265,
      "Connection": "keep-alive",
      //"Authorization":  authStr
    };
     const body = Object.entries({
		"grant_type": "authorization_code",
		"client_id": this.environment.settings.clientId,
		"code": oneTimeUseCode,
		"redirect_uri": this.environment.settings.redirectURI
        }).map(([k, v]) => `${k}=${v}`).join("&");
    const options = {
      	method: "POST",
    	headers: headers,
	    body: body
    }
    let [tokenError, tokenResponse] = await to(fetch(url, options));
    if (tokenError) {
      console.log('Error in requesting tokens');
      throw tokenError;
    }
    let [jsonError, json] = await to(tokenResponse.json());
    if (!tokenResponse.ok) {
      console.log("Problems with com token retrieval");
      console.log(`Request return a status of: ${tokenResponse.status}, ${tokenResponse.statusText}`);
      throw new Error("Response did not produce a valid token");
    }
    if (jsonError) {
      throw jsonError;
    }
    this.access_token = json["access_token"];
    this.id_token = json["id_token"];
    this.refresh_token = json["refresh_token"];
    const expiresInSeconds = json["expires_in"];
    const expiresInMilliseconds = expiresInSeconds * 1000;
    const expiresAt = new Date().getTime() + expiresInMilliseconds;
    json.expiresAt = expiresAt;
    this.expiresAt = expiresAt;
    delete json.expires_in;
    window.history.replaceState({}, document.title, "/?type=cognito");
    return json;
  }

  async refreshTokens () {
    const body = Object.entries({
          "grant_type": "refresh_token",
          "client_id": this.environment.settings["clientId"],
          "redirect_uri": window.location.origin,
          "refresh_token": this.refresh_token,
      }).map(([k, v]) => `${k}=${v}`).join("&");
    const headers = new Headers({"content-type": "application/x-www-form-urlencoded"})
    const options = {
      method: "POST",
      headers: headers,
      body: body
    };
    const [refreshError, refreshResponse] = await to(fetch(`${this.environment.settings["baseURL"]}/oauth2/token`, options));
    if (refreshError) {
      throw refreshError;
    } else if (!refreshResponse.ok) {
      console.log("Problems with com token refresh");
      console.log(`Request return a status of: ${refreshResponse.status}, ${refreshResponse.statusText}`);
      return false;
    }
    let [jsonError, json] = await to(refreshResponse.json());
    if (jsonError) {
      throw jsonError;
    }
    this.access_token = json["access_token"];
    this.id_token = json["id_token"];
    this.refresh_token = json["refresh_token"];
    const expiresInSeconds = json["expires_in"];
    const expiresInMilliseconds = expiresInSeconds * 1000;
    const expiresAt = new Date().getTime() + expiresInMilliseconds;
    json.expiresAt = expiresAt;
    this.expiresAt = expiresAt;
    delete json.expires_in;
    return json;
  }
}

export default CognitoAuthManager;
