import BaseEvaluator from '../base-evaluator.js';

export default class TextOccurrenceWithTime extends BaseEvaluator {

    constructor(config) {
      super(config);
      this.name = 'Text Occurent with Time';
      this.textField = config.textField;
      this.term = config.term;
      this.dateField = config.dateField;
      this.value = config.value;
      this.relativeDateField = config.relativeDateField;
      this.comparator = config.comparator;
    }

    addErrorFields(fieldArray) {
      if (!fieldArray) {
        fieldArray = [];
      }
      if (!fieldArray.includes(this.textField)) {
        fieldArray.push(this.textField);
      }
      if (!fieldArray.includes(this.dateField)) {
        fieldArray.push(this.dateField);
      }
      if (!fieldArray.includes(this.relativeDateField)) {
        fieldArray.push(this.relativeDateField);
      }
      return fieldArray;
    }

    _getSummary() {
      return `The text '${this.term}' must be found in '${this.textField}', and the date found in ${this.dateField}
        must be ${this.comparator} than the date found in ${this.relativeDateField}`;
    }

    getTriggerMessage() {
      return `The text '${this.term}' was found in '${this.textField}', and the date found in ${this.dateField}
        was ${this.comparator} than the date found in ${this.relativeDateField}`;
    }

    _evaluate(record, finalRecord) {
      if (!this.textField || !this.dateField || !this.relativeDateField ||
          !record.hasOwnProperty(this.textField.trim()) || !record[this.dateField.trim()] || !record[this.relativeDateField.trim()]) {
        return {result: false, missingData: true, description: 'Necessary fields not present', fields: this.addErrorFields(null)};
      }
      if (this.doesDateFit(record) && (record[this.textField.trim()].indexOf(this.term) >= 0 || this.term === 'NOT~NULL')) {
        record.errorDetail.push(this.getTriggerMessage());
        this.addErrorFields(record.errorFields);
        return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
      }
      return {result: false, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
    }

    doesDateFit(record) {
      const dateValue = Date.parse(record[this.dateField.trim()]);
      const relativeDateValue = Date.parse(record[this.relativeDateField.trim()]);
      const diff = dateValue - relativeDateValue;
      const thresholdValue = parseInt(this.value) * 1000 * 60 * 60 * 24;
      switch (this.comparator) {
        case ">": {
          if (diff > thresholdValue){
            return true;
          }
          break;
        }
        case "<": {
          if (diff < thresholdValue){
            return true;
          }
          break;
        }
        case ">=": {
          if (diff >= thresholdValue){
            return true;
          }
          break;
        }
        case "<=": {
          if (diff <= thresholdValue){
            return true;
          }
          break;
        }
        case "=": {
          if (diff === thresholdValue){
            return true;
          }
          break;
        }
        case "!=": {
          if (diff != thresholdValue){
            return true;
          }
          break;
        }
      }
      return false;
    }

    _perturb(record) {
      if (record[this.textField] && record[this.dateField]) {
        const chance = Math.random();
        if (chance >= 0.50) {
          record[this.textField] = `${this.getStr(10)} ${this.getStr(10)} ${this.term} ${this.getStr(10)} ${this.getStr(10)}`;
          record.perturbed = true;
          const newRecord = JSON.parse(JSON.stringify(record));
          newRecord.perturbed_field = this.textField;
          return newRecord;
        } else {
          this.perturbValue(record, this.dateField, true);
          record.perturbed = true;
          const newRecord = JSON.parse(JSON.stringify(record));
          newRecord.perturbed_field = this.dateField;
          return newRecord;
        }
      }
    }

}
