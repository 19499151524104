import CRUDManager from "./crud-manager";
import ApplicationRole from "./application-role";

// CRUD SDK for 'ApplicationRole' entity
class ApplicationRoleManager extends CRUDManager{

    constructor(sessionManager, logger, config, environment, pullData) {
        super('ApplicationRole', 'application-role', sessionManager, logger, config, environment, pullData);
    }

    getNewEntity(json) {
        const newEntity = new ApplicationRole();
        newEntity.setAttributes(json);
        return newEntity;
    }

    // Create a new user
    async createApplicationRole(newRole) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at APPLICATION manager in getting headers during create application role");
            throw headersError;
        }
        let result = null;
        try {
            result = await super.create(newRole, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // Update existing user
    async updateApplicationRole(updatedRole) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at APPLICATION manager in getting headers during create application role");
            throw headersError;
        }
        let result = null;
        try {
            await super.update(updatedRole, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // Soft-Delete existing user
    async deleteApplicationRole(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at APPLICATION manager in getting headers during delete application role");
            throw headersError;
        }
        let result = null;
        try {
            await super.delete(id, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // Fetch all app roles
    async getAllApplicationRoles(onSuccess, onFailure) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at APPLICATION manager in getting headers during get all application roles");
            throw headersError;
        }
        let allEntities = [];
        try {
            let result = await super.getAll(headersResult, onSuccess, onFailure);
            for (let record of result) {
                let entity = new ApplicationRole();
                entity.setAttributes(record);
                allEntities.push(entity);
            }
        } catch (error) {
            this.logger.error(error.message);
        }
        return allEntities;
    }

    // Fetch a single user based on Id
    async getApplicationRole(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at APPLICATION manager in getting headers during get application role");
            throw headersError;
        }
        try {
            let result = await super.getOne(id, headersResult);
            return result;
        } catch (error) {
            this.logger.error(error.message);
            return null;
        }
    }

    validateForCreation(appUser) {

    }

    validateForUpdate(appUser) {
        this.getCreatable();
    }

    stripNonNewAttributes(appUser) {

    }

    stripNonUpdateAttributes(appUser) {

    }

    getCreatable() {
        const applicationRole = new ApplicationRole();
        this.stripNonNewAttributes(applicationRole);
        return applicationRole;
    }
}

export default ApplicationRoleManager;
