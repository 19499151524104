import BaseEvaluator from '../base-evaluator.js';

export default class TextOccurrenceWithTimeWindow extends BaseEvaluator {

  constructor(config) {
    super(config);
    this.name = 'Text Occurrence with Time Window';
    this.textField = config.textField;
    this.term = config.term;
    this.dateField = config.dateField;
    this.units = config.units;
    this.relativeDateField = config.relativeDateField;
    this.valueLowDisplay = config.valueLow;
    this.valueHighDisplay = config.valueHigh;
    this.valueLow = this.convertToMilliseconds(config.valueLow);
    this.valueHigh = this.convertToMilliseconds(config.valueHigh);

  }

  addErrorFields(fieldArray) {
    if (!fieldArray) {
      fieldArray = [];
    }
    if (!fieldArray.includes(this.textField)) {
      fieldArray.push(this.textField);
    }
    if (!fieldArray.includes(this.dateField)) {
      fieldArray.push(this.dateField);
    }
    if (!fieldArray.includes(this.relativeDateField)) {
      fieldArray.push(this.relativeDateField);
    }
    return fieldArray;
  }

  _getSummary() {
    let termPhrase = `The text '${this.term}' must be found in '${this.textField}'`;
    if (this.term === 'NOT~NULL') {
      termPhrase = `The value found in '${this.textField}' must not be missing`;
    }
    return `${termPhrase}, and the date found in '${this.dateField}'
      should be between ${this.convertFromMilliseconds(this.valueLow)} and ${this.convertFromMilliseconds(this.valueHigh)} ${this.units}
       from the date in '${this.relativeDateField}'`;
  }

  _evaluate(record, finalRecord) {
    if (!this.textField || !this.dateField || !this.relativeDateField ||
        !record.hasOwnProperty(this.textField.trim()) || !record[this.dateField.trim()] || !record[this.relativeDateField.trim()]) {
      return {result: false, missingData: true, description: 'Necessary fields not present', fields: this.addErrorFields(null)};
    }
    if (this.doesDateFit(record) && (record[this.textField.trim()].indexOf(this.term) >= 0 || (this.term === 'NOT~NULL' && record[this.textField.trim()].length > 0))) {
      record.errorDetail.push(this.getTriggerMessage());
      this.addErrorFields(record.errorFields);
      return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
    }
    return {result: false, description: this.getNotTriggerMessage(), fields: this.addErrorFields(null)};
  }

  getTriggerMessage() {
    return `The text '${this.term}' was found in '${this.textField}', and the date found in '${this.dateField}'
      was between ${this.convertFromMilliseconds(this.valueLow)} and ${this.convertFromMilliseconds(this.valueHigh)} ${this.units}
       from the date in '${this.relativeDateField}'`;
  }

  getNotTriggerMessage() {
    return `The text '${this.term}' was NOT found in '${this.textField}', or the date found in '${this.dateField}'
      was NOT between ${this.convertFromMilliseconds(this.valueLow)} and ${this.convertFromMilliseconds(this.valueHigh)} ${this.units}
       from the date in '${this.relativeDateField}'`;
  }

  doesDateFit(record) {
    const dateValue = Date.parse(record[this.dateField.trim()]);
    const relativeDateValue = Date.parse(record[this.relativeDateField.trim()]);
    const diff = dateValue - relativeDateValue;
    if (diff >= this.valueLow && diff <= this.valueHigh) {
      return true;
    }
    return false;
  }

  convertToMilliseconds(value) {
    switch (this.units) {
      case "seconds": {
        return value * 1000;
      }
      case "minutes": {
        return value * 60 * 1000;
      }
      case "hours": {
        return value * 60 *  60 * 1000;
      }
      case "days": {
        return value * 24 * 60 * 60 * 1000;
      }
      case "weeks": {
        return value * 7 * 24 * 60 * 60 * 1000;
      }
      case "months": {
        return value * 4.4 * 7 * 24 * 60 * 60 * 1000;
      }
      case "years": {
        return value * 12 * 4.4 * 7 * 24 * 60 * 60 * 1000;
      }
    }
    return value;
  }

  convertFromMilliseconds(value) {
    switch (this.units) {
      case "seconds": {
        return value/1000;
      }
      case "minutes": {
        return value/(60 * 1000);
      }
      case "hours": {
        return value/(60 *  60 * 1000);
      }
      case "days": {
        return value/(24 * 60 * 60 * 1000);
      }
      case "weeks": {
        return value/(7 * 24 * 60 * 60 * 1000);
      }
      case "months": {
        return value/(4.4 * 7 * 24 * 60 * 60 * 1000);
      }
      case "years": {
        return value/(12 * 4.4 * 7 * 24 * 60 * 60 * 1000);
      }
    }
    return value;
  }

  _perturb(record) {
    if (record[this.textField] && record[this.dateField]) {
      const chance = Math.random();
      if (chance >= 0.50) {
        this.perturbValue(record, this.textField, true);
        record.perturbed = true;
        const newRecord = JSON.parse(JSON.stringify(record));
        newRecord.perturbed_field = this.textField;
        return newRecord;
      } else {
        this.perturbValue(record, this.dateField, true);
        record.perturbed = true;
        const newRecord = JSON.parse(JSON.stringify(record));
        newRecord.perturbed_field = this.dateField;
        return newRecord;
      }
    }
  }
}
