import ProjectRole from "./orm/project-role";

// Class that provides pub/sub pattern for notification of succes/warning/failure
// events arising from sdk actions for subsequent processing such as with the
// display of message boxes, etc.
class EventNotifier {

    constructor(logger) {
        this.restEventSubscribers = {};
        this.logger = logger;
    }

    subscribeToRestEvents(endpointRegex, method, callBack) {
        const lowerMethod = method.toLowerCase();
        if (!this.restEventSubscribers[endpointRegex]) {
            this.restEventSubscribers[endpointRegex] = {};
        }
        if (!this.restEventSubscribers[endpointRegex][lowerMethod]) {
            this.restEventSubscribers[endpointRegex][lowerMethod] = [];
        }
        this.restEventSubscribers[endpointRegex][lowerMethod].push(callBack);
    }

    restEvent(endpoint, method, statusCode, data) {
        const lowerMethod = method.toLowerCase();
        if (this.restEventSubscribers && this.restEventSubscribers[endpoint] && this.restEventSubscribers[endpoint][method]) {
            const regexEntries = Object.keys(this.restEventSubscribers);
            for (let regexEntry of regexEntries) {
                if (regexEntry.test(endpoint)) {
                    this.logger.info(`Endpoint matches in notifier for ${endpoint}`);
                    if (this.restEventSubscribers[regexEntry][lowerMethod]) {
                        this.logger.info(`Method matches in notifier for ${endpoint}, ${lowerMethod}`);
                        try {
                            for (let subscriberCallback of this.restEventSubscribers[regexEntry][lowerMethod]) {
                                subscriberCallback(statusCode, data);
                            }
                            
                        } catch (exception) {
                            this.logger("Exception encountered in notifier rest event receiver.");
                        }
                    }
                }
            }
        }
    }

    subscribeToApplicationEvent(eventName) {
        // TODO: Future implementation pending
    }

    subscribeToProcessEvent(eventName) {
        // TODO: Future implementation pending
    }

}

export default EventNotifier;