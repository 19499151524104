import moment from "moment/moment";
import {Paper, Typography} from "@mui/material";
import {AlertBanner, Chips, CustomButton} from "@medtronic/pecc-react-component-library-js";
import * as PropTypes from "prop-types";

import {Stack} from "@mui/material";
import {APIContext} from "../../utils/api-context";
import {useContext, useState} from "react";
import * as React from "react";

function RestNotifier({eventMediator, successMessage, warningMessage, failureMessage}) {

    const apiManager = useContext(APIContext);
    const [successBannerMessage, setSuccessBannerMessage] = useState(false);
    const [warningBannerMessage, setWarningBannerMessage] = useState(false);
    const [failureBannerMessage, setFailureBannerMessage] = useState(false);
    const confirmationDialogTimeout_ms = 4000;
    let successColor = "rgb(200,255,200)";
    let warningColor = "rgb(255,255,200)";
    let failureColor = "rgb(255,200,200)";
    let messageColor = warningColor;

    const showSuccess = () => {
        messageColor = successColor;
        setSuccessBannerMessage(successMessage);
        console.log(`Showing success message: ${successMessage}`);
        setTimeout(() => setSuccessBannerMessage(null), confirmationDialogTimeout_ms)
    };

    const showWarning = () => {
        messageColor = warningColor;
        setSuccessBannerMessage(warningMessage);
        console.log(`Showing success message: ${warningMessage}`);
        setTimeout(() => setWarningBannerMessage(null), confirmationDialogTimeout_ms)
    };

    const showFailure = () => {
        messageColor = failureColor;
        setSuccessBannerMessage(failureMessage);
        console.log(`Showing success message: ${failureMessage}`);
        setTimeout(() => setFailureBannerMessage(null), confirmationDialogTimeout_ms)
    };

    eventMediator.listenForEvents(showSuccess, showWarning, showFailure);


    return <Stack>
        {warningBannerMessage && <AlertBanner
            alertMessage={warningBannerMessage}
            alertType="custom"
            customSnackbarColor={successColor}
            bannerType="snackbar"
            snackbarAutoHideDuration={confirmationDialogTimeout_ms}
        />}
        {successBannerMessage && <AlertBanner
            alertMessage={successBannerMessage}
            alertType="custom"
            customSnackbarColor={warningColor}
            bannerType="snackbar"
            snackbarAutoHideDuration={confirmationDialogTimeout_ms}
        />}
        {failureBannerMessage && <AlertBanner
            alertMessage={failureBannerMessage}
            customSnackbarColor={failureColor}
            alertType="custom"
            bannerType="snackbar"
            snackbarAutoHideDuration={confirmationDialogTimeout_ms}
        />}
    </Stack>
}

export default RestNotifier;
