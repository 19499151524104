import BaseEvaluator from '../base-evaluator.js';

export default class FieldComparison extends BaseEvaluator {

    constructor(config) {
      super(config);
      this.name = 'Field Comparison';
      this.sourceField = config.sourceField;
      this.comparator = config.comparator;
      this.comparisonField = config.comparisonField;
    }

    addErrorFields(fieldArray) {
      if (!fieldArray) {
        fieldArray = [];
      }
      if (!fieldArray.includes(this.sourceField)) {
        fieldArray.push(this.sourceField);
      }
      if (!fieldArray.includes(this.comparisonField)) {
        fieldArray.push(this.comparisonField);
      }
      return fieldArray;
    }

    _getSummary() {
      return `The value found in '${this.sourceField}' must be ${this.comparator} to/than ${this.comparisonField}`;
    }

    getTriggerMessage() {
      if (this.comparator === "=") {
        return `${this.sourceField} matches ${this.comparisonField}`;
      } else if (this.comparator === "!=") {
        return `${this.sourceField} does not match ${this.comparisonField}`;
      }
    }

    _evaluate(record, finalRecord) {
      if (!this.sourceField || !record.hasOwnProperty(this.sourceField.trim()) ||
          !this.comparisonField || !record.hasOwnProperty(this.comparisonField.trim())) {
        return {result: false, missingData: true, description: 'Necessary fields not present', fields: this.addErrorFields(null)};
      }
      let value = record[this.sourceField.trim()];
      let compareValue = record[this.comparisonField.trim()];
      if (value.toLowerCase) {
        value = value.toLowerCase();
      }
      if (compareValue.toLowerCase) {
        compareValue = compareValue.toLowerCase();
      }
      if (this.comparator === "=" && value === compareValue) {
        record.errorDetail.push(this.getTriggerMessage());
        this.addErrorFields(record.errorFields);
        return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
      } else if (this.comparator === "!=" && value != compareValue) {
        record.errorDetail.push(this.getTriggerMessage());
        this.addErrorFields(record.errorFields);
        return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
      }
      return {result: false, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
    }

    _perturb(record) {
      if (record[this.sourceField] && record[this.comparisonField]) {
        if (this.comparator === "=") {
          record[this.sourceField] = record[this.comparisonField];
        } else if (this.comparator === "!=") {
          this.perturbValue(record, this.sourceField, true);
        }
        record.perturbed = true;
        const newRecord = JSON.parse(JSON.stringify(record));
        newRecord.perturbed_field = this.sourceField;
        return newRecord;
      }
    }
}
