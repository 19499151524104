import BaseAction from './base-action.js';

export default class ChangeField extends BaseAction {

    constructor(config) {
      super(config);
      this.fieldName = config.fieldName;
      this.newValue = config.newValue;
    }

    takeAction(record) {
      record[this.fieldName] = this.newValue;
    }
}
