import SemanticObject from "./semantic-object";

// Entity Class for 'job_cluster' table
class JobCluster extends SemanticObject {

    constructor() {
        super();
        this.workbench_id = "";
        this.cluster_template = "";
        this.cluster_type = "";
        this.status = "";
        this.current_job_name = "";
        this.current_job_status = "";
        this.log_activity = "";
    }

    setAttributes(json) {
        super.setAttributes(json);

        if (json.workbench_id) {
            if (typeof json.workbench_id === 'string' || json.workbench_id instanceof String) {
              this.workbench_id = parseInt(json.workbench_id)
            } else {
              this.workbench_id = json.workbench_id;
            }
          }

        this.cluster_template = json.cluster_template;
        this.cluster_type = json.cluster_type;
        this.status = json.status;
        this.current_job_name = json.current_job_name;
        this.current_job_status = json.current_job_status;
        this.log_activity = json.log_activity;
    }
}

export default JobCluster;
