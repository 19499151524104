import BaseParentEvaluator from './base-parent-evaluator.js';

export default class OrEvaluator extends BaseParentEvaluator {

    constructor(config) {
      super(config);
      this.name = 'Or';
    }

    _evaluate(record, finalRecord) {
      let results = [];
      let thisResult = {result: false, parent: true, description: this.getSummary(), fields: []};
      for (const key of Object.keys(this.children)) {
        const childEvaluator = this.children[key];
        const result = childEvaluator.evaluate(record, finalRecord);
        this.addFields(thisResult.fields, result.fields);
        if (result && result.result && result.parent) {
          results = results.concat(result.childResults);
        } else {
          results.push(result);
        }
        if (result.missingData) {
          thisResult.missingData = true;
        }
        if (result.badData) {
          thisResult.badData = true;
        }
      }
      thisResult.childResults =  results;
      for (const key of Object.keys(results)) {
        const childResult = results[key];
        if (childResult.result) {
          thisResult.result = true;
          break;
        }
      }
      return thisResult;
    }

    _getSummary() {
      let summary = [];
      for (const child of this.children) {
        summary.push(child.getSummary());
      }
      return summary.join(', OR ');
    }
}
