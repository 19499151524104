export default class BaseAction {

    constructor(config) {

    }

    takeAction(record) {
      throw new Error('#takeAction must be implemented in child class');
    }
}
