import {useContext, useState} from 'react';

import * as PropTypes from "prop-types";
import ProjectEditPane from "./project-edit-pane";
import {CustomButton, Paper, SearchInput, Tabs} from "@medtronic/pecc-react-component-library-js";
import ProjectCard from "../project-card";
import EmptyProject from "./empty-project";
import {Typography, Box, Stack, useTheme} from "@mui/material";
import MoreToExplore from "../more-to-explore";
import {APIContext} from "../../utils/api-context";
import EventMediator from "../../utils/event-mediator";
import RestNotifier from "./rest-notifier";
import * as React from "react";

function ProjectContainer() {
    const {typography, palette} = useTheme();
    const [modalOpen, setModalOpen] = useState(false);
    let [projects, setProjects] = useState([]);
    let [searchText, setSearchText] = useState("");

    const eventsMediator = new EventMediator();
    const apiManager = useContext(APIContext);
    const user = apiManager.getCurrentUser();
    console.log(user);

    projects = apiManager.getProjects();

    if (!Array.isArray(projects)) {
        console.error("Projects array is NOT an array, using empty array");
        setProjects([]);
    }

    console.log(projects);

    function projectCardsContent(filter_func) {
        return <Stack>
            <Stack direction="row" spacing={2} justifyContent="row-start" useFlexGap flexWrap="wrap">
                {projects
                    .filter(project => filter_func(project))
                    .toSorted((a, b) => (a.updated > b.updated ? -1 : 1))
                    .map((project) => <ProjectCard
                        key={`project-${project.id}`}
                        project={project}
                        sx={{
                            width: "33%", lineHeight: "1.25em"
                        }}
                    />)}
            </Stack>
            <MoreToExplore/>
        </Stack>
    }

    function handleSearchChange(event) {
        setTimeout(setSearchText(event.input), 1000);
    }

    function projectIncludes(project, searchTerm) {
        let compareStr = "";
        
        if (project.name != null)
            compareStr += project.name.toLowerCase() + " ";

        if (project.description != null)
            compareStr += project.description.toLowerCase() + " ";

        if (project.type != null)
            compareStr += project.type.toLowerCase() + " ";

        if (project.status != null)
            compareStr += project.status.toLowerCase() + " ";

        if (project.group_name != null)
            compareStr += project.group_name.toLowerCase() + " ";

        if (project.cost_center != null)
            compareStr += project.cost_center.toLowerCase() + " ";

        if (project.created_by != null)
            compareStr += project.created_by.toLowerCase() + " ";

        if (project.updated_by != null)
            compareStr += project.updated_by.toLowerCase();
        
        if (compareStr.includes(searchTerm.trim().toLowerCase()))
            return true;
        else
            return false;
    }
   
    const tabsData = [
        {
            label: "My Projects",
            content: projectCardsContent(project => {
                const userId = user.id;  
            
               // Check if project.users exists and is an array
               const users = Array.isArray(project.users) ? project.users : [];

               // Check if the user is the owner of the project
               const isOwner = users.length > 0 && users[0].user.id === userId;

               // Check if the user is a member of the project (even if not the owner)
               const isMember = users.some(member => member.user.id === userId);
            
                // Combine conditions to only include projects where the user is either an owner or a member
                return (isOwner || isMember) && projectIncludes(project, searchText);
            })
        },
        {
            label: "My OU",
            content: projectCardsContent(project => {
                return (project.operating_unit_id == user.operating_unit_id) & (projectIncludes(project, searchText));
            })
        },
        {
            label: "All",
            content: projectCardsContent(project => {
                return (true & projectIncludes(project, searchText));
            })
        },
        {
            label: "Active",
            content: projectCardsContent(project => {
                return (project.status.toLowerCase() === "active") & (projectIncludes(project, searchText));
            })
        },
        {
            label: "Inactive",
            content: projectCardsContent(project => {
                return (project.status.toLowerCase() === "inactive") & (projectIncludes(project, searchText));
            })
        }
    ];

    return <Box>
        <Box marginRight="3rem">
            <Stack direction="row" justifyContent="space-between" alignItems="baseline">
                <Typography
                    variant="h1">
                    Hi {!!user ? user.first_name : 'Error'}
                </Typography>
                {user.privileges.project.canCreate &&
                    <CustomButton
                        id="new-project"
                        onClick={() => setModalOpen(true)}
                        label="New Project"
                    />
                }
            </Stack>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" marginTop="1rem">
                <Typography variant="body1">Welcome to the Digital Health Platform. Find a project below to begin.</Typography>
                <Box sx={{bgcolor: palette.white.main, borderRadius: "2rem"}}>
                    <SearchInput size={"small"} onChange={handleSearchChange} value={searchText}/>
                </Box>
            </Stack>
        </Box>
        <ProjectEditPane
            project={EmptyProject}
            setProject={setProjects}
            open={modalOpen}
            handleOpen={() => setModalOpen(true)}
            handleClose={() => {
                setModalOpen(false);
                window.location.reload();
            }}
            eventMediator={eventsMediator}
        />
        <Tabs
            isLeftAlign
            disableBottomBorder
            width="100%"
            defaultTab={0}
            tabsData={tabsData}
        />
        <RestNotifier eventMediator={eventsMediator} successMessage={"Project operation successfully"} warningMessage={"Warnings detected, see logs."} failureMessage={"Project operation failed. Try again later."} />
    </Box>;
}

ProjectContainer.propTypes = {
    projects: PropTypes.any
};

export default ProjectContainer;
