export default class DataLoaderService {

    constructor(pullData) {
        this.pullData = pullData;
        this.data = {};
    }

    getData(entityNames) {
        const data = this.pullData();
        const combinedData = [];
        for (let name of entityNames) {
            if (data[name]) {
                combinedData.concat(data[name]);
            } else {
                console.warn(`Data of type ${name} not handled by rule service system, skipping.`)
            }
        }
        return combinedData;
    }
}