import CRUDManager from "./crud-manager";

// CRUD SDK for 'servicenow' operations
class ServiceNowEventManager extends CRUDManager{

    constructor(sessionManager, logger, config, environment) {
        super('ServiceNowEvent', 'servicenowevent', sessionManager, logger, config, environment);
    }

    // Get all events for a ServiceNow ticket
    async getAllEventsByRequestNumber(requestNumber) {
        let headersResult = null;
        try {
            await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at SERVICE NOW EVENT manager in getting headers during get all events by req number");
            throw headersError;
        }
        try {
            return await super.getOne(requestNumber, headersResult);
        } catch (error) {
            this.logger.error("Error encountered at SERVICENOWEVENT manager in retrieving events based on request number");
            return null;
        }
    }
}

export default ServiceNowEventManager;
