import SemanticObject from "./semantic-object";

// Entity Class for 'workbench' table
class Workbench extends SemanticObject {

    constructor() {
        super();
        this.project_id = -1;
        this.workbench_template = "";
        this.keypair_id = "";
        this.instance_type = "t2.micro";
        this.access = "Project";
        this.status = "";
        this.artifacts = "";
    }

    setAttributes(json) {
        super.setAttributes(json);
        if (json.project_id) {
          if (typeof json.project_id === 'string' || json.project_id instanceof String) {
            this.project_id = parseInt(json.project_id)
          } else {
            this.project_id = json.project_id;
          }
        }
        this.workbench_template = json.workbench_template;
        this.keypair_id = json.keypair_id;
        this.instance_type = json.instance_type;
        this.access = json.access;
        this.status = json.status;
        this.artifacts = json.artifacts;
    }
}

export default Workbench;
