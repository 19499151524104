import SemanticObject from "./semantic-object";

// Entity Class for 'project' table
class Project extends SemanticObject {

    constructor() {
        super();
        this.application_id = -1;
        this.operating_unit_id = -1;
        this.type = "";
        this.status = "active";
        this.group_name = "CRM";
        this.cost_center = "";
    }

    setAttributes(json) {
        super.setAttributes(json);
        this.application_id = json.application_id;
        this.operating_unit_id = json.operating_unit_id;
        this.type = json.type;
        this.status = json.status;
        this.group_name = json.group_name;
        this.cost_center = json.cost_center;
    }

    stripNonNewAttributes(json) {
      super.stripNonNewAttributes(json);
      delete json.users;
      delete json.datasets;
      delete json.workbenches;
    }

    stripUpdateAttributes(json) {
      super.stripUpdateAttributes(json);
      delete json.users;
      delete json.datasets;
      delete json.workbenches;
    }
}

export default Project;
