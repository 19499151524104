class EventMediator {

    constructor() {
        this.successCallbackMethod = null;
        this.warningCallbackMethod = null;
        this.failureCallbackMethod = null;

        this.success = () => {
            console.log(`Success triggered in event mediator`);
            if (this.successCallbackMethod) {
                this.successCallbackMethod();
            }
        }

        this.warning = () => {
            console.log(`Warning triggered in event mediator`);
            if (this.warningCallbackMethod) {
                this.warningCallbackMethod();
            }
        }

        this.failure = () => {
            console.log(`Failure triggered in event mediator`);
            if (this.failureCallbackMethod) {
                this.failureCallbackMethod();
            }
        }

        this.listenForEvents = (successCallback, warningCallback, failureCallback) => {
            this.successCallbackMethod = successCallback;
            this.warningCallbackMethod = warningCallback;
            this.failureCallbackMethod = failureCallback;
        }
    }

}

export default EventMediator;