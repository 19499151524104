import RestManager from '../rest-manager';

import EventNotifier from "../event-notifier";
import Environment from '../config/environment';
import ConfigurationManager from "../ops/configurations-manager.js";
import RulesManager from "../ops/rules-manager.js";

// Base class for all Entity Managers
class CRUDManager {

    constructor(entityName, path, sessionManager, logger, config, environment, pullData) {
        this.config = config;
        this.pullData = pullData;
        this.dataCache = {};
        this.dataArray = [];
        this.entityName = entityName;
        this.RESTPath = path;
        this.sessionManager = sessionManager;
        this.logger = logger;
        this.eventNotifier = new EventNotifier(this.logger);
        this.restManager = new RestManager(this.eventNotifier, new Environment(environment));
        if (this.config && this.config[this.entityName] && this.config[this.entityName].fetchEveryMilliseconds) {
            this.fetchEveryMilliseconds = this.config[this.entityName].fetchEveryMilliseconds;
        } else {
            this.fetchEveryMilliseconds = 60000; // Every 60 seconds
        }
        this.lastFetched = null;
        this.subscribedToGetAll = false;
        this.subscribedToGetOne = false;
        this.subscribedToCreate = false;
        this.subscribedToUpdate = false;
        this.subscribedToDelete = false;
        this.getAllSubscribers = {};
        this.getOneSubscribers = {};
        this.createSubscribers = {};
        this.updateSubscribers = {};
        this.deleteSubscribers = {};
        this.processGetOne = (statusCode, data) => {

        }
        this.processGetAll = (statusCode, data) => {

        }
        this.processCreate = (statusCode, data) => {

        }
        this.processUpdate = (statusCode, data) => {

        }
        this.processDelete = (statusCode, data) => {

        }
        this.configurationManager = new ConfigurationManager(sessionManager, logger, environment);
        this.rulesManager = null;
        this.init(pullData);
    }

    setExplicitRules(rules) {
        this.rulesManager.setExplicitRules(rules);
    }

    setExplicitRuleSet(action, rules) {
        this.rulesManager.setExplicitRuleSet(action, rules);
    }

    addRuleResultsListener(callbackFunction, id) {
        if (this.rulesManager) {
            this.rulesManager.addRuleResultsListener(callbackFunction, id)
        }
    }

    async init(pullData){
        console.log(`Fetching CRUD rules for ${this.entityName}...`);
        let rulesDef = {};
        try {
            rulesDef = await this.configurationManager.fetchCRUDRules(this.entityName);
            console.log('Rules received:');
            console.log(rulesDef);
        } catch (exception) {
            this.logger.warn(`Rules not fetched for ${this.entityName}`)
        }
        this.rulesManager = new RulesManager(this.restManager, rulesDef, pullData);
        this.restManager.registerRuleManager(this.rulesManager);
        this.postInit();
    }

    postInit() {
        // To be implemented in child
    }

    getEntityName() {
        return this.entityName;
    }

    getRESTPath() {
        return this.RESTPath;
    }

    getRESTManager() {
        return this.restManager;
    }

    getEndpointRegexPattern(id) {
        let regex = null;
        if (id) {
            regex = new RegExp(this.RESTPath + "\/[0-9]+", "g");
        } else {
            regex = new RegExp(this.RESTPath, "g");
        }
        return regex;
    }

    subscribeToGetAll(handle, callBack) {
        if (!this.subscribedToGetAll){
            const regex = this.getEndpointRegexPattern();
            this.eventNotifier.subscribeToRestEvents(regex, "get", this.processGetAll);
            this.subscribedToGetAll = true;
        }
        this.getAllSubscribers[handle] = callBack;
    }

    subscribeToGetOne(handle, callBack, id) {
        if (!this.subscribedToGetOne){
            const regex = this.getEndpointRegexPattern(id);
            this.eventNotifier.subscribeToRestEvents(regex, "get", this.processGetOne);
            this.subscribedToGetOne = true;
        }
        if (id) {
            this.getOneSubscribers[handle] = {"id": id, theFunction: callBack};
        } else {
            this.getOneSubscribers[handle] = {theFunction: callBack};
        }

    }

    subscribeToCreate(handle, callBack) {
        if (!this.subscribedToCreate){
            const regex = this.getEndpointRegexPattern();
            this.eventNotifier.subscribeToRestEvents(regex, "post", this.processCreate);
            this.subscribedToCreate = true;
        }
        this.createSubscribers[handle] = callBack;
    }

    subscribeToUpdate(handle, callBack, id) {
        if (!this.subscribedToUpdate){
            const regex = this.getEndpointRegexPattern(id);
            this.eventNotifier.subscribeToRestEvents(regex, "put", this.processUpdate);
            this.subscribedToUpdate = true;
        }
        if (id) {
            this.updateSubscribers[handle] = {"id": id, theFunction: callBack};
        } else {
            this.updateSubscribers[handle] = {theFunction: callBack};
        }
    }

    subscribeToDelete(handle, callBack, id) {
        if (!this.subscribedToDelete){
            const regex = this.getEndpointRegexPattern(id);
            this.eventNotifier.subscribeToRestEvents(regex, "delete", this.processDelete);
            this.subscribedToDelete = true;
        }
        if (id) {
            this.deleteSubscribers[handle] = {"id": id, theFunction: callBack};
        } else {
            this.deleteSubscribers[handle] = {theFunction: callBack};
        }
    }

    mustBeFetched() {
        this.logger.debug(this.lastFetched);
        if (!this.lastFetched) {
            this.logger.info(`Data of type ${this.entityName} has not been fetched previously, fetching now.`);
            return true;
        } else {
            const now = new Date().getTime();
            if (this.lastFetched + this.fetchEveryMilliseconds < now) {
                this.logger.info(`Data cache of type ${this.entityName} expired, fetching now.`);
                return true;
            }
        }
    }

    pruneUndefined(records) {
        const pruned = [];
        for (let record of records) {
            if (record != null && record !== undefined && record !== "undefined") {
                pruned.append(record);
            }
        }
        return pruned;
    }

    dataCacheToArray() {
        const allItems = [];
        for (const [key, value] of Object.entries(this.dataCache)) {
            allItems.push(value);
        }
        return allItems;
    }

    // Fetch all values for an entity
    async getAll(authHeaders, onSuccess, onFailure) {
        if (this.mustBeFetched()) {
            let result = null;
            try {
                result = await this.restManager.get(this.RESTPath, authHeaders);
            } catch (exception) {
                if (onFailure) {
                    onFailure(this.getEntityName());
                }
                return null;
            }
            // Insert new rows OR update existing entities in the cache
            if (result) {
                this.dataCache = {};
                this.dataArray = [];
                for (let record of result) {
                    try {
                        const newEntity = this.getNewEntity(record);
                        this.dataArray.push(newEntity);
                        this.dataCache[record.id.toString()] = newEntity;
                    } catch (error) {
                        throw error;
                    }
                }
            }
            this.lastFetched = new Date().getTime();
        }
        const allItems = this.dataCacheToArray();
        if (onSuccess) {
            onSuccess(this.getEntityName());
        }
        return allItems;
    }

    // Fetch one value for an entity based on Id
    async getOne(id, authHeaders) {
        if (this.mustBeFetched()) {
            let result = null;
            try {
                result = await this.restManager.get(this.RESTPath + '/' + id, authHeaders)
                for (let record of result) {
                    const newEntity = this.getNewEntity(record);
                    this.dataCache[id] = newEntity;
                }
            } catch (error) {
                this.logger.error(error.message);
                return null;
            }
            // Rebuild the data array from the data cache
            this.dataArray = [];
            const ids = Object.keys(this.dataCache);
            for (let id of ids) {
                this.dataArray.push(this.dataCache[id]);
            }
        }
        return this.dataCache[id];
    }

    // Create a new value for an entity
    async create(newEntity, authHeaders) {
        const sendingEntity = JSON.parse(JSON.stringify(newEntity));
        newEntity.stripNonNewAttributes(sendingEntity);
        let result = null;
        try {
            result = await this.restManager.post(this.RESTPath, sendingEntity, authHeaders);
        } catch (error) {
            this.logger.error(error.message);
            return null;
        }
        result = result.replace(')', '');
        result = result.replace('(', '');
        result = result.replace(',', '');
        result = result.replace('"', '');
        result = result.replace('"', '');
        const newId = parseInt(result);
        newEntity.id = newId;
        // Insert the new record in the cache
        if (result) {
            this.dataCache[newId] = newEntity;
            this.dataArray.push(newEntity);
        }
        return newId;
    }

    // Create a new value for an entity
    async create_by_payload(payload, authHeaders) {
        const sendingEntity = JSON.parse(JSON.stringify(payload));
        let result = null;
        try {
            result = await this.restManager.post(this.RESTPath, sendingEntity, authHeaders);
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    update_record(updatedEntity) {
        this.dataCache[updatedEntity.id] = updatedEntity;
        let index = 0;
        for (let entity of this.dataArray) {
            if (entity.id === updatedEntity.id) {
                this.dataArray.splice(index, 1, updatedEntity);
            }
            index++;
        }
    }

    // Update the existing value for an entity
    async update(updatedEntity, authHeaders) {
        const sendingEntity = JSON.parse(JSON.stringify(updatedEntity));
        updatedEntity.stripUpdateAttributes(sendingEntity);
        let result = null;
        try {
            result = await this.restManager.put(this.RESTPath + '/' + updatedEntity.id, sendingEntity, authHeaders);
        } catch (error) {
            this.logger.error(error.message);
            return false;
        }
        return true;
    }

    // Delete an existing entity
    async delete(id, authHeaders) {
        let result = null;
        try {
            result = await this.restManager.delete(this.RESTPath + '/' + id, authHeaders);
        } catch (error) {
            this.logger.error(error.message);
            return false;
        }
        delete this.dataCache[id];
        // Now, delete the item from the dataArray
        this.dataArray = []
        const keys = Object.keys(this.dataCache);
        for (let key of keys) {
            let item = this.dataCache[key];
            this.dataArray.push(item);
        }
        return true;
    }
}

export default CRUDManager;
