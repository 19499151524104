import BaseCondition from './base-condition.js';

export default class TrueNumberOfTimes extends BaseCondition {

    constructor(config, action) {
      super(config, action);
        this.truesNeeded = 1;
        this.truesSeen = 0;
      if (config.hasOwnProperty('truesNeeded')) {
        this.truesNeeded = config.truesNeeded;
      }
      this.tripped = false;
    }

    proceed() {
      return true;
    }

    _renderResults() {
      if (this.invert) {
        this.tripped = !this.tripped;
      }
      return {
        result: this.tripped,
        trueResults: this.trueResults,
        falseResults: this.falseResults,
        offendingRecord: this.offendingRecord
      };
    }

    resultAdded(resultSet, ruleEvaluator, record) {
      if (resultSet.result) {
        this.action.takeAction(record);
        this.truesSeen++;
      }
      if (this.truesNeeded === this.truesSeen) {
        this.tripped = true;
        this.offendingRecord = record;
      } else {
        this.tripped = false;
        this.offendingRecord = null;
      }
    }
}
