import {Box, Stack, Typography} from "@mui/material";
import {TrafficCone} from "@carbon/icons-react";

function UnderConstruction() {
    return <Box sx={{padding: "2rem"}}>
        <Stack direction="row" spacing={2} alignItems="baseline">
            <TrafficCone size={32}/>
            <Typography variant={"h2"}>Under Construction</Typography>
        </Stack>
        <Typography>This page is not yet finished, but check back later!</Typography>
    </Box>
}

export default UnderConstruction;
