
export default class RuleProcessingService {

  constructor(evaluatorFactory, actionFactory, conditionFactory, selectorFactory) {
    this.evaluatorFactory = evaluatorFactory;
    this.actionFactory = actionFactory;
    this.conditionFactory = conditionFactory;
    this.selectorFactory = selectorFactory;
  }

  processRuleSets(records, ruleSets) {
    let results = [];
    for (const ruleSetKey of Object.keys(ruleSets)) {
      const ruleSet = ruleSets[ruleSetKey];
      const ruleSetResults = this.processRuleSet(records, ruleSet);
      results = results.concat(ruleSetResults);
    }
    return results;
  }

  countRulesInRuleSets(ruleSets) {
    let count = 0;
    for (const ruleSetKey of Object.keys(ruleSets)) {
      const ruleSet = ruleSets[ruleSetKey];
      const compsInRuleSet = this.countRulesInRuleSet(ruleSet);
      count += compsInRuleSet;
    }
    return count;
  }

  countRulesInRuleSet(ruleSet) {
    let count = 0;
    for (const ruleKey of Object.keys(ruleSet.rules)) {
      const rule = ruleSet.rules[ruleKey];
      const ruleComps = this.countRuleComponentsInRule(rule);
      count += ruleComps;
    }
    return count;
  }

  countRuleComponentsInRule(rule) {
    let count = 0;
    for (const childKey of Object.keys(rule.root.rule_content.content)) {
      count++;
    }
    return count;
  }

  perturbData(records, ruleSets, chance) {
    const componentsCount = this.countRulesInRuleSets(ruleSets);
    const adjustedChance = chance/componentsCount;
    let alteredRecordsSet = [];
    for (const ruleSetKey of Object.keys(ruleSets)) {
      const ruleSet = ruleSets[ruleSetKey];
      const alteredRecords = this.perturbDataByRuleSet(records, ruleSet, adjustedChance);
      alteredRecordsSet = alteredRecordsSet.concat(alteredRecords);
    }
    return alteredRecordsSet;
  }

  perturbDataByRuleSet(records, ruleSet, chance) {
    let alteredRecordsSet = [];
    for (const ruleKey of Object.keys(ruleSet.rules)) {
      const rule = ruleSet.rules[ruleKey];
      const alteredRecords = this.perturbDataByRule(records, rule, chance);
      alteredRecordsSet = alteredRecordsSet.concat(alteredRecords);
    }
    return alteredRecordsSet;
  }

  perturbDataByRule(records, rule, chance) {
    const alteredRecordsSet = [];
    const rootRuleComponent = this.evaluatorFactory.getEvaluator(rule.root.rule_content);
    for (let record of records) {
      const alteredRecord = rootRuleComponent.perturb(record, chance);
      if (alteredRecord) {
        alteredRecordsSet.push(alteredRecord);
      }
    }
    return alteredRecordsSet;
  }

  processRuleSet(records, ruleSet) {
    let results = [];
    let ruleArray = [];
    if (typeof ruleSet.rules === 'object') {
      ruleArray = Object.values(ruleSet.rules);
    } else if (Array.isArray(ruleSet)) {
      ruleArray = ruleSet;
    } else {
      console.log("Rules are not in a format that is digestible.")
    }
    for (let rule of ruleArray) {
      const resultSet = this.processRule(records, rule);
      if (resultSet) {
        for (let result of resultSet) {
          if (ruleSet.title) {
            result.ruleSetName = ruleSet.title;
          } else {
            result.ruleSetName = "unknown";
          }
          result.ruleSetDescription = ruleSet.description;
        }
        results = results.concat(resultSet);
      }
    }
    return results;
  }

  processRule(records, rule) {
    const results = [];
    const rootRuleComponent = this.evaluatorFactory.getEvaluator(rule.root.rule_content);
    let selector = this.selectorFactory.getSelector(rule.selector, records);
    if (!selector) {
      console.warn('No selector found, using SELECT-ALL');
      selector = this.selectorFactory.getSelector({type: "allRecords"}, records);
    }
    let action = this.actionFactory.getAction(rule.action);
    if (!action) {
      console.warn('No action found, using DO-NOTHING');
      action = this.actionFactory.getAction({type: "doNothing"});
    }
    const renderedResults = [];
    let condition = this.getCondition(rule, action);
    while (selector.hasNextRecord()) {
      if (selector.hasNewBatch()) {
        renderedResults.push(this.renderResults(condition, rule, rootRuleComponent));
        condition = this.getCondition(rule, action);
      }
      if (condition.proceed()) {
        let record = selector.nextRecord();
        if (!record.errorDetail) {
          record.errorDetail = [];
        }
        if (!record.errorFields) {
          record.errorFields = [];
        }
        let rootRuleComponentResults = null;
        if (record) {
          rootRuleComponentResults = rootRuleComponent.evaluate(record, selector.hasNextRecord());
        } else {
          console.log("Record not present");
        }
        condition.addResult(rootRuleComponentResults, rootRuleComponent, record);
      } else {
        break;
      }
    }
    renderedResults.push(this.renderResults(condition, rule, rootRuleComponent));
    return renderedResults;
  }

  renderResults(condition, rule, rootRuleComponent) {
    const conditionResults = condition.renderResults();
    conditionResults.ruleName = rule.name;
    conditionResults.ruleDescription = rule.description;
    if (condition.customMessage && condition.customMessage.length > 0) {
      conditionResults.summary = condition.customMessage;
    } else {
      conditionResults.summary = rootRuleComponent.getSummary();
    }
    const namesArray = [];
    this.collectRuleNames(rootRuleComponent, namesArray);
    conditionResults.ruleComponentNames = namesArray;
    conditionResults.ruleName = rule.name;
    return conditionResults;
  }

  getCondition(rule, action) {
    let condition = this.conditionFactory.getCondition(rule.condition, action);
    if (!condition) {
      console.warn('No condition found, using PER-RECORD');
      condition = this.conditionFactory.getCondition({type: "individualRecord"}, action);
    }
    if (rule.invert && rule.invert !== 'false') {
      condition.invert = true;
    }
    return condition;
  }

  collectRuleNames(ruleComponent, namesArray) {
    if (!namesArray) {
      namesArray = [];
    }
    if (ruleComponent.children && ruleComponent.children.length > 0) {
      for (let child of ruleComponent.children) {
        this.collectRuleNames(child, namesArray);
      }
    }
    namesArray.push(ruleComponent.name);
  }
}
