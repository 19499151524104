import BaseEvaluator from '../base-evaluator.js';

export default class TextOccurrence extends BaseEvaluator {

  constructor(config) {
    super(config);
    this.name = 'Text Occurence';
    this.targetField = config.targetField;
    this.term = config.term;
  }

  addErrorFields(fieldArray) {
    if (!fieldArray) {
      fieldArray = [];
    }
    if (!fieldArray.includes(this.targetField)) {
      fieldArray.push(this.targetField);
    }
    return fieldArray;
  }

  _getSummary() {
    return `The text '${this.term}' must be found in '${this.targetField}'`;
  }

  getTriggerMessage() {
    return `The text '${this.term}' was found in '${this.targetField}'`;
  }

  _evaluate(record, finalRecord) {
    if (!this.targetField || !record.hasOwnProperty(this.targetField.trim())) {
      return {result: false, missingData: true, description: 'Necessary fields not present', fields: this.addErrorFields(null)};
    }
    const str = record[this.targetField.trim()];
    const compare = this.term;
    if (str) {
      if (str && (str.toLowerCase().indexOf(compare.toLowerCase()) >= 0 || this.term === 'NOT~NULL')) {
        record.errorDetail.push(this.getTriggerMessage());
        this.addErrorFields(record.errorFields);
        return {result: true, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
      }
    }
    return {result: false, description: this.getTriggerMessage(), fields: this.addErrorFields(null)};
  }

  _perturb(record) {
    if (record[this.targetField]) {
      record[this.targetField] = `${this.getStr(10)} ${this.getStr(10)} ${this.term} ${this.getStr(10)} ${this.getStr(10)}`;
      record.perturbed = true;
      const newRecord = JSON.parse(JSON.stringify(record));
      newRecord.perturbed_field = this.targetField;
      return newRecord;
    }
  }
}
