import Grid from "@mui/material/Unstable_Grid2";
import {LabelAndValue} from "@medtronic/pecc-react-component-library-js";
import {Typography} from "@mui/material";
import {useContext} from "react";
import {APIContext} from "../../utils/api-context";
import Stack from "@mui/material/Stack";
import {titleCase} from "../../utils/text-utils";
import moment from "moment/moment";
function DatasetViewModal({contents: dataset}) {
    const apiManager = useContext(APIContext);

    console.log("Loading dataset viewing modal Grid...")
    console.log(dataset);
    const formattedCreatedDate = moment.utc(dataset.createdText).local().format("MM/DD/YYYY");
    const formattedUpdatedDate = moment.utc(dataset.updatedText).local().format("MM/DD/YYYY");

    const labelColor = "#0000008C";
    const is_parseable = testField => testField && typeof testField !== "object";

    const createdBy = apiManager.appUserManager.dataCache[dataset.created_by];
    const createdByName = Object.keys(apiManager.appUserManager.dataCache).includes(dataset.created_by) ?
        `${createdBy.first_name} ${createdBy.last_name}` :
        dataset.created_by;
    const updatedBy = apiManager.appUserManager.dataCache[dataset.updated_by];
    const updatedByName = Object.keys(apiManager.appUserManager.dataCache).includes(dataset.updated_by) ?
        `${updatedBy.first_name} ${updatedBy.last_name}` :
        dataset.updated_by;
    const printable_size = (dataset.size && typeof dataset.size === "object") ? `${dataset.size.value} ${dataset.size.unit}` : dataset.size;

    const geo_location_tags_printable = is_parseable(dataset.metadata.geo_location_tags) ? JSON.parse(dataset.metadata.geo_location_tags).join(', ') : dataset.metadata.geo_location_tags;
    const storage_location_tags_printable = is_parseable(dataset.metadata.storage_geo_tags) ? JSON.parse(dataset.metadata.storage_geo_tags).join(', ') : dataset.metadata.storage_geo_tags;
    const secondary_data_stewards = is_parseable(dataset.metadata.secondary_data_owners) ? JSON.parse(dataset.metadata.secondary_data_owners).join(', ') : dataset.metadata.secondary_data_owners;

    return <Grid container marginLeft={"2rem"}>
        <Grid item xs={9} marginLeft="1.35rem">
            <Typography variant="subtitle" color="#0C0CA5">Dataset</Typography>
        </Grid>
        <Grid item xs={2} marginLeft="1rem">
            <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="body2">Status:</Typography>
                <Typography variant="body1">{titleCase(dataset.status)}</Typography>
            </Stack>
        </Grid>
        <Grid item xs={12} marginLeft="1rem">
            <Typography variant="h2" color="#0C0CA5">{`${dataset.name}`}</Typography>
        </Grid>
        <Grid item xs={12}>
            <LabelAndValue label="Description" labelColor={labelColor} value={dataset.description}/>
        </Grid>
        <Grid item xs={12}>
            <LabelAndValue label="Provenance" labelColor={labelColor} value={dataset.metadata.provenance}/>
        </Grid>

        <Grid item xs={4}>
            <LabelAndValue label="Data Classification" labelColor={labelColor} value={dataset.data_classification} padding="8px"/>
        </Grid>
        <Grid item xs={4}>
            <LabelAndValue label="Source/Owner OU" labelColor={labelColor} value={dataset.source_ou} padding="8px"/>
        </Grid>
        <Grid item xs={4}>
            <LabelAndValue label="Primary Data Steward" labelColor={labelColor} value={dataset.metadata.data_owner_name} padding="8px"/>
        </Grid>
        <Grid item xs={4}>
            <LabelAndValue label="Size" labelColor={labelColor} value={printable_size} padding="8px"/>
        </Grid>
        <Grid item xs={4} style={{wordWrap: 'break-word'}}>
            <LabelAndValue label="Source Geography" labelColor={labelColor}
            value={geo_location_tags_printable?.length > 0 ? geo_location_tags_printable.join(', ') : geo_location_tags_printable}
            padding="8px"/>
        </Grid>
        <Grid item xs={4} style={{wordWrap: 'break-word'}}>
            <LabelAndValue label="Secondary Data Stewards" labelColor={labelColor} 
            value={secondary_data_stewards?.length > 0 ? secondary_data_stewards.join(', ') : secondary_data_stewards}
            padding="8px"/>
        </Grid>
        <Grid item xs={4}>
            <LabelAndValue label="Type" labelColor={labelColor} value={dataset.type} padding="8px"/>
        </Grid>
        <Grid item xs={4} style={{wordWrap: 'break-word'}}>
            <LabelAndValue label="Storage Geography" labelColor={labelColor} 
            value={storage_location_tags_printable?.length > 0 ? storage_location_tags_printable.join(', ') : storage_location_tags_printable} 
            padding="8px"/>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
            <LabelAndValue label="Data Type" labelColor={labelColor} value={dataset.metadata.data_type ? dataset.metadata.data_type : "Unknown"} padding="8px"/>
        </Grid>
        <Grid item xs={4} style={{wordWrap: 'break-word'}}>
            <LabelAndValue label="Collection Source" labelColor={labelColor} value={dataset.source} padding="8px"/>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
            <LabelAndValue label="Deidentified" labelColor={labelColor} value={dataset.metadata.de_identified ? "Yes" : "No"} padding="8px"/>
        </Grid>
        <Grid item xs={4} style={{wordWrap: 'break-word'}}>
            <LabelAndValue label="Legal Relationship" labelColor={labelColor} value={dataset.metadata.legal_relationship_tag} padding="8px"/>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
            <LabelAndValue label="Mock/Test Dataset" labelColor={labelColor} value={dataset.metadata.is_mock ? "Yes" : "No"} padding="8px"/>
        </Grid>
        <Grid item xs={4}>
            <LabelAndValue label="Legal Relationship Qualifier" labelColor={labelColor} value={dataset.metadata.legal_relationship_qualifier_tag} padding="8px"/>
        </Grid>
        <Grid item xs={4} style={{wordWrap: 'break-word'}}>
            <LabelAndValue label="Restriction Tag" labelColor={labelColor} value={dataset.metadata.restriction_tag} padding="8px"/>
        </Grid>

        <Grid container item xs={12} marginTop="2rem">
            <Grid item xs={3}>
                <LabelAndValue label="Created On" labelColor={labelColor} value={formattedCreatedDate} padding="8px"/>
            </Grid>
            <Grid item xs={3}>
                <LabelAndValue label="Created By" labelColor={labelColor} value={createdByName} padding="8px"/>
            </Grid>
            <Grid item xs={3}>
                <LabelAndValue label="Updated On" labelColor={labelColor} value={formattedUpdatedDate} padding="8px"/>
            </Grid>
            <Grid item xs={3}>
                <LabelAndValue label="Updated By" labelColor={labelColor} value={updatedByName} padding="8px"/>
            </Grid>
        </Grid>
    </Grid>
}

export default DatasetViewModal;
