import SemanticObject from "./semantic-object";


// Entity Class for 'Application'
class OperatingUnit extends SemanticObject {

    constructor() {
        super();
        this.cost_centers = [];
        this.tags = {};
    }

    setAttributes(json) {
        super.setAttributes(json);
        this.type = json.type;
    }
}

export default OperatingUnit;
