import {Box, Stack, Typography} from "@mui/material";
import {Chips, CustomButton} from "@medtronic/pecc-react-component-library-js";
import {useContext, useState} from "react";
import ProjectEditPane from "../page-content-components/project-edit-pane";
import EventMediator from "../../utils/event-mediator";
import RestNotifier from "../page-content-components/rest-notifier";
import Grid from "@mui/material/Unstable_Grid2";
import moment from "moment/moment";
import {APIContext} from "../../utils/api-context";
import * as React from "react";

const DATE_FORMAT = "MM/DD/YYYY";
const OWNER = 12; // Privilege constant for role IDs

function ProjectDetailsPane({project, setProject}) {
    const apiManager = useContext(APIContext);
    const currentUser = apiManager.getCurrentUser();
    const eventMediator = new EventMediator();
    const [modalOpen, setModalOpen] = useState(false);

    console.log(project);

    const creatorUser = apiManager.appUserManager.dataCache[project.created_by];
    const projectOwner = project.users ? project.users.filter(Q => Q.role.id === 12) : null;
    let projectOwnerUser;
    if (projectOwner && projectOwner.length > 0) {
        projectOwnerUser = projectOwner[0].user;
    } else {
        projectOwnerUser = null;
    }
    const sysUserNames = ['system', 'setup'];

    let datasetChipConfig = [
        {
            label: "unknown",
            type: "customSolid",
            customColor: 'info.light',
        }
    ];

    if (project.datasets) {
        datasetChipConfig = project.datasets.map(dataset => ({
            label: dataset.name,
            type: "customSolid",
            customColor: 'info.light',
        }));
    }

    return (currentUser.privileges.project.canView ?
            <>
                <ProjectEditPane
                    project={project}
                    setProject={setProject}
                    open={modalOpen}
                    handleOpen={() => setModalOpen(true)}
                    handleClose={() => setModalOpen(false)}
                    eventMediator={eventMediator}
                />
                <Grid container spacing={2} width="100%" alignItems="bottom" sx={{padding: "3rem"}}>
                    <Grid xs={2}>
                        <Typography variant="h4" component="p">Name:</Typography>
                    </Grid>
                    <Grid xs={10}>
                        <Typography variant="h4" component="p">{project.name}</Typography>
                    </Grid>
                    <Grid xs={2}>
                        <Typography variant="h4" component="p">Project Description:</Typography>
                    </Grid>
                    <Grid xs={10}>
                        <Typography variant="body1" component="p" sx={{"whiteSpace": "pre-wrap"}}>{project.description}</Typography>
                    </Grid>
                    <Grid xs={2}>
                        <Typography variant="h4" component="p">Project Status:</Typography>
                    </Grid>
                    <Grid xs={4} display="flex" justifyContent="left">
                        <Chips chipsConfig={[{
                            label: project.status.charAt(0).toUpperCase() + project.status.slice(1),
                            type: "confirm"
                        }]}/>
                    </Grid>
                    <Grid xs={2}>
                        <Typography variant="h4" component="p">Created By:</Typography>
                    </Grid>
                    <Grid xs={4} display="flex" justifyContent="left">
                        <Typography variant="body1" component="p">
                            {`${sysUserNames.includes(project.created_by) ? project.created_by :
                                `${creatorUser.first_name} ${creatorUser.last_name}`}`}
                        </Typography>
                    </Grid>
                    <Grid xs={2}>
                        <Typography variant="h4" component="p">Project Members:</Typography>
                    </Grid>
                    {!!project.roster && <Grid xs={4} display="flex" justifyContent="left">
                        <Chips chipsConfig={[{
                            label: `${project.roster.length} members`,
                            type: "informative"
                        }]}/>
                    </Grid>}
                    <Grid xs={2}>
                        <Typography variant="h4" component="p">Owner:</Typography>
                    </Grid>
                    <Grid xs={4} display="flex" justifyContent="left">
                        <Typography variant="body1" component="p">
                            {projectOwnerUser ? `${projectOwnerUser.first_name} ${projectOwnerUser.last_name}` : "No Owner"}
                        </Typography>
                    </Grid>
                    <Grid xs={2}>
                        <Typography variant="h4" component="p">Created On:</Typography>
                    </Grid>
                    <Grid xs={4} display="flex" justifyContent="left">
                        <Typography variant="body1"
                                    component="p">{moment.utc(project.created).local().format(DATE_FORMAT)}</Typography>
                    </Grid>
                    <Grid xs={2}>
                        <Typography variant="h4" component="p">Associated Datasets:</Typography>
                    </Grid>
                    <Grid xs={4}>
                        <Chips alignment="left" chipsConfig={datasetChipConfig}/>
                    </Grid>
                    <Grid xs={2}>
                        <Typography variant="h4" component="p">Last Modified:</Typography>
                    </Grid>
                    <Grid xs={4} display="flex" justifyContent="left">
                        <Typography variant="body1"
                                    component="p">{moment.utc(project.updated).local().format(DATE_FORMAT)}</Typography>
                    </Grid>
                </Grid>
                <Stack direction="row" useFlexGap flexWrap="wrap" spacing={3} sx={{padding: "3rem"}}>
                    {currentUser.privileges.project.canUpdate &&
                        project.users.map(u => u.user.id).includes(currentUser.id) &&
                        currentUser.projectRoles[project.id].role_id <= OWNER &&
                        <CustomButton
                            buttonType="outlined"
                            label="Edit"
                            onClick={() => setModalOpen(true)}
                            disabled={project.status.toLowerCase() !== "active"}
                        />
                    }
                </Stack>
                <RestNotifier eventMediator={eventMediator} successMessage={"Project operation successfully"} warningMessage={"Warnings detected, see logs."} failureMessage={"Project operation failed. Try again later."} />
            </>
            :
            <>
                <Typography variant="h2">I can't let you do that, <s>Dave</s> - I mean, {currentUser.first_name}.</Typography>
                <Typography variant="h4">You do not have permissions to view this project.</Typography>
            </>
    )
}

export default ProjectDetailsPane;
