import CRUDManager from "./crud-manager";
import DataviewRole from "./dataview-role";

// CRUD SDK for 'dataviewRole' table
class DataviewRoleManager extends CRUDManager{

    constructor(sessionManager, logger, config, environment, pullData) {
        super('DATAVIEW_ROLE', 'dataview-role', sessionManager, logger, config, environment, pullData);
    }

    getNewEntity(json) {
        const newEntity = new DataviewRole();
        newEntity.setAttributes(json);
        return newEntity;
    }

    // CREATE dataviewRole
    async createDataviewRole(newDataviewRole) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at DATAVIEW ROLE manager in getting headers during create role");
            throw headersError;
        }
        let result = null;
        try {
            result = await super.create(newDataviewRole, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // UPDATE dataviewRole
    async updateDataviewRole(updatedDataviewRole) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at DATAVIEW ROLE manager in getting headers during update role");
            throw headersError;
        }
        let result = null;
        try {
            await super.update(updatedDataviewRole, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // DELETE dataviewRole based on id
    async deleteDataviewRole(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at DATAVIEW ROLE manager in getting headers during delete role");
            throw headersError;
        }
        let result = null;
        try {
            await super.delete(id, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // GET ALL dataviewRoles
    async getAllDataviewRoles(onSuccess, onFailure) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at DATAVIEW ROLE manager in getting headers during get all roles");
            throw headersError;
        }
        let allEntities = [];
        try {
            let result = await super.getAll(headersResult, onSuccess, onFailure);
            for (let record of result) {
                let entity = new DataviewRole();
                entity.setAttributes(record);
                allEntities.push(entity);
            }
        } catch (error) {
            this.logger.error(error.message);
        }
        return allEntities;
    }

    // GET a dataviewRole based on Id
    async getDataviewRole(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at DATAVIEW ROLE manager in getting headers during get role");
            throw headersError;
        }
        try {
            let result = await super.getOne(id, headersResult);
            return result;
        } catch (error) {
            this.logger.error(error.message);
            return null;
        }
    }
}

export default DataviewRoleManager;
