import CRUDManager from "./crud-manager";
import DatasetRole from "./dataset-role";

// CRUD SDK for 'datasetRole' table
class DatasetRoleManager extends CRUDManager{

    constructor(sessionManager, logger, config, environment, pullData) {
        super('DATASET_ROLE', 'dataset-role', sessionManager, logger, config, environment, pullData);
    }

    getNewEntity(json) {
        const newEntity = new DatasetRole();
        newEntity.setAttributes(json);
        return newEntity;
    }

    // CREATE datasetRole
    async createDatasetRole(newDatasetRole) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at DATASET ROLE manager in getting headers during create role");
            throw headersError;
        }
        let result = null;
        try {
            result = await super.create(newDatasetRole, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // UPDATE datasetRole
    async updateDatasetRole(updatedDatasetRole) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at DATASET ROLE manager in getting headers during update role");
            throw headersError;
        }
        let result = null;
        try {
            await super.update(updatedDatasetRole, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // DELETE datasetRole based on id
    async deleteDatasetRole(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at DATASET ROLE manager in getting headers during delete role");
            throw headersError;
        }
        let result = null;
        try {
            await super.delete(id, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // GET ALL datasetRoles
    async getAllDatasetRoles(onSuccess, onFailure) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at DATASET ROLE manager in getting headers during get all roles");
            throw headersError;
        }
        let allEntities = [];
        try {
            let result = await super.getAll(headersResult, onSuccess, onFailure);
            for (let record of result) {
                let entity = new DatasetRole();
                entity.setAttributes(record);
                allEntities.push(entity);
            }
        } catch (error) {
            this.logger.error(error.message);
        }
        return allEntities;
    }

    // GET a datasetRole based on Id
    async getDatasetRole(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at DATASET ROLE manager in getting headers during get role");
            throw headersError;
        }
        try {
            let result = await super.getOne(id, headersResult);
            return result;
        } catch (error) {
            this.logger.error(error.message);
            return null;
        }
    }
}

export default DatasetRoleManager;
