import RoleRelation from "./role-relation";

class ApplicationRole extends RoleRelation {

    constructor() {
        super();
        this.application_id = -1;
    }

    setAttributes(json) {
        super.setAttributes(json);
        this.application_id = json.application_id;
    }
}

export default ApplicationRole;
