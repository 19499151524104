import CRUDManager from "./crud-manager";
import Application from "./application";

// CRUD SDK for 'application' table
class ApplicationManager extends CRUDManager{

    constructor(sessionManager, logger, config, environment, pullData) {
        super('Application', 'application', sessionManager, logger, config, environment, pullData);
    }

    getNewEntity(json) {
        const newEntity = new Application();
        newEntity.setAttributes(json);
        return newEntity;
    }

    // CREATE application
    async createApplication(newApplication) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at USER manager in getting headers during create application");
            throw headersError;
        }
        let result = null;
        try {
            result = await super.create(newApplication, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // UPDATE application
    async updateApplication(updatedApplication) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at USER manager in getting headers during update application");
            throw headersError;
        }
        let result = null;
        try {
            await super.update(updatedApplication, headersResult);
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // DELETE application based on id
    async deleteApplication(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at USER manager in getting headers during delete application");
            throw headersError;
        }
        let result = null;
        try {
            await super.delete(id, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // GET ALL applications
    async getAllApplications(onSuccess, onFailure) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at USER manager in getting headers during get all applications");
            throw headersError;
        }
        let allEntities = [];
        try {
            let result = await super.getAll(headersResult, onSuccess, onFailure);
            for (let record of result) {
                let entity = new Application();
                entity.setAttributes(record);
                allEntities.push(entity);
            }
        } catch (error) {
            this.logger.error(error.message);
        }
        return allEntities;
    }

    // GET a application based on Id
    async getApplication(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at APPLICATION manager in getting headers during get application");
            throw headersError;
        }
        try {
            let result = await super.getOne(id, headersResult);
            return result;
        } catch (error) {
            this.logger.error(error.message);
            return null;
        }
    }
}

export default ApplicationManager;
