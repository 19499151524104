import CRUDManager from "./crud-manager";
import DatasetProjectRelation from "./dataset-project-relation";
import CompatibleDatasetsRule from "../rules/explicit-rules/compatible-datasets.rule";
import NewProjectDatasetRule from "../rules/explicit-rules/new-project-dataset.rule";

// CRUD SDK for 'datasetProjectRelation' table
class DatasetProjectRelationManager extends CRUDManager{

    constructor(sessionManager, logger, config, environment, pullData) {
        super('DATASET_PROJECT_RELATION', 'dataset-project-rel', sessionManager, logger, config, environment, pullData);
    }

    postInit() {
        const explicitRules = [
            new NewProjectDatasetRule(this.pullData, this.logger)
        ];
        this.setExplicitRuleSet('newDataset', explicitRules);
    }

    getNewEntity(json) {
        const newEntity = new DatasetProjectRelation();
        newEntity.setAttributes(json);
        return newEntity;
    }

    // CREATE datasetProjectRelation
    async createDatasetProjectRelation(newDatasetProjectRelation) {
        let headersResult = null;
        const explicitRules = [
            new CompatibleDatasetsRule(this.pullData, this.logger),
            new NewProjectDatasetRule(this.pullData, this.logger)
        ];
        this.setExplicitRules(explicitRules);
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at DATASET PROJECT RELATION manager in getting headers during create relation");
            throw headersError;
        }
        let result = null;
        try {
            result = await super.create(newDatasetProjectRelation, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // UPDATE datasetProjectRelation
    async updateDatasetProjectRelation(updatedDatasetProjectRelation) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at DATASET PROJECT RELATION manager in getting headers during update relation");
            throw headersError;
        }
        let result = null;
        try {
            result = await super.update(updatedDatasetProjectRelation, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // DELETE datasetProjectRelation based on id
    async deleteDatasetProjectRelation(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at DATASET PROJECT RELATION manager in getting headers during delete relation");
            throw headersError;
        }
        let result = null;
        try {
            await super.delete(id, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // GET ALL datasetProjectRelations
    async getAllDatasetProjectRelations(onSuccess, onFailure) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at DATASET PROJECT RELATION manager in getting headers during get all relations");
            throw headersError;
        }
        let allEntities = [];
        try {
            let result = await super.getAll(headersResult, onSuccess, onFailure);
            for (let record of result) {
                let entity = new DatasetProjectRelation();
                entity.setAttributes(record);
                allEntities.push(entity);
            }
        } catch (error) {
            this.logger.error(error.message);
        }
        return allEntities;
    }

    // GET a datasetProjectRelation based on Id
    async getDatasetProjectRelation(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at DATASET PROJECT RELATION manager in getting headers during get relation");
            throw headersError;
        }
        try {
            let result = await super.getOne(id, headersResult);
            return result;
        } catch (error) {
            this.logger.error(error.message);
            return null;
        }
    }
}

export default DatasetProjectRelationManager;
