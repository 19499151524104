import {
    Box,
    Button,
    Grid,
    MenuItem,
    Paper,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import {useContext, useState} from "react";
import to from 'await-to-js';

import {APIContext} from "../../utils/api-context";
import Workbench from "../../mdt-lib/hdp-client-sdk/orm/workbench"
import {AlertBanner, CustomButton, Dropdown, InputField} from "@medtronic/pecc-react-component-library-js";
import EventMediator from "../../utils/event-mediator";
import RestNotifier from "../page-content-components/rest-notifier";

function WorkbenchView({setAlertMessage, refreshFn, closeFn, eventMediator, refresh_btn}) {
    const {projectid} = useParams();
    const apiManager = useContext(APIContext);
    const [project, setProject] = useState({name: "NAME"});
    const [submitState, setSubmitState] = useState("editing");
    const [workbenchGroup, setWorkbenchGroup] = useState();

    const template_options_array = [
        {
            label: "EC2-Based Workbenches",
            value: "Default"
        }
    ];
    const template_options_dataCache = {
            Default: {
                label: "EC2-Based Workbenches",
                value: "Default"
            }
    };
    const workbenchEntries = apiManager.configurationsManager.getWorkbenchEntries();
    const workbenchGroups = apiManager.configurationsManager.getWorkbenchGroups();
    let workbench_type_options_array = [];

    const workbenchGroupIndex = {};
    let workbench_index = {};
    for (let entry of workbenchEntries) {
        workbench_type_options_array.push(
            {
                label: entry.name,
                value: entry.id
            }
        )
        workbench_index[entry.id] = entry;
    }
    workbenchGroups.forEach(group => (group in workbenchGroupIndex ? workbenchGroupIndex[group].push(apiManager.configurationsManager.getWorkbenchIdsByGroup(group.name)) : [apiManager.configurationsManager.getWorkbenchIdsByGroup(group)]))
    const [protoworkbench, setProtoworkbench] = useState({
        "access": "",
        "name": "",
        "description": "",
        "project_id": parseInt(projectid),
        "workbench_template": template_options_array[0].value,
        "keypair_id": "",
        "instance_type": null,
        "status": "active",
        "metadata": {},
        "artifacts": {}
    });

    if (project.name === "NAME") {
        setProject(apiManager.projectManager.dataCache[projectid]);
    }

    function handleError(err) {
        alert(err); // Should later turn into a toast and/or console log
        setSubmitState("editing");
    }

    async function onSubmit(event) {
        event.preventDefault();
       
        if (protoworkbench.name && protoworkbench.description && protoworkbench.instance_type) {
            setSubmitState("submitting");
            console.log('Submitting workbench');
            console.log(protoworkbench);
            const newWorkbench = new Workbench();
            newWorkbench.setAttributes(protoworkbench);
            const [createError, createResult] = await to(apiManager.workbenchManager.createWorkbench(newWorkbench));
            if (createError) {
                eventMediator.failure();
                handleError(createError);
            } else if (createResult) {
                eventMediator.success();
                console.log(`Workbench created with id ${createResult}`);
                apiManager.workbenchManager.getWorkbench(createResult).then(resp => {
                    console.log(resp);
                    // refreshFn();
                    closeFn();
                      // Call refresh_btn after a delay
                    setTimeout(() => {
                        refresh_btn();
                    }, 5555); 
                   })
            } else {
                eventMediator.failure();
            }
        } else {
            alert("Please fill in all required fields.");
        }
    }

    return <Box sx={{margin: "auto", maxWidth: "60vw", padding: "1rem 3rem 0 3rem"}}>
        <Typography variant="body2">
            {`New Workbench${project.name === "NAME" ? "" : " for project \"" + project.name}"`}
        </Typography>
        <form id="project-form" onSubmit={onSubmit}>
            <Grid container spacing={2} style={{"marginTop": "1px"}}>
                <Grid item xs={12}>
                    <Stack spacing={1}>
                        <InputField
                            label="Workbench Name"
                            value={protoworkbench.name}
                            onChange={newValue => setProtoworkbench({...protoworkbench, name: newValue})}
                            isRequired
                            width="100%"
                            pattern={"[^'\"]+"}
                        />
                        {/['"]/.test(protoworkbench.name) && <AlertBanner
                            alertMessage="Single and double quotation marks are not allowed in workbench names."
                            alertType="caution" bannerType="notice"/>}
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <InputField
                        label="Description"
                        value={protoworkbench.description}
                        onChange={newValue => setProtoworkbench({...protoworkbench, description: newValue})}
                        isRequired
                        width="100%"
                        pattern={".*"}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Dropdown dropdownOptions={template_options_array}
                              label="Workbench Template"
                              value={{
                                  optionLabel: template_options_dataCache[protoworkbench.workbench_template].label,
                                  optionValue: template_options_dataCache[protoworkbench.workbench_template].value,
                                  groupLabel: ""
                              }}
                              onChange={newValue => setProtoworkbench({...protoworkbench, workbench_template: newValue.optionValue})}
                              width="100%"
                              disabled
                    />
                </Grid>
                <Grid item xs={4}>
                    <Dropdown isRequired dropdownOptions={workbenchGroups.map(type => ({
                        optionLabel: type,
                        optionValue: type,
                        groupLabel: ""
                    }))}
                              label="Workbench Group"
                              onChange={event => {
                                  if (event && "optionValue" in event) {
                                      console.log(`Workbench group changed to: "${event.optionValue}"`);
                                      setWorkbenchGroup(event.optionValue);
                                  } else {
                                      console.log(`Workbench group changed to: "${event}"`);
                                      setWorkbenchGroup(event);
                                  }
                              }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Dropdown isRequired dropdownOptions={apiManager.configurationsManager.getWorkbenchIdsByGroup(workbenchGroup)
                        .map(option => {
                            const workbenchEntry = workbenchEntries.filter(en => en.id === option)[0]
                            return ({
                            optionLabel: workbenchEntry.name,
                            optionValue: workbenchEntry.id,
                            groupLabel: workbenchEntry.workbenchGroup
                        })})}
                              label="Instance Type"
                              value={workbench_index[protoworkbench.instance_type]}
                              onChange={newValue => {
                                  if (newValue && "optionValue" in newValue) {
                                      setProtoworkbench({...protoworkbench, instance_type: newValue.optionValue})
                                  } else {
                                      setProtoworkbench({...protoworkbench, instance_type: newValue});
                                  }
                              }}
                              disabled={!workbenchGroup}
                    />
                </Grid>
                {protoworkbench.instance_type && <Grid item xs={12}>
                    <Paper width="100%" sx={{"whiteSpace": "pre-wrap", "padding": "2rem"}}>
                        <Typography variant="h4">
                            {workbench_index[protoworkbench.instance_type].name}
                        </Typography>
                        <hr/>
                        <Typography variant="body1">
                            {workbench_index[protoworkbench.instance_type].description.replace(/->/g, '')}
                        </Typography>
                    </Paper>
                </Grid>}
            </Grid>
            <Stack spacing={2} direction="row-reverse" sx={{marginTop: "2rem"}}>
                <CustomButton buttonType="loading" type="submit" form="project-form"
                              isLoading={submitState !== "editing"}
                              label={submitState === "editing" ? "Submit" : submitState}/>
                <CustomButton buttonType="outlined" onClick={closeFn}>Close</CustomButton>
            </Stack>
        </form>
    </Box>
}

export default WorkbenchView;
