import SemanticObject from "./semantic-object";


// Entity Class for 'Application'
class Application extends SemanticObject {

    constructor() {
        super();
        this.type = ""
    }

    setAttributes(json) {
        super.setAttributes(json);
        this.type = json.type;
    }
}

export default Application;
