import CRUDManager from "./crud-manager";
import AppUser from "./app-user";

// CRUD SDK for 'AppUser' entity
class AppUserManager extends CRUDManager{

    constructor(sessionManager, logger, config, environment, pullData) {
        super('AppUser', 'appuser', sessionManager, logger, config, environment, pullData);
    }

    getNewEntity(json) {
        const newEntity = new AppUser();
        newEntity.setAttributes(json);
        return newEntity;
    }

    // Create a new user
    async createUser(newUser) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at APPUSER manager in getting headers during create user");
            throw headersError;
        }
        let result = null;
        try {
            result = await super.create(newUser, headersResult);
        } catch (error) {
            this.logger.error("ERROR encountered during create user");
            return [];
        }
        return result;
    }

    // Update existing user
    async updateUser(updatedUser) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at USER manager in getting headers during update user");
            throw headersError;
        }
        let result = null;
        try {
            await super.update(updatedUser, headersResult);
        } catch (error) {
            this.logger.error("ERROR encountered during update user");
            return [];
        }
        return result;
    }

    // Soft-Delete existing user
    async deleteUser(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at USER manager in getting headers during delete user");
            throw headersError;
        }
        let result = null;
        try {
            result = await super.delete(id, headersResult);
        } catch (error) {
            this.logger.error(error.message);
            return [];
        }
        return result;
    }

    // Fetch all users
    async getAllUsers(onSuccess, onFailure) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at USER manager in getting headers during get all users");
            throw headersError;
        }
        let result = null;
        let allUsers = [];
        try {
            result = await super.getAll(headersResult, onSuccess, onFailure);
            for (let user of result) {
                let newUser = new AppUser();
                newUser.setAttributes(user);
                allUsers.push(newUser);
            }
        } catch (error) {
            this.logger.error(error.message);
            return [];
        }
        return allUsers;
    }

    // Fetch a single user based on Id
    async getUser(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at USER manager in getting headers during get user");
            throw headersError;
        }
        let result = null;
        try {
            result = await super.getOne(id, headersResult);
        } catch (error) {
            this.logger.error(error.message);
            return [];
        }
        return result;
    }

    validateForCreation(appUser) {

    }

    validateForUpdate(appUser) {
        this.getCreatable();
    }

    stripNonNewAttributes(appUser) {

    }

    stripNonUpdateAttributes(appUser) {

    }

    getCreatable() {
        const appUser = new AppUser();
        this.stripNonNewAttributes(appUser);
        return appUser;
    }
}

export default AppUserManager;
