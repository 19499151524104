import HDPEntity from "./hdp-entity";

// Base class for all entities
class SemanticObject extends HDPEntity {

    constructor() {
        super();
        this.name = "";
        this.description = "";
        this.metadata = {};
    }

    stripNonNewAttributes(json) {
      super.stripNonNewAttributes(json);
    }

    stripUpdateAttributes(json) {
      super.stripUpdateAttributes(json);
    }

    // Attributes present in all entities encapsulated in base class
    setAttributes(json) {
      super.setAttributes(json);
      this.name = json.name;
      this.description = json.description;
      if (typeof json.metadata === "string") {
        this.metadata = JSON.parse(json.metadata);
      } else {
        this.metadata = json.metadata;
      }
    }
}

export default SemanticObject;
