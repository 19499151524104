import BaseCondition from './base-condition.js';

export default class AlwaysTrue extends BaseCondition {

    constructor(config, action) {
      super(config, action);
    }

    proceed() {
      if (this.tripped) {
        return true;
      }
      return false;
    }

    _renderResults() {
      if (this.invert) {
        this.tripped = !this.tripped;
      }
      return {
        result: this.tripped,
        trueResults: this.trueResults,
        falseResults: this.falseResults,
        offendingRecord: this.offendingRecord
      };
    }

    resultAdded(resultSet, ruleEvaluator, record) {
      if (!resultSet.result) {
        this.tripped = true;
        this.offendingRecord = record;
        this.action.takeAction(record);
      }
    }
}
