import SemanticObject from "./semantic-object";

// Entity Class for 'dataset' table
class Artifact extends SemanticObject {

    constructor() {
        super();
        this.project_id = -1;
        this.data_view_id = -1;
        this.type = "";
        this.location = "";
    }

    setAttributes(json) {
        super.setAttributes(json);
        this.project_id = json.project_id;
        this.data_view_id = json.data_view_id;
        this.type = json.type;
        this.location = json.location;
    }
}

export default Artifact;
