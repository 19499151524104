import RestManager from "../rest-manager";
import Environment from "../config/environment";
import EventNotifier from '../event-notifier';

// SDK for Healthcheck API call
class OperationsManager {

    constructor(sessionManager, logger, environment) {
        this.sessionManager = sessionManager;
        this.logger = logger;
        this.eventNotifier = new EventNotifier(this.logger);
        this.restManager = new RestManager(this.eventNotifier, new Environment(environment));
    }

    // Call Healthcheck API
    async getSystemStatus() {
        let headersResult = null;
        try {
          headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
          this.logger.error("Error encountered at CONFIGURATIONS manager in getting headers during get configurations");
          throw headersError;
        }
        try {
            let result = await this.restManager.get(this.restManager.get('healthcheck', headersResult));
            return result;
        } catch (error) {
            this.logger.error("Error encountered at ops manager in getting system status");
            throw error;
        }
    }
}

export default OperationsManager;
