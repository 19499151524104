import SemanticObject from "./semantic-object";

// Entity Class for 'DataView'
class DataView extends SemanticObject {

    constructor() {
        super();
        this.type = "";
        this.filter = "";
        this.status = "";
        this.dataset_id = 0;
        this.project_id = 0;
    }

    setAttributes(json) {
        super.setAttributes(json);
        this.type = json.type;
        this.filter = json.filter;
        this.status = json.status;
        this.dataset_id = json.dataset_id;
        this.project_id = json.project_id;
    }
}

export default DataView;
