import {Navigation, PageContainer, AlertBanner} from "@medtronic/pecc-react-component-library-js";
import {Outlet} from "react-router-dom";
import {useContext, useState} from 'react';

import {APIContext} from "../utils/api-context";

import {CatalogPublish, DashboardReference, Settings,} from '@carbon/icons-react';

import Box from "@mui/material/Box";
import FeedbackButton from "./page-content-components/feedback-button";

import Environment from "../config/environment";

function Layout() {

    const [rulesNotificationMessage, setRulesNotificationMessage] = useState();
    const environment = new Environment();
    const apiManager = useContext(APIContext);
    const user = apiManager.getCurrentUser();

    const ruleTriggered = (finding) => {
      console.log("****** RULE FAILED ******");
      console.log(finding);
      setRulesNotificationMessage(finding.message);
    }

    // apiManager.workbenchManager.addRuleResultsListener(ruleTriggered, "MainPanel");
    // apiManager.datasetManager.addRuleResultsListener(ruleTriggered, "MainPanel");
    // apiManager.projectManager.addRuleResultsListener(ruleTriggered, "MainPanel");
    // apiManager.appUserManager.addRuleResultsListener(ruleTriggered, "MainPanel");
    // apiManager.dataViewManager.addRuleResultsListener(ruleTriggered, "MainPanel");
    // apiManager.applicationManager.addRuleResultsListener(ruleTriggered, "MainPanel");
    // apiManager.dataViewManager.addRuleResultsListener(ruleTriggered, "MainPanel");
    // apiManager.jobClusterManager.addRuleResultsListener(ruleTriggered, "MainPanel");
    // apiManager.operatingUnitManager.addRuleResultsListener(ruleTriggered, "MainPanel");
    // apiManager.roleManager.addRuleResultsListener(ruleTriggered, "MainPanel");
    // apiManager.workbenchManager.addRuleResultsListener(ruleTriggered, "MainPanel");
    // if (apiManager && apiManager.datasetProjectRelationManager) {
    //     apiManager.datasetProjectRelationManager.addRuleResultsListener(ruleTriggered, "MainPanel");
    // }
    // apiManager.datasetRoleManager.addRuleResultsListener(ruleTriggered, "MainPanel");
    // if (apiManager && apiManager.projectRoleManager) {
    //     apiManager.projectRoleManager.addRuleResultsListener(ruleTriggered, "MainPanel");
    // }
    // apiManager.dataviewRoleManager.addRuleResultsListener(ruleTriggered, "MainPanel");
    // apiManager.datasetProjectRelationManager.addRuleResultsListener(ruleTriggered, "MainPanel");
    // apiManager.datasetRoleManager.addRuleResultsListener(ruleTriggered, "MainPanel");
    // apiManager.projectRoleManager.addRuleResultsListener(ruleTriggered, "MainPanel");
    // apiManager.dataviewRoleManager.addRuleResultsListener(ruleTriggered, "MainPanel");
    // console.log(user)
    const userProfileMenuConfig = [
        {
            title: "Log out",
            url: "/logout"
            //url: environment.settings.auth.signOutURL
        }
    ]

    const leftNavConfig = [
        {
            icon: <DashboardReference/>,
            label: "Project Dashboard",
            url: '/projects',
            showSubMenu: false,
           
        },
        {
            icon: <CatalogPublish/>,
            label: "Data Catalog",
            url: "/data-catalog"
        }
    ]

    return <>
    <Box 
     sx={{
        position: 'fixed', 
        top: 0, 
        left: 0, 
        height: '100vh', 
        
    }}>
        <Navigation
            appName="Healthcare Data Platform"
            isWithSearchBar={false}
            isWithNotification={false}
            firstName={!!user ? user.first_name : "Error"}
            lastName={!!user ? user.last_name : "Error"}
            userProfileMenuConfig={userProfileMenuConfig}
            leftNavConfig={leftNavConfig}
        />
        
        <PageContainer style={{overflowX: "hidden"}}>
            <Box sx={{margin: "2rem", width: "80vw"}}>
                <Outlet/>
                <FeedbackButton/>
                {rulesNotificationMessage && <AlertBanner
                    alertMessage={rulesNotificationMessage}
                    alertType="caution"
                    bannerType="notice"
                    onActionClick={() => setRulesNotificationMessage(null)}
                    actionButtonLabel="Dismiss"
                />}
            </Box>

        </PageContainer>
       </Box>
    </>
}

export default Layout;
