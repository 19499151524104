import {useState} from 'react';
import Cookies from 'js-cookie';
import Environment from "../../config/environment";

function LogoutDisplay () {
    console.log("Logout Functionality Working...");
    const environment = new Environment();
    const domain = window.location.hostname;
    Cookies.remove(environment.getCookieName() + "-access", { path: '/'});
    Cookies.remove(environment.getCookieName() + "-id", { path: '/'});
    Cookies.remove(environment.getCookieName() + "-refresh", { path: '/'});
    Cookies.remove(environment.getCookieName() + "-expires", { path: '/'});

    setTimeout(() => {
      window.location.href = environment.settings.auth.signOutURL;
    }, 3000);
    return <>
        <div style={{width: '100%', height: '50vh'}}>
          <div id="loading-spinner-container" class="loading-spinner-container" style={{"font-family": "Avenir Next World", width:"100%", height:"100%", "text-align":"center", "margin-top":"20rem"}}>
            <div><h3>Logging Out...</h3></div>
            <img src="/spinner.gif" height="100px" width="10rem" />
          </div>
        </div>
    </>
}

export default LogoutDisplay;
