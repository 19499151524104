import BaseSelector from './base-selector.js';

export default class EachRecordSelector extends BaseSelector {

    constructor(config, records) {
      super(config, records);
    }

    hasNewBatch() {
      return true;
    }
}
