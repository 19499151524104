import CRUDManager from "./crud-manager";
import Role from "./role";

// CRUD SDK for 'role' table
class RoleManager extends CRUDManager{

    constructor(sessionManager, logger, config, environment, pullData) {
        super('ROLE', 'role', sessionManager, logger, config, environment, pullData);
    }

    getNewEntity(json) {
        const newEntity = new Role();
        newEntity.setAttributes(json);
        return newEntity;
    }

    // CREATE role
    async createRole(newRole) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at ROLE manager in getting headers during create role");
            throw headersError;
        }
        let result = null;
        try {
            result = await super.create(newRole, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // UPDATE role
    async updateRole(updatedRole) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at ROLE manager in getting headers during update role");
            throw headersError;
        }
        let result = null;
        try {
            await super.update(updatedRole, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // DELETE role based on id
    async deleteRole(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at ROLE manager in getting headers during delete role");
            throw headersError;
        }
        let result = null;
        try {
            await super.delete(id, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // GET ALL roles
    async getAllRoles(onSuccess, onFailure) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at ROLE manager in getting headers during get all roles");
            throw headersError;
        }
        let allEntities = [];
        try {
            let result = await super.getAll(headersResult, onSuccess, onFailure);
            for (let record of result) {
                let entity = new Role();
                entity.setAttributes(record);
                allEntities.push(entity);
            }
        } catch (error) {
            this.logger.error(error.message);
        }
        return allEntities;
    }

    // GET a role based on Id
    async getRole(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at ROLE manager in getting headers during get role");
            throw headersError;
        }
        try {
            let result = await super.getOne(id, headersResult);
            return result;
        } catch (error) {
            this.logger.error(error.message);
            return null;
        }
    }
}

export default RoleManager;
