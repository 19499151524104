import CRUDManager from "./crud-manager";
import OperatingUnit from "./operating-unit";
import JobCluster from "./job-cluster";

// CRUD SDK for 'operating-unit' table
class OperatingUnitManager extends CRUDManager{

    constructor(sessionManager, logger, config, environment, pullData) {
        super('OperatingUnit', 'operating-unit', sessionManager, logger, config, environment, pullData);
    }

    getNewEntity(json) {
        const newEntity = new OperatingUnit();
        newEntity.setAttributes(json);
        return newEntity;
    }

    // CREATE operating unit
    async createOperatingUnit(newOperatingUnit) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at OPERATING UNIT manager in getting headers during create operating unit");
            throw headersError;
        }
        let result = null;
        try {
            result = await super.create(newOperatingUnit, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // UPDATE operating unit
    async updateOperatingUnit(updatedOperatingUnit) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at OPERATING UNIT manager in getting headers during update operating unit");
            throw headersError;
        }
        let result = null;
        try {
            await super.update(updatedOperatingUnit, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // DELETE operating unit based on id
    async deleteOperatingUnit(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at OPERATING UNIT manager in getting headers during delete operating unit");
            throw headersError;
        }
        let result = null;
        try {
            await super.delete(id, headersResult)
        } catch (error) {
            this.logger.error(error.message);
        }
        return result;
    }

    // GET ALL operating units
    async getAllOperatingUnits(onSuccess, onFailure) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at OPERATING UNIT manager in getting headers during get all operating units");
            throw headersError;
        }
        let allEntities = [];
        try {
            let result = await super.getAll(headersResult, onSuccess, onFailure);
            for (let record of result) {
                let entity = new JobCluster();
                entity.setAttributes(record);
                allEntities.push(entity);
            }
        } catch (error) {
            this.logger.error(error.message);
        }
        return allEntities;
    }

    // GET a operating unit based on Id
    async getOperatingUnit(id) {
        let headersResult = null;
        try {
            headersResult = await this.sessionManager.getAuthHeaders();
        } catch (headersError) {
            this.logger.error("Error encountered at OPERATING UNIT manager in getting headers during get operating unit");
            throw headersError;
        }
        try {
            let result = await super.getOne(id, headersResult);
            return result;
        } catch (error) {
            this.logger.error(error.message);
            return null;
        }
    }
}

export default OperatingUnitManager;
