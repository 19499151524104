export default class BaseSelector {


  constructor(config, records) {
    this.records = records;
    this.counter = 0;
  }

  hasNewBatch() {
    return false;
  }

  hasNextRecord() {
    if (this.counter < this.records.length) {
      return true;
    }
    return false;
  }

  nextRecord() {
    if (this.counter === this.records.length) {
      return null;
    }
    const nextRecord = this.records[this.counter];
    this.counter++;
    return nextRecord;
  }

  reset() {
    this.counter = 0;
  }
}
