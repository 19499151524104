import BaseParentEvaluator from './base-parent-evaluator.js';

export default class AndEvaluator extends BaseParentEvaluator {

    constructor(config) {
      super(config);
      this.name = 'And';
    }

    _evaluate(record, finalRecord) {
      let results = [];
      let satisfied = true;
      let fields = [];
      let thisResult = {result: false, parent: true, description: this.getSummary(), fields: []};
      for (const key of Object.keys(this.children)) {
        const childEvaluator = this.children[key];
        const result = childEvaluator.evaluate(record, finalRecord);
        this.addFields(thisResult.fields, result.fields);
        if (result.missingData) {
           thisResult.missingData = true;
        }
        if (result.badData) {
          thisResult.badData = true;
        }
        if (!result || !result.result) {
          satisfied = false;
        }
        if (result && result.result && result.parent && satisfied) {
          results = results.concat(result.childResults);
        } else {
          results.push(result);
        }
      }
      thisResult.childResults =  results;
      if (satisfied) {
        thisResult.result = true;
      }
      return thisResult;
    }

    _getSummary() {
      let summary = [];
      for (const child of this.children) {
        summary.push(child.getSummary());
      }
      return summary.join(', AND ');
    }
}
