import BaseAction from './base-action.js';

export default class Notify extends BaseAction {

    constructor(config) {
      super(config);
    }

    takeAction(record) {
      console.log(`Creating notification!`);
    }
}
